import { Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../reducers';
import { fetchMemberDemoGraphicInfo, setSelectedTab } from '../../../store/memberDetail.slice';
import MemberAdvancedDirectives from './MemberAdvancedDirectives';
import MemberCareCircle from './MemberCareCircle';
import MemberDemographic from './MemberDemographic';
import MemberHealthPlan from './MemberHealthPlan';
import MemberPcpSpecialist from './MemberPcpSpecialist/MemberPcpSpecialist';
import MemberPhone from './MemberPhone';
import MemberTelemonitoring from './MemberTelemonitoring/MemberTelemonitoring';
import { saveMemberBasicInfo } from '../../../store/memberDetail.slice';
import { roleEnum } from '../../../utils/assessments';
import ViewMemberDemographic from './ViewMemberDemographic';

const MemberDetailEditPage: React.FC<{ memberId?: string; isAssessment?: boolean; setSaveClickEvent?: Function }> = ({
    memberId = '',
    isAssessment = false,
    setSaveClickEvent = null,
}) => {
    const dispatch = useDispatch();
    const { selectedTab, selectedMember } = useSelector((state: RootState) => state.memberDetail);
    const { selectedRole } = useSelector((state: RootState) => state.assessment);
    const isInHra = isAssessment && selectedRole === roleEnum.HRA;

    if (isAssessment) {
        dispatch(setSelectedTab(0));
    }

    const getDefaultPhone = () => {
        return { Id: null, Number: null, Note: null, Textable: false, IsPrimary: false };
    };

    let params = useParams<any>();
    memberId = memberId || params.memberId;

    const [showPhone, setShowPhone] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [formPhone, setFormPhone] = useState(getDefaultPhone());

    const phoneStyles = {
        height: 'calc(100vh - 100px)',
        padding: '8px',
        margin: '16px',
        textAlign: 'right',
    };

    const saveLocalPhone = () => {
        let update = { ...formPhone };

        update.Number = formPhone.Number.replace(/[^\d.]/g, '');

        if (update.Id) {
            const newPhones = updatePrimary(selectedMember.Phones, update);
            const newState = { ...selectedMember };
            newState.Phones = newPhones;
            dispatch(saveMemberBasicInfo(newState));
        } else {
            let newPhones = selectedMember.Phones.concat(update);
            const newState = { ...selectedMember };
            newState.Phones = updatePrimary(newPhones, update);
            dispatch(saveMemberBasicInfo(newState));
        }
        setShowPhone(false);
    };

    const reset = (row: any) => {
        // let update = row ? {...row} : getDefaultPhone();
        let update = row.Id ? { ...row } : getDefaultPhone();
        setFormPhone(update);
    };

    const addPhoneHandler = (row: any = null) => {
        reset(row);
        setShowPhone(true);
    };

    const updatePrimary = (phones: any, update: any) => {
        let newPhones = phones.map((existingPhone) => {
            if (existingPhone.Id === update.Id) {
                return update;
            } else {
                let existingClone = { ...existingPhone };
                if (formPhone.IsPrimary) {
                    existingClone.IsPrimary = false;
                }
                return existingClone;
            }
        });

        return newPhones;
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        dispatch(setSelectedTab(newValue));
    };

    const deletePhone = () => {
        const newPhones = selectedMember.Phones.filter((item, j) => item.Id !== formPhone.Id);
        const newState = { ...selectedMember };
        newState.Phones = newPhones;

        dispatch(saveMemberBasicInfo(newState));
        setShowDelete(false);
    };

    useEffect(() => {
        dispatch(fetchMemberDemoGraphicInfo(memberId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Tabs
                variant="fullWidth"
                value={selectedTab}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={handleTabChange}
                aria-label="disabled tabs example"
                centered
            >
                <Tab label="Demographic Info" />
                {!isInHra && <Tab label="Phone" />}
                {!isAssessment && <Tab label="Health Plan" />}
                {!isAssessment && <Tab label="Advanced Directives" />}
                {!isAssessment && <Tab label="Care Circle" />}
                {!isAssessment && <Tab label="PCP & Specialist" />}
                {!isAssessment && <Tab label="Telemonitoring" />}
            </Tabs>
            {selectedTab === 0 && selectedMember && isAssessment ? (
                <MemberDemographic isAssessment={isAssessment} setSaveClickEvent={setSaveClickEvent} />
            ) : null}
            {selectedTab === 0 && selectedMember && !isAssessment ? <ViewMemberDemographic /> : null}
            {selectedTab === 1 && selectedMember ? (
                <MemberPhone
                    phoneStyles={phoneStyles}
                    selectedMember={selectedMember}
                    showPhone={showPhone}
                    setShowPhone={setShowPhone}
                    formPhone={formPhone}
                    setFormPhone={setFormPhone}
                    showDelete={showDelete}
                    setShowDelete={setShowDelete}
                    deletePhone={deletePhone}
                    addPhoneHandler={addPhoneHandler}
                    saveLocalPhone={saveLocalPhone}
                />
            ) : null}
            {selectedTab === 2 && selectedMember ? <MemberHealthPlan /> : null}
            {selectedTab === 3 && selectedMember ? <MemberAdvancedDirectives /> : null}
            {selectedTab === 4 && selectedMember ? <MemberCareCircle /> : null}
            {selectedTab === 5 && selectedMember ? <MemberPcpSpecialist /> : null}
            {selectedTab === 6 && selectedMember ? <MemberTelemonitoring /> : null}
        </>
    );
};

export default MemberDetailEditPage;
