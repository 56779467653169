import React, { useEffect, useState } from 'react';
import { Paper, TextField, Tabs, Tab, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parentUrl } from '../../../utils/constants';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import WidgetLoading from '../../SmartDashboard/Shared/WidgetLoading';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { isDefaultDate } from '../../../utils/common';
import { dateFormatter } from '../../../utils/timeFormat';
import b64toBlob from 'b64-to-blob';
import '../Widgets.css';

import { WidgetTimeFilterEnum } from '../../../Enum/WidgetTimeFilterEnum';
import { fetchFuhFumOrHraFullProgramReferral, exportFullProgramReferralSummary } from '../../Widgets/WidgetService';
import { useWidgetContext } from '../../../hooks/widgetContext';

const dayFilterList = [
    { Id: WidgetTimeFilterEnum.Last30Days, Value: WidgetTimeFilterEnum.Last30DaysDescription },
    { Id: WidgetTimeFilterEnum.Last60Days, Value: WidgetTimeFilterEnum.Last60DaysDescription },
    { Id: WidgetTimeFilterEnum.Custom, Value: WidgetTimeFilterEnum.CustomDescription },
];

const FullProgramReferralDetail = () => {
    const history = useNavigate();
    const [downLoading, setDownLoading] = useState<boolean>(false);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [fullProgramReferral, setFullProgramReferral] = useState<any>({});
    const [pageSize, setPageSize] = useState(15);
    const {
        fullProgramReferralDateFilter,
        setFullProgramReferralDateFilter,
        fullProgramReferralFromDate,
        setFullProgramReferralFromDate,
        fullProgramReferralToDate,
        setFullProgramReferralToDate,
        fullProgramReferralTab,
        setFullProgramReferralTab,
    } = useWidgetContext();

    useEffect(() => {
        if (fullProgramReferralDateFilter !== WidgetTimeFilterEnum.Custom || (Boolean(fullProgramReferralFromDate) && Boolean(fullProgramReferralToDate))) {
            setIsFetched(false);
            const changeFilter = async () => {
                let dateFrom =
                    Boolean(fullProgramReferralFromDate) && fullProgramReferralDateFilter === WidgetTimeFilterEnum.Custom
                        ? new Date(fullProgramReferralFromDate).toISOString()
                        : '';
                let dateTo =
                    Boolean(fullProgramReferralToDate) && fullProgramReferralDateFilter === WidgetTimeFilterEnum.Custom
                        ? new Date(fullProgramReferralToDate).toISOString()
                        : '';

                const data = await fetchFuhFumOrHraFullProgramReferral(fullProgramReferralDateFilter, dateFrom, dateTo, true);
                setFullProgramReferral(data);
                setIsFetched(true);
            };
            changeFilter();
        }

        if (fullProgramReferralDateFilter !== WidgetTimeFilterEnum.Custom && Boolean(fullProgramReferralFromDate) && Boolean(fullProgramReferralToDate)) {
            setFullProgramReferralFromDate(null);
            setFullProgramReferralToDate(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFullProgramReferral, setIsFetched, fullProgramReferralDateFilter, fullProgramReferralFromDate, fullProgramReferralToDate]);

    const changeFromDate = (date: any) => {
        setFullProgramReferralFromDate(date);
    };

    const changeToDate = (date: any) => {
        setFullProgramReferralToDate(date);
    };

    const handleAlertsTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setFullProgramReferralTab(newValue);
    };

    const fuhfumColumns: GridColDef[] = [
        {
            field: 'AhcccsId',
            headerName: 'Ahcccs Id',
            flex: 0.7,
        },
        {
            field: 'MemberName',
            headerName: 'Member Name',
            flex: 1.3,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : dateFormatter(new Date(params.row.DOB))}`,
        },
        {
            field: 'ReferralDate',
            headerName: 'Referral Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.ReferralDate) ? '-' : dateFormatter(new Date(params.row.ReferralDate))}`,
        },
        {
            field: 'DateOfOnboard',
            headerName: 'Date Of Onboard',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.DateOfOnboard) ? '-' : dateFormatter(new Date(params.row.DateOfOnboard))}`,
        },
        {
            field: 'HealthPlan',
            headerName: 'Health Plan',
            flex: 1.5,
        },
        {
            field: 'Status',
            headerName: 'Status',
            flex: 1.5,
        },
        {
            field: 'Address',
            headerName: 'Address',
            flex: 3,
        },
    ];

    const hraColumns: GridColDef[] = [
        {
            field: 'AhcccsId',
            headerName: 'Ahcccs Id',
            flex: 0.7,
        },
        {
            field: 'MemberName',
            headerName: 'Member Name',
            flex: 1.3,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : dateFormatter(new Date(params.row.DOB))}`,
        },
        {
            field: 'ReferralDate',
            headerName: 'Referral Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.ReferralDate) ? '-' : dateFormatter(new Date(params.row.ReferralDate))}`,
        },
        {
            field: 'DateOfOnboard',
            headerName: 'Date Of Onboard',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.DateOfOnboard) ? '-' : dateFormatter(new Date(params.row.DateOfOnboard))}`,
        },
        {
            field: 'HealthPlan',
            headerName: 'Health Plan',
            flex: 1.5,
        },
        {
            field: 'Status',
            headerName: 'Status',
            flex: 1.5,
        },
        {
            field: 'Address',
            headerName: 'Address',
            flex: 3,
        },
    ];

    const backToWidget = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    const exportDetail = async () => {
        setDownLoading(true);
        let dateFrom =
            Boolean(fullProgramReferralFromDate) && fullProgramReferralDateFilter === WidgetTimeFilterEnum.Custom
                ? new Date(fullProgramReferralFromDate).toISOString()
                : '';
        let dateTo =
            Boolean(fullProgramReferralToDate) && fullProgramReferralDateFilter === WidgetTimeFilterEnum.Custom
                ? new Date(fullProgramReferralToDate).toISOString()
                : '';
        const data = await exportFullProgramReferralSummary(fullProgramReferralDateFilter, dateFrom, dateTo);

        let blob = b64toBlob(data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        var date = new Date();
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var dateInfo = [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('');
        link.setAttribute('download', `FullProgramReferrals - ${dateInfo}.xlsx`);
        link.click();
        link.remove();
        setDownLoading(false);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1} paddingBottom={2}>
                    <Grid item xs={12}>
                        <span onClick={backToWidget}>
                            <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                        </span>
                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                            Full Program Referrals Summary
                        </Typography>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Date Filter</InputLabel>
                            <Select
                                value={fullProgramReferralDateFilter}
                                label="Date Filter"
                                name="Date"
                                onChange={(e) => setFullProgramReferralDateFilter(parseInt(e.target.value as any))}
                            >
                                {dayFilterList.map((date) => (
                                    <MenuItem key={date.Id} value={date.Id}>
                                        {date.Value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {fullProgramReferralDateFilter === WidgetTimeFilterEnum.Custom ? (
                        <React.Fragment>
                            <Grid item md={1.5}>
                                <DesktopDatePicker
                                    label="From Date"
                                    value={Boolean(fullProgramReferralFromDate) ? fullProgramReferralFromDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeFromDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                            <Grid item md={1.5}>
                                <DesktopDatePicker
                                    label="To Date"
                                    value={Boolean(fullProgramReferralToDate) ? fullProgramReferralToDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeToDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                        </React.Fragment>
                    ) : null}
                    <Grid item xs={fullProgramReferralDateFilter === WidgetTimeFilterEnum.Custom ? 6.5 : 9.5}></Grid>
                    <Grid item xs={1}>
                        <Button
                            style={{ minWidth: '120px', marginLeft: 'auto' }}
                            onClick={() => exportDetail()}
                            variant="contained"
                            color="success"
                            disabled={
                                (fullProgramReferral?.FuhfumFullProgramReferrals?.length === 0 && fullProgramReferral?.HraFullProgramReferrals?.length === 0) ||
                                downLoading
                            }
                        >
                            {downLoading ? 'Exporting..' : 'Export'}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <hr />
                    </Grid>
                    <Grid item xs={12} display={'flex'}>
                        <Tabs sx={{ display: 'inline-flex', marginRight: 'auto' }} value={fullProgramReferralTab} onChange={handleAlertsTabChange}>
                            <Tab value={1} label="FUHFUM" />
                            <Tab value={2} label="HRA" />
                        </Tabs>
                    </Grid>
                    <Grid item xs={12} minHeight="60vh" sx={{ position: 'relative' }}>
                        {!isFetched ? (
                            <WidgetLoading />
                        ) : (
                            <Grid container spacing={2}>
                                {fullProgramReferralTab === 1 ? (
                                    <Grid item xs={12}>
                                        <Grid item xs={12} textAlign="center">
                                            <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                FUHFUM Full Program Referrals ({fullProgramReferral?.FuhfumFullProgramReferrals?.length ?? 0})
                                            </Typography>
                                        </Grid>
                                        <div style={{ width: '100%', height: 'Calc(60vh - 65px)', position: 'relative' }}>
                                            <DataGrid
                                                onCellClick={(params: any) => {
                                                    window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                                }}
                                                rows={fullProgramReferral?.FuhfumFullProgramReferrals || []}
                                                columns={fuhfumColumns}
                                                pageSize={pageSize}
                                                rowsPerPageOptions={[5]}
                                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                getRowId={(row) => row.Id}
                                                getRowHeight={() => 'auto'}
                                                sx={{
                                                    [`& .${gridClasses.cell}`]: {
                                                        py: 1,
                                                    },
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                ) : (
                                    <Grid item xs={12}>
                                        <Grid item xs={12} textAlign="center">
                                            <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                HRA Full Program Referrals ({fullProgramReferral?.HraFullProgramReferrals?.length ?? 0})
                                            </Typography>
                                        </Grid>
                                        <div style={{ width: '100%', height: 'Calc(60vh - 65px)', position: 'relative' }}>
                                            <DataGrid
                                                onCellClick={(params: any) => {
                                                    window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                                }}
                                                rows={fullProgramReferral?.HraFullProgramReferrals || []}
                                                columns={hraColumns}
                                                pageSize={pageSize}
                                                rowsPerPageOptions={[5]}
                                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                getRowId={(row) => row.Id}
                                                getRowHeight={() => 'auto'}
                                                sx={{
                                                    [`& .${gridClasses.cell}`]: {
                                                        py: 1,
                                                    },
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </LocalizationProvider>
    );
};

export default FullProgramReferralDetail;
