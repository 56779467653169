export enum AppointmentTimeFilterEnum {
    Next30DaysDescription = 'Next 30 Days',
    Next30Days = 1,
    Next60DaysDescription = 'Next 60 Days',
    Next60Days = 2,
    Next90DaysDescription = 'Next 90 Days',
    Next90Days = 3,
    CustomDescription = 'Custom',
    Custom = 4,
}
