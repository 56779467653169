import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    Box,
    CircularProgress,
    Divider,
    Typography,
    Stack,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Paper,
    List,
    ListItem,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useParams } from 'react-router';

import { RootState } from '../../../reducers';
import { gotoNextMenu, gotoPreviousMenu } from '../../../store/assessment.slice';
import AlertDialog from '../../AlertDialog/AlertDialog';

import { HamiltonAnxietyScaleValuesEnum } from '../../../Enum/HamiltonAnxietyScaleValuesEnum.ts';
import { copyObject } from '../../../utils/common';
import {
    checkCompletion,
    fetchHamiltonAnxietyScale,
    getTotalScore,
    saveHamiltonAnxietyScale,
    setHamiltonAnxietyScale,
} from '../../../store/hamiltonAnxietyScaleSlice.slice';
import { StatusEnum } from '../../../Enum/StatusEnum';
import { HamiltonAnxietyScaleModel } from '../../../Models/HamiltonAnxietyScale/HamiltonAnxietyScale.model';

const HamiltonAnxietyScale: React.FC<{ memberId: string }> = ({ memberId }) => {
    const params = useParams();
    memberId = params.memberId;
    const dispatch = useDispatch();
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const { engagementDraft, isSaving: isSavingEngagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const { hamiltonAnxietyScale, isFetchingHamiltonAnxietyScale } = useSelector((state: RootState) => state.hamiltonAnxietyScaleSlice);

    const isSaving = isSavingEngagementDraft || isFetchingHamiltonAnxietyScale || false;

    let color = 'success.main';
    if (getTotalScore(hamiltonAnxietyScale) >= 18 && getTotalScore(hamiltonAnxietyScale) <= 24) color = 'warning.main';
    if (getTotalScore(hamiltonAnxietyScale) >= 25 && getTotalScore(hamiltonAnxietyScale) <= 30) color = 'error.main';
    if (getTotalScore(hamiltonAnxietyScale) >= 31) color = 'secondary.main';

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let copy = copyObject(hamiltonAnxietyScale);
        copy[name] = value;
        dispatch(setHamiltonAnxietyScale(copy));
    };

    const handleOnConfirmToNext = (isComplete = false) => {
        const copy = copyObject(hamiltonAnxietyScale);
        copy.MemberId = memberId;
        copy.IsAssessmentCompleted = isComplete;
        if (Boolean(isComplete)) {
            copy.AssessmentStatus = StatusEnum.Completed;
            dispatch(saveHamiltonAnxietyScale(copy, false, true));
        } else {
            copy.AssessmentStatus = StatusEnum.InProgress;
            dispatch(saveHamiltonAnxietyScale(copy, true, true));
        }
    };

    const handleOnConfirmSkip = () => {
        dispatch(setHamiltonAnxietyScale(new HamiltonAnxietyScaleModel()));
        dispatch(gotoNextMenu());
    };

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    const questionsList = [
        {
            question: 'Anxious mood',
            name: 'Anxious',
            value: hamiltonAnxietyScale.Anxious,
            description: 'Worries, anticipation of the worst, fearful anticipation, irritability',
        },
        {
            question: 'Tension',
            name: 'Tension',
            value: hamiltonAnxietyScale.Tension,
            description: 'Feelings of tension, fatigability, startle response, moved to tears easily, trembling, feelings of restlessness, inability to relax',
        },
        {
            question: 'Fears',
            name: 'Fears',
            value: hamiltonAnxietyScale.Fears,
            description: 'Of the dark, of strangers, of being left alone, of animals, of traffic, of crowds',
        },
        {
            question: 'Insomnia',
            name: 'Insomnia',
            value: hamiltonAnxietyScale.Insomnia,
            description: 'Difficulty in falling asleep, broken sleep, unsatisfying sleep and fatigue on waking, dreams, nightmares, night terrors',
        },
        { question: 'Intellectual', name: 'Intellectual', value: hamiltonAnxietyScale.Intellectual, description: 'Difficulty in concentration, poor memory' },
        {
            question: 'Depressed mood',
            name: 'DepressedMood',
            value: hamiltonAnxietyScale.DepressedMood,
            description: 'Loss of interest, lack of pleasure in hobbies, depression, early waking, diurnal swing',
        },
        {
            question: 'Somatic (muscular)',
            name: 'SomaticMuscular',
            value: hamiltonAnxietyScale.SomaticMuscular,
            description: 'Pains and aches, twitching, stiffness, myoclonic jerks, grinding of teeth, unsteady voice, increased muscular tone',
        },
        {
            question: 'Somatic (sensory)',
            name: 'SomaticSensory',
            value: hamiltonAnxietyScale.SomaticSensory,
            description: 'Tinnitus, blurring of vision, hot and cold flushes, feelings of weakness, prickling sensation',
        },
        {
            question: 'Cardiovascular symptoms',
            name: 'Cardiovascular',
            value: hamiltonAnxietyScale.Cardiovascular,
            description: 'Tachycardia, palpitations, pain in chest, throbbing of vessels, fainting feelings, missing beat',
        },
        {
            question: 'Respiratory symptoms',
            name: 'Respiratory',
            value: hamiltonAnxietyScale.Respiratory,
            description: 'Pressure or constriction in chest, choking feelings, sighing, dyspnoea',
        },
        {
            question: 'Gastrointestinal symptoms',
            name: 'Gastrointestinal',
            value: hamiltonAnxietyScale.Gastrointestinal,
            description:
                'Difficulty in swallowing, wind abdominal pain, burning sensations, abdominal fullness, nausea, vomiting, borborygmi, looseness of bowels, loss of weight, constipation',
        },
        {
            question: 'Genitourinary symptoms',
            name: 'Genitourinary',
            value: hamiltonAnxietyScale.Genitourinary,
            description:
                'Frequency of micturition, urgency of micturition, amenorrhea, menorrhagia, development of frigidity, premature ejaculation, loss of libido, impotence',
        },
        {
            question: 'Autonomic symptoms',
            name: 'AutonomicSymptoms',
            value: hamiltonAnxietyScale.AutonomicSymptoms,
            description: 'Dry mouth, flushing, pallor, tendency to sweat, giddiness, tension headache, raising of hair',
        },
        {
            question: 'Behavior at interview',
            name: 'BehaviorAtInterview',
            value: hamiltonAnxietyScale.BehaviorAtInterview,
            description:
                'Fidgeting, restlessness or pacing, tremor of hands, furrowed brow, strained face, sighing or rapid respiration, facial pallor, swallowing, etc',
        },
    ];

    const questionComponent = (question: string, name: string, value: HamiltonAnxietyScaleValuesEnum, description: string, index: number) => {
        return (
            <Grid key={name + '_' + index} item xs={10}>
                <Stack justifyContent={'space-between'} sx={{ marginTop: 2 }}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <Typography variant="body1" component="label" sx={{ marginRight: 1 }}>
                            {index + 1}.{' '}
                        </Typography>
                        <Typography variant="body1" fontWeight={800} component="label">
                            {question}
                        </Typography>
                    </Stack>

                    <Typography variant="body1" fontStyle={'italic'} component="label">
                        {description}
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            aria-label={name}
                            name={name}
                            value={value !== null && value !== undefined ? value : ''}
                            onChange={(e) => handleOnChange(e)}
                        >
                            <FormControlLabel value={HamiltonAnxietyScaleValuesEnum.NotPresent} control={<Radio />} label="0" />
                            <FormControlLabel value={HamiltonAnxietyScaleValuesEnum.Mild} control={<Radio />} label="1" />
                            <FormControlLabel value={HamiltonAnxietyScaleValuesEnum.Moderate} control={<Radio />} label="2" />
                            <FormControlLabel value={HamiltonAnxietyScaleValuesEnum.Severe} control={<Radio />} label="3" />
                            <FormControlLabel value={HamiltonAnxietyScaleValuesEnum.VerySevere} control={<Radio />} label="4" />
                        </RadioGroup>
                    </FormControl>
                </Stack>
            </Grid>
        );
    };

    const renderBody = () => {
        const score = getTotalScore(hamiltonAnxietyScale);
        const confirmMessage = `I confirm and acknowledge that Hamilton Anxiety Rating Scale (HAM-A) is completed.`;
        if (score >= 30) {
            return (
                <Stack direction={'column'} alignItems={'center'} spacing={2}>
                    <Typography variant="body1" component="label">{`${confirmMessage}`}</Typography>
                    <Typography
                        variant="body1"
                        component="label"
                        color={'error.main'}
                    >{`The total score indicates the member is experiencing severe anxiety symptoms. Please follow up with BH team for further assessment.`}</Typography>
                </Stack>
            );
        } else {
            return <Typography variant="body1" component="label">{`${confirmMessage}`}</Typography>;
        }
    };

    useEffect(() => {
        if (engagementDraft?.HamiltonAnxietyScaleId) {
            dispatch(fetchHamiltonAnxietyScale(engagementDraft.HamiltonAnxietyScaleId));
        } else {
            dispatch(setHamiltonAnxietyScale(new HamiltonAnxietyScaleModel()));
        }
    }, [engagementDraft?.HamiltonAnxietyScaleId, dispatch]);

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            {isSaving ? (
                <Grid item className="content-form">
                    <Box display="flex" justifyContent="center" className="loader-container">
                        <CircularProgress />
                    </Box>
                </Grid>
            ) : (
                <>
                    <Grid item className="content-form">
                        <Typography variant="h6" component="label">
                            Hamilton Anxiety Rating Scale (HAM-A)
                        </Typography>
                        <Divider sx={{ marginBottom: 2 }} />
                        <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="body1" component="label">
                                        Below is a list of phrases that describe certain feelings that people have. Rate the member by finding the answer which
                                        best describes the extent to which he/she has these conditions. Select one of the five responses for each of the
                                        fourteen questions.
                                    </Typography>
                                    <Stack direction={'row'} alignItems={'center'} sx={{ marginTop: 2 }}>
                                        <Typography variant="body1" component="label" sx={{ marginRight: 1 }}>
                                            <b>0</b> = Not present
                                        </Typography>
                                        <Typography variant="body1" component="label" sx={{ marginRight: 1 }}>
                                            <b>1</b> = Mild
                                        </Typography>
                                        <Typography variant="body1" component="label" sx={{ marginRight: 1 }}>
                                            <b>2</b> = Moderate
                                        </Typography>
                                        <Typography variant="body1" component="label" sx={{ marginRight: 1 }}>
                                            <b>3</b> = Severe
                                        </Typography>
                                        <Typography variant="body1" component="label" sx={{ marginRight: 1 }}>
                                            <b>4</b> = Very severe
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Divider sx={{ marginY: 2, width: '100%' }} />
                                <List sx={{ width: '100%' }}>
                                    {questionsList.map((question, index) => (
                                        <ListItem key={question.name + '_' + index}>
                                            {questionComponent(question.question, question.name, question.value, question.description, index)}
                                        </ListItem>
                                    ))}
                                </List>
                                <Grid item xs={12}>
                                    <Stack direction={'row'} alignItems={'center'} sx={{ marginTop: 5 }}>
                                        <Typography variant="h6" fontWeight={800} sx={{ marginRight: 1 }}>
                                            Total Score:
                                        </Typography>
                                        <Typography variant="h6" color={color} fontWeight={800} sx={{ marginRight: 1 }}>
                                            {getTotalScore(hamiltonAnxietyScale)}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction={'row'} alignItems={'center'} spacing={3} sx={{ marginTop: 2 }}>
                                        <Stack spacing={0} justifyContent={'center'} alignItems={'center'}>
                                            <Typography variant="body1" component="label">
                                                0 - 17
                                            </Typography>
                                            <Typography variant="body1" component="label" color={'success.main'}>
                                                Mild
                                            </Typography>
                                        </Stack>
                                        <Stack spacing={0} justifyContent={'center'} alignItems={'center'}>
                                            <Typography variant="body1" component="label">
                                                18 - 24
                                            </Typography>
                                            <Typography variant="body1" component="label" color={'warning.main'}>
                                                Mild to Moderate
                                            </Typography>
                                        </Stack>
                                        <Stack spacing={0} justifyContent={'center'} alignItems={'center'}>
                                            <Typography variant="body1" component="label">
                                                25 - 30
                                            </Typography>
                                            <Typography variant="body1" component="label" color={'error.main'}>
                                                Moderate to Severe
                                            </Typography>
                                        </Stack>
                                        <Stack spacing={0} justifyContent={'center'} alignItems={'center'}>
                                            <Typography variant="body1" component="label">
                                                31 - 56
                                            </Typography>
                                            <Typography variant="body1" component="label" color={'secondary.main'}>
                                                Severe
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ marginRight: 2 }}
                            disabled={isSaving}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={isSaving}
                            endIcon={isSaving ? <CircularProgress size={18} /> : <ArrowForwardIosIcon />}
                            onClick={handleOnClickSave}
                        >
                            Next
                        </Button>
                    </Grid>
                </>
            )}

            {Boolean(checkCompletion(hamiltonAnxietyScale).length === 0) ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Hamilton Anxiety Rating Scale (HAM-A)"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={handleOnConfirmSkip}
                    showBottomCenter={true}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    renderBody={renderBody}
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Hamilton Anxiety Rating Scale (HAM-A)"
                    showBottomCenter={true}
                    cancelText="Go Back"
                    skipText="Skip and continue"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(false)}
                    onSkip={handleOnConfirmSkip}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="Are you sure you wish to proceed without completing Hamilton Anxiety Rating Scale (HAM-A)?"
                />
            )}
        </Grid>
    );
};

export default HamiltonAnxietyScale;
