import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { setListOfMemberCharts, setShowAllMemberCharts } from '../../../store/onboardingWorkflow.slice';
import { MemberStatusEnum } from '../../../Enum/MemberStatusEnum';
import { DataGrid, GridColDef, GridValueGetterParams, gridClasses } from '@mui/x-data-grid';
import { isDefaultDate } from '../../../utils/common';
import moment from 'moment';
import { parentUrl } from '../../../utils/constants';

const OtherMemberChartsModal = () => {
    const { listOfMemberCharts, showAllMemberCharts } = useSelector((state: RootState) => state.onboardingWorkflowSlice);
    const { population } = useSelector((state: RootState) => state.adminMembers);
    const dispatch = useDispatch();

    const closeShowAllMemberChartsHandler = () => {
        dispatch(setListOfMemberCharts([]));
        dispatch(setShowAllMemberCharts(false));
    };

    const columns: GridColDef[] = [
        {
            field: 'Mem_AHCCCS_ID',
            headerName: 'AHCCCS ID',
            minWidth: 140,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 140,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 140,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 140,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : moment(params.row.DOB).format('MM/DD/YYYY')}`,
        },
        {
            field: 'Status',
            headerName: 'Status',
            minWidth: 200,
            valueGetter: (params: GridValueGetterParams) => `${MemberStatusEnum[params.row.Status] || ''}`,
        },
        {
            field: 'Population',
            headerName: 'Population',
            minWidth: 160,

            valueGetter: (params: GridValueGetterParams) => `${population.find((el) => el.PopulationNumber === params.row.Population)?.PopulationName || ''}`,
        },
        {
            field: 'ContractingHealthPlan',
            headerName: 'Contracting Health Plan',
            minWidth: 140,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.ContractingHealthPlan?.Name || ''}`,
        },
    ];

    return (
        <Dialog open={showAllMemberCharts} onClose={closeShowAllMemberChartsHandler} fullWidth maxWidth="lg">
            <DialogTitle>Previous Member Charts</DialogTitle>
            <DialogContent>
                <div style={{ width: '100%', height: '47vh' }}>
                    <DataGrid
                        rows={listOfMemberCharts || []}
                        columns={columns}
                        onRowClick={(params) => {
                            window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                        }}
                        getRowHeight={() => 'auto'}
                        rowsPerPageOptions={[5]}
                        getRowId={(row) => row.Id}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 1,
                            },
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button sx={{ minWidth: '120px' }} variant="contained" onClick={closeShowAllMemberChartsHandler} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OtherMemberChartsModal;
