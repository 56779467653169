import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { insertOrUpdateZipCodeGroup, setOpenSelectedGroup, setSelectedGroup } from '../../../../store/adminOnboarding.slice';
import { setShowMessage } from '../../../../store/shared.slice';

const SelectedGroupModal = (props) => {
    const { MenuProps, zipCodes, zipCodeGroups } = props;
    const dispatch = useDispatch();
    const { openSelectedGroup, selectedGroup } = useSelector((state: RootState) => state.adminOnboardingSlice);
    const closeSelectedGroupHandler = () => {
        dispatch(setOpenSelectedGroup(false));
    };

    const handleChange = (event: SelectChangeEvent<typeof zipCodes>) => {
        const {
            target: { value },
        } = event;
        const newValue = Array.isArray(value) ? value : [value]; // Ensure value is always an array
        const existingZipCodes = new Set<string>();
        zipCodeGroups.forEach((group) => {
            if (group.Id !== selectedGroup.Id) {
                group.SelectedZipCodes?.forEach((zip) => existingZipCodes.add(zip));
            }
        });

        const duplicates = newValue.filter((zip) => existingZipCodes.has(zip));
        if (duplicates.length > 0) {
            dispatch(setShowMessage(true, `The following zip codes are already assigned to other groups: ${duplicates.join(', ')}`, 'error'));
        }
        dispatch(setSelectedGroup({ ...selectedGroup, SelectedZipCodes: newValue }));
    };

    const saveSelectedGroupHandler = () => {
        dispatch(insertOrUpdateZipCodeGroup(selectedGroup));
        dispatch(setOpenSelectedGroup(false));
    };

    return (
        <Dialog
            open={openSelectedGroup}
            onClose={closeSelectedGroupHandler}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">{selectedGroup.Id.length > 0 ? 'Edit' : 'Add'} Group</DialogTitle>
            <DialogContent>
                <Stack spacing={2} paddingY={2}>
                    <TextField
                        variant="outlined"
                        name="GroupName"
                        label={'Group Name'}
                        value={selectedGroup.PopulationName || ''}
                        onChange={(e) => dispatch(setSelectedGroup({ ...selectedGroup, PopulationName: e.target.value }))}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="zip-codes-label">Zip Codes</InputLabel>
                        <Select
                            labelId="zip-codes-label"
                            multiple
                            value={selectedGroup.SelectedZipCodes || []}
                            name="SelectedZipCodes"
                            onChange={(e) => handleChange(e)}
                            input={<OutlinedInput id="select-multiple-chip" label="Zip Codes" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {zipCodes.map((zip: string, index: number) => (
                                <MenuItem key={zip + `_${index}`} value={zip}>
                                    {zip}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button className="button-120" variant="outlined" onClick={closeSelectedGroupHandler}>
                    Cancel
                </Button>
                <Button className="button-120" variant="contained" onClick={saveSelectedGroupHandler}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SelectedGroupModal;
