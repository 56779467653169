export enum SummaryNoteTypeEnum {
    VitalSign = 1,
    Goal = 2,
    SDOH = 3,
    PastPresentHistory = 4,
    PastSurgicalHistory = 5,
    ReviewSystems = 6,
    PhysicalExam = 7,
    PHQ9 = 8,
    GeneralEncounter = 9,
    HgbA1CAssessment = 10,
    DiabetesFootAssessment = 11,
    UrinalysisReport = 12,
    Plan = 13,
    Barrier = 14,
    Metric = 15,
    NextStep = 16,
    SchedulePlan = 17,
    Request = 18,
    MiniMentalExam = 19,
    Strength = 20,
    Intervention = 21,
    SubjectiveAssessment = 22,
    Medications = 25,
    ProgressNote = 26,
    Custom = 27,
    DemographicInfo = 28,
    HealthPlan = 29,
    ConsentForm = 30,
    BudgetForm = 31,
    Allergy = 32,
    PcpSpecialist = 33,
    CareCircle = 34,
    Tablet = 35,
    Education = 36,
    TransitionalCare = 37,
    LipidAssessment = 38,
    TobaccoAndDrug = 39,
    SexualHealth = 40,
    ADL = 41,
    FallRisk = 42,
    VisionAndHearing = 43,
    PreventativeHealthAdvice = 44,
    AdvancedDirective = 45,
    AdditionalCHPCriteria = 46,
    ScreeningFuhFum = 47,
    HamiltonAnxietyScale = 48,
}
