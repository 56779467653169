export enum AssessmentTypeEnum {
    SelfReportedInformation = 1,
    PastPresentHistory = 2,
    PastSurgicalHistory = 3,
    ReviewSystems = 4,
    PhysicalExam = 5,
    Functional = 6,
    Physical = 7,
    Social = 8,
    Allergies = 9,
    Medication = 10,
    GeneralEncounter = 11,
    ProcedureSignOff = 12,
    PatientHealthQuestionnaire = 13,
    UrinalysisReport = 14,
    BehaviourHealthInterview = 15,
    PatientScreeningSurvey = 16,
    ACEAssessment = 17,
    HgbA1CAssessment = 18,
    DiabetesFootAssessment = 19,
    SdohAssessment = 20,
    BudgetForm = 21,
    LipidAssessment = 22,
    TobaccoAndDrug = 23,
    SexualHealth = 24,
    ADL = 25,
    FallRisk = 26,
    VisionAndHearing = 27,
    PreventativeHealthAdvice = 28,
    AdvancedDirective = 29,
    AdditionalCHPCriteria = 30,
    ScreeningFuhFum = 31,
    HamiltonAnxietyScale = 32,
}
