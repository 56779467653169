import BaseEntity from '../BaseEntity.model';

import { StatusEnum } from '../../Enum/StatusEnum';
import { CreatedBy } from '../Shared/CreatedBy.model';
export default interface IHamiltonAnxietyScaleModel extends BaseEntity {
    MemberId: string;
    RecordingDate: Date;
    MemberName: string;
    CreatedDate: Date;
    CreatedBy: CreatedBy;
    UpdatedDate: Date;
    UpdatedBy: CreatedBy;
    Anxious: number;
    Tension: number;
    Fears: number;
    Insomnia: number;
    Intellectual: number;
    DepressedMood: number;
    SomaticMuscular: number;
    SomaticSensory: number;
    Cardiovascular: number;
    Respiratory: number;
    Gastrointestinal: number;
    Genitourinary: number;
    AutonomicSymptoms: number;
    BehaviorAtInterview: number;
    IsAssessmentCompleted: boolean;
    AssessmentStatus: StatusEnum;
}

export class HamiltonAnxietyScaleModel implements IHamiltonAnxietyScaleModel {
    Id: string;
    MemberId: string;
    RecordingDate: Date;
    MemberName: string;
    CreatedDate: Date;
    CreatedBy: CreatedBy;
    UpdatedDate: Date;
    UpdatedBy: CreatedBy;
    Anxious: number;
    Tension: number;
    Fears: number;
    Insomnia: number;
    Intellectual: number;
    DepressedMood: number;
    SomaticMuscular: number;
    SomaticSensory: number;
    Cardiovascular: number;
    Respiratory: number;
    Gastrointestinal: number;
    Genitourinary: number;
    AutonomicSymptoms: number;
    BehaviorAtInterview: number;
    IsAssessmentCompleted: boolean;
    AssessmentStatus: StatusEnum;

    constructor() {
        this.Id = '';
        this.MemberId = '';
        this.MemberName = '';
        this.IsAssessmentCompleted = false;
        this.AssessmentStatus = 0;
    }
}
