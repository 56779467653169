import React, { useEffect, useState, useRef } from 'react';
import { Paper, Card, CardContent, TextField, IconButton, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useNavigate } from 'react-router';
import '../Widgets.css';

import { WidgetTimeFilterEnum } from '../../../Enum/WidgetTimeFilterEnum';
import { fetchFuhFumOrHraFullProgramReferral } from '../../Widgets/WidgetService';
import { useWidgetContext } from '../../../hooks/widgetContext';

const dayFilterList = [
    { Id: WidgetTimeFilterEnum.Last30Days, Value: WidgetTimeFilterEnum.Last30DaysDescription },
    { Id: WidgetTimeFilterEnum.Last60Days, Value: WidgetTimeFilterEnum.Last60DaysDescription },
    { Id: WidgetTimeFilterEnum.Custom, Value: WidgetTimeFilterEnum.CustomDescription },
];

const FullProgramReferrals = () => {
    const history = useNavigate();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [fullProgramReferral, setFullProgramReferral] = useState<any>({});
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const {
        fullProgramReferralDateFilter,
        setFullProgramReferralDateFilter,
        fullProgramReferralFromDate,
        setFullProgramReferralFromDate,
        fullProgramReferralToDate,
        setFullProgramReferralToDate,
        setFullProgramReferralTab,
    } = useWidgetContext();

    const handleClick = (event) => {
        setShow(!show);
    };

    useEffect(() => {
        if (fullProgramReferralDateFilter !== WidgetTimeFilterEnum.Custom || (Boolean(fullProgramReferralFromDate) && Boolean(fullProgramReferralToDate))) {
            setIsFetched(false);
            const changeFilter = async () => {
                let dateFrom =
                    Boolean(fullProgramReferralFromDate) && fullProgramReferralDateFilter === WidgetTimeFilterEnum.Custom
                        ? new Date(fullProgramReferralFromDate).toISOString()
                        : '';
                let dateTo =
                    Boolean(fullProgramReferralToDate) && fullProgramReferralDateFilter === WidgetTimeFilterEnum.Custom
                        ? new Date(fullProgramReferralToDate).toISOString()
                        : '';

                const data = await fetchFuhFumOrHraFullProgramReferral(fullProgramReferralDateFilter, dateFrom, dateTo, false);
                setFullProgramReferral(data);
                setIsFetched(true);
            };
            changeFilter();
        }

        if (fullProgramReferralDateFilter !== WidgetTimeFilterEnum.Custom && Boolean(fullProgramReferralFromDate) && Boolean(fullProgramReferralToDate)) {
            setFullProgramReferralFromDate(null);
            setFullProgramReferralToDate(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFullProgramReferral, setIsFetched, fullProgramReferralDateFilter, fullProgramReferralFromDate, fullProgramReferralToDate]);

    const changeFromDate = (date: any) => {
        setFullProgramReferralFromDate(date);
    };

    const changeToDate = (date: any) => {
        setFullProgramReferralToDate(date);
    };

    const navigateToMembers = (tab: any) => {
        setFullProgramReferralTab(tab);
        history(`/provider/metricsummary/fullprogramreferraldetail`);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                                <span className="pointer" onClick={() => navigateToMembers(1)}>
                                    Full Program Referrals
                                </span>
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography variant="caption">
                                                Number of successful referrals to the full program from FUHFUM/HRA populations
                                            </Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select
                                    value={fullProgramReferralDateFilter}
                                    label="Date Filter"
                                    name="Date"
                                    onChange={(e) => setFullProgramReferralDateFilter(parseInt(e.target.value as any))}
                                >
                                    {dayFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {fullProgramReferralDateFilter === WidgetTimeFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(fullProgramReferralFromDate) ? fullProgramReferralFromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(fullProgramReferralToDate) ? fullProgramReferralToDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                        <Grid item xs={12} minHeight="45vh" sx={{ position: 'relative' }}>
                            {!isFetched ? (
                                <Box className="loader-center">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Grid container spacing={2} marginTop={1} textAlign="center" sx={{ position: 'relative' }}>
                                    <Grid item xs={4.2}>
                                        <Card
                                            elevation={10}
                                            sx={{
                                                maxHeight: '150px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                padding: 2,
                                                justifyContent: 'space-between',
                                            }}
                                            className="pointer"
                                            onClick={() => navigateToMembers(1)}
                                        >
                                            <Typography variant="body1" fontWeight={'bold'}>
                                                FUHFUM Full Program Referrals
                                            </Typography>
                                            <CardContent>
                                                <Typography variant="h4" fontWeight="bold" color="#06a106">
                                                    {fullProgramReferral.FuhfumFullProgramReferralCount}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Card
                                            elevation={10}
                                            sx={{
                                                maxHeight: '150px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                padding: 2,
                                                justifyContent: 'space-between',
                                            }}
                                            className="pointer"
                                            onClick={() => navigateToMembers(2)}
                                        >
                                            <Typography variant="body1" fontWeight={'bold'}>
                                                HRA Full Program Referrals
                                            </Typography>
                                            <CardContent>
                                                <Typography variant="h4" fontWeight="bold" color="#ff8452">
                                                    {fullProgramReferral.HraFullProgramReferralCount}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default FullProgramReferrals;
