import { Box, Button, Divider, FormControl, FormGroup, Input, InputLabel, MenuItem, Modal, Paper, Select, Stack, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import MemberAvatar from './MemberAvatar';
import { useEffect, useState } from 'react';
import {
    fetchMemberHealthPlans,
    getHealthPlanId,
    saveHealthPlan,
    deleteHealthPlan,
    fetchInsuranceTypes,
    fetchHealthPlans,
} from '../../../store/memberDetail.slice';
import TextMaskCustom from '../../Shared/PhoneMask';
import { healthPlanPriorities, modalStyle, states } from '../../../utils/constants';
import { CheckCircle } from '@mui/icons-material';

const MemberHealthPlan = () => {
    const getDefaultAddress = () => {
        return {
            Address1: '',
            Address2: '',
            City: '',
            State: 'Arizona',
            ZipCode: '',
        };
    };

    const getDefault = () => {
        return {
            Id: null,
            Name: '',
            OrganizationId: '',
            OrganizationName: '',
            MemberId: selectedMember.Id,
            InsuranceType: -1,
            HealthPlanId: '',
            Mem_AHCCCS_ID: '',
            Address: getDefaultAddress(),
            PhoneNumber: '',
            Priority: '',
            IsContractingPlan: false,
        };
    };

    const reset = (row: any) => {
        let update = row ? { ...row } : getDefault();
        if (update.Address === null) {
            update.Address = getDefaultAddress();
        }

        setFormHP(update);
    };

    const addHandler = (row: any = null) => {
        reset(row);
        setShowModal(true);
    };

    const dispatch = useDispatch();
    const { healthPlans, insuranceTypes, selectedMember, memberHealthPlans } = useSelector((state: RootState) => state.memberDetail);
    const [showModal, setShowModal] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [formHP, setFormHP] = useState(getDefault());

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Plan',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'OrganizationId',
            headerName: 'Org Id',
            valueGetter: (params: GridValueGetterParams) => {
                const insuranceType = insuranceTypes.find((x) => parseInt(x.value) === params.row.InsuranceType);
                if (insuranceType) {
                    return getHealthPlanId(params.row, healthPlans, insuranceTypes);
                }
                return params.row.OrganizationId;
            },
        },
        {
            field: 'OrganizationName',
            headerName: 'Org Name',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'InsuranceType',
            headerName: 'Insurance Type',
            minWidth: 150,
            valueGetter: (params: GridValueGetterParams) => insuranceTypes.find((x) => parseInt(x.value) === params.row.InsuranceType)?.label,
        },
        {
            field: 'Mem_AHCCCS_ID',
            headerName: 'Plan Member Id',
            minWidth: 200,
        },
        {
            field: 'Address1',
            headerName: 'Address',
            minWidth: 100,
            valueGetter: (params: GridValueGetterParams) => params.row.Address?.Address1,
        },
        {
            field: 'City',
            headerName: 'City',
            minWidth: 100,
            valueGetter: (params: GridValueGetterParams) => params.row.Address?.City,
        },
        {
            field: 'State',
            headerName: 'State',
            minWidth: 100,
            valueGetter: (params: GridValueGetterParams) => params.row.Address?.State,
        },
        {
            field: 'ZipCode',
            headerName: 'Zip Code',
            minWidth: 100,
            valueGetter: (params: GridValueGetterParams) => params.row.Address?.ZipCode,
        },
        {
            field: 'PhoneNumber',
            headerName: 'Phone',
            minWidth: 150,
            renderCell: (params) => {
                return <Input readOnly value={params.row.PhoneNumber} name="textmask" id="formatted-text-mask-input" inputComponent={TextMaskCustom as any} />;
            },
        },
        {
            field: 'IsContractingPlan',
            headerName: 'Contracting Plan',
            minWidth: 100,
            renderCell: (params) => {
                return <>{params.row.IsContractingPlan && <CheckCircle style={{ color: 'green' }} />}</>;
            },
        },
        {
            field: 'Priority',
            headerName: 'Plan Priority',
            minWidth: 100,
        },
        {
            field: 'action',
            headerName: 'Action',
            minWidth: 100,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Edit
                            className="icon-left"
                            onClick={() => {
                                addHandler(params.row);
                                setShowModal(true);
                            }}
                        />
                        {!params.row.IsContractingPlan && (
                            <Delete
                                className="icon-right"
                                onClick={() => {
                                    setFormHP(params.row);
                                    setShowDelete(true);
                                }}
                            />
                        )}
                    </>
                );
            },
        },
    ];

    const addHPHandler = (row: any = null) => {
        reset(row);
        setShowModal(true);
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let update = { ...formHP };
        update.PhoneNumber = event.target.value;

        setFormHP(update);
    };

    const deleteHandler = () => {
        dispatch(deleteHealthPlan(formHP.Id));
        setShowDelete(false);
    };

    const saveHP = () => {
        let update = { ...formHP };
        update.PhoneNumber = formHP.PhoneNumber.replace(/[^\d.]/g, '');
        dispatch(saveHealthPlan(update));
        setShowModal(false);
    };

    const changeAddress = (property, value) => {
        var update = { ...formHP };
        update.Address = { ...formHP.Address };
        update.Address[property] = value;

        setFormHP(update);
    };

    const changeHealthPlan = (value) => {
        var selectedPlan = healthPlans.find((x) => x.Id === value);
        if (selectedPlan) {
            var update = { ...formHP };
            update.HealthPlanId = selectedPlan.Id;
            update.MemberId = selectedMember.Id;
            update.Mem_AHCCCS_ID = selectedMember.Mem_AHCCCS_ID;
            update.Name = selectedPlan.Name ? selectedPlan.Name : '';
            update.OrganizationId = selectedPlan.OrganizationId ? selectedPlan.OrganizationId : '';
            update.OrganizationName = selectedPlan.OrganizationName ? selectedPlan.OrganizationName : '';
            update.PhoneNumber = selectedPlan.PhoneNumber ? selectedPlan.PhoneNumber : '';
            update.Address = selectedPlan.Address ? selectedPlan.Address : getDefaultAddress();
            update.InsuranceType = selectedPlan.InsuranceType ? selectedPlan.InsuranceType : -1;

            setFormHP(update);
        }
    };

    const renderBody = () => {
        return (
            <Stack spacing={2}>
                <Stack spacing={3}>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Plan Name</InputLabel>
                                <Select
                                    disabled={formHP.IsContractingPlan}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formHP.HealthPlanId}
                                    label="Health Plan"
                                    onChange={(e: any) => {
                                        changeHealthPlan(e.target.value);
                                    }}
                                >
                                    {healthPlans.map(function (hPlan) {
                                        return (
                                            <MenuItem key={hPlan.Id} value={hPlan.Id}>
                                                {hPlan.Name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="formatted-text-mask-input">Phone Number</InputLabel>
                                <Input
                                    disabled
                                    value={formHP.PhoneNumber}
                                    onChange={handlePhoneChange}
                                    name="textmask"
                                    id="formatted-text-mask-input"
                                    inputComponent={TextMaskCustom as any}
                                />
                            </FormControl>
                        </Stack>
                    </FormGroup>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                disabled
                                id="outlined-basic"
                                fullWidth
                                label="Organization Name"
                                variant="outlined"
                                value={formHP.OrganizationName}
                                onChange={(e: any) => {
                                    setFormHP({
                                        ...formHP,
                                        OrganizationName: e.target.value,
                                    });
                                }}
                            />
                            <TextField
                                disabled
                                id="outlined-basic"
                                fullWidth
                                label="Organization Id"
                                variant="outlined"
                                value={formHP.OrganizationId}
                                onChange={(e: any) => {
                                    setFormHP({
                                        ...formHP,
                                        OrganizationId: e.target.value,
                                    });
                                }}
                            />
                            <TextField
                                disabled
                                id="outlined-basic"
                                fullWidth
                                label="Member Id"
                                variant="outlined"
                                value={formHP.Mem_AHCCCS_ID}
                                onChange={(e: any) => {
                                    setFormHP({
                                        ...formHP,
                                        Mem_AHCCCS_ID: e.target.value,
                                    });
                                }}
                            />
                        </Stack>
                    </FormGroup>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                disabled
                                id="outlined-basic"
                                fullWidth
                                label="Address 1"
                                variant="outlined"
                                value={formHP.Address?.Address1}
                                onChange={(e: any) => {
                                    changeAddress('Address1', e.target.value);
                                }}
                            />
                            <TextField
                                disabled
                                id="outlined-basic"
                                fullWidth
                                label="Address 2"
                                variant="outlined"
                                value={formHP.Address?.Address2}
                                onChange={(e: any) => {
                                    changeAddress('Address2', e.target.value);
                                }}
                            />
                        </Stack>
                    </FormGroup>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                disabled
                                id="outlined-basic"
                                fullWidth
                                label="City"
                                variant="outlined"
                                value={formHP.Address?.City}
                                onChange={(e: any) => {
                                    changeAddress('City', e.target.value);
                                }}
                            />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">State</InputLabel>
                                <Select
                                    disabled
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formHP.Address?.State}
                                    label="State"
                                    onChange={(e: any) => {
                                        changeAddress('State', e.target.value);
                                    }}
                                >
                                    {states.map(function (state) {
                                        return (
                                            <MenuItem key={state} value={state}>
                                                {state}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <TextField
                                disabled
                                id="outlined-basic"
                                fullWidth
                                label="Zip Code"
                                variant="outlined"
                                value={formHP.Address?.ZipCode}
                                onChange={(e: any) => {
                                    changeAddress('ZipCode', e.target.value);
                                }}
                                type={'number'}
                            />
                        </Stack>
                    </FormGroup>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Insurance Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formHP.InsuranceType}
                                    label="Insurance Type"
                                    onChange={(e: any) => {
                                        setFormHP({
                                            ...formHP,
                                            InsuranceType: e.target.value,
                                        });
                                    }}
                                >
                                    {insuranceTypes.map(function (insuranceType) {
                                        return (
                                            <MenuItem key={insuranceType.value} value={insuranceType.value}>
                                                {insuranceType.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Plan Priority</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formHP.Priority}
                                    label="Insurance Type"
                                    onChange={(e: any) => {
                                        setFormHP({
                                            ...formHP,
                                            Priority: e.target.value,
                                        });
                                    }}
                                >
                                    {healthPlanPriorities.map(function (planPriority) {
                                        return (
                                            <MenuItem key={planPriority.value} value={planPriority.value}>
                                                {planPriority.value}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>
                    </FormGroup>
                </Stack>
                <Box>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        <Button className="button-120" variant="contained" onClick={saveHP}>
                            Save
                        </Button>
                        <Button
                            className="button-120"
                            variant="outlined"
                            onClick={() => {
                                setShowModal(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        );
    };

    const renderDeleteBody = () => {
        return (
            <Stack spacing={2}>
                <div className="row">
                    <Typography sx={{ ml: 3 }} variant="h6">
                        Are you sure do you want to delete: {formHP.Name}?
                    </Typography>
                </div>
                <Box>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        <Button className="button-120" variant="contained" color="error" onClick={() => deleteHandler()}>
                            Delete
                        </Button>
                        <Button
                            className="button-120"
                            variant="outlined"
                            onClick={() => {
                                setShowDelete(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        );
    };

    useEffect(() => {
        dispatch(
            fetchHealthPlans(() => {
                dispatch(fetchMemberHealthPlans(selectedMember.Id));
            })
        );
        dispatch(fetchInsuranceTypes());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //Set the Organization Id from the plan after the Insurance Type is selected
        if (formHP.InsuranceType > -1) {
            setFormHP({
                ...formHP,
                OrganizationId: getHealthPlanId(formHP, healthPlans, insuranceTypes),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formHP.InsuranceType]);

    return (
        <div>
            <Paper
                elevation={3}
                style={{
                    height: 'calc(100vh - 100px)',
                    padding: '8px',
                    margin: '16px',
                    textAlign: 'right',
                }}
            >
                <Stack spacing={2}>
                    <div>
                        <MemberAvatar />
                        <Button
                            style={{ marginBottom: '16px' }}
                            variant="contained"
                            onClick={() => {
                                addHPHandler();
                            }}
                        >
                            Add Health Plan
                        </Button>
                    </div>
                    <DataGrid
                        autoHeight
                        getRowId={(row) => row.Id}
                        rows={memberHealthPlans}
                        columns={columns}
                        experimentalFeatures={{ newEditingApi: true }}
                        hideFooter
                    />
                </Stack>
            </Paper>
            <Modal
                open={showModal}
                onClose={() => {
                    setShowModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Stack spacing={2}>
                        <Typography variant="h5">Health Plan</Typography>
                        <Divider />
                        {renderBody()}
                    </Stack>
                </Box>
            </Modal>
            <Modal
                open={showDelete}
                onClose={() => {
                    setShowDelete(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Stack spacing={2}>
                        <Typography variant="h5">Delete Health Plan</Typography>
                        <Divider />
                        {renderDeleteBody()}
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
};

export default MemberHealthPlan;
