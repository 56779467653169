import { Grid, Paper, Typography, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Box, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import b64toBlob from 'b64-to-blob';

import { fetchFuhFumUndeterminedSummary, exportFuhFumUndeterminedSummary } from '../../../Widgets/WidgetService';
import { useWidgetContext } from '../../../../hooks/widgetContext';
import { parentUrl } from '../../../../utils/constants';
import { isDefaultDate } from '../../../../utils/common';
import { ReferralDaysEnum } from '../../../../Enum/ReferralDaysEnum';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { dateFormatter } from '../../../../utils/timeFormat';
import '../../Widgets.css';

const daysList = [
    { Id: ReferralDaysEnum.From1To7Days, Name: ReferralDaysEnum.From1To7DaysDescription },
    { Id: ReferralDaysEnum.From8To30Days, Name: ReferralDaysEnum.From8To30DaysDescription },
];

const FuhFumUndeterminedSummaryDetail = () => {
    const history = useNavigate();
    const [rows, setRows] = useState([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [downLoading, setDownLoading] = useState<boolean>(false);
    const {
        undeterminedReferralDay,
        setUndeterminedReferralDay,
        undeterminedIncludeScheduledStatus,
        setUndeterminedIncludeScheduledStatus,
    } = useWidgetContext();
    const [pageSize, setPageSize] = useState(15);

    const getFuhFumSummary = async () => {
        setIsFetched(false);
        const data = await fetchFuhFumUndeterminedSummary(undeterminedReferralDay, undeterminedIncludeScheduledStatus);
        setRows(data);
        setIsFetched(true);
    };

    useEffect(() => {
        getFuhFumSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [undeterminedReferralDay, undeterminedIncludeScheduledStatus]);

    const backToWidget = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    const exportDetail = async () => {
        setDownLoading(true);
        const data = await exportFuhFumUndeterminedSummary(undeterminedReferralDay, undeterminedIncludeScheduledStatus);
        let blob = b64toBlob(data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        var date = new Date();
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var dateInfo = [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('');
        link.setAttribute('download', `FuhFumUndeterminedMembers - ${dateInfo}.xlsx`);
        link.click();
        link.remove();

        setDownLoading(false);
    };

    const columns: GridColDef[] = [
        {
            field: 'MemberName',
            headerName: 'Member Name',
            flex: 1,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            flex: 0.8,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : dateFormatter(new Date(params.row.DOB))}`,
        },
        {
            field: 'ReferralDate',
            headerName: 'Date Referred',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.ReferralDate) ? '-' : dateFormatter(new Date(params.row.ReferralDate))}`,
        },
        {
            field: 'LastAppointmentDate',
            headerName: 'Appointment',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.LastAppointmentDate) ? '-' : dateFormatter(new Date(params.row.LastAppointmentDate))}`,
        },
        {
            field: 'NumberOfOutreachCalls',
            headerName: '# Outreach Calls',
            flex: 1,
        },
        {
            field: 'LastAttemptDate',
            headerName: 'Last Contacted',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.LastAttemptDate) ? '-' : dateFormatter(new Date(params.row.LastAttemptDate))}`,
        },
        {
            field: 'IsMemberReached',
            headerName: 'Member Reached',
            flex: 1.2,
            valueGetter: (params: GridValueGetterParams) => `${Boolean(params.row.IsMemberReached) ? 'Yes' : 'No'}`,
        },
    ];

    const prepareCloumns = () => {
        let columnsData = columns;
        if (undeterminedIncludeScheduledStatus === false) {
            columnsData = columnsData.filter((c) => c.field !== 'LastAppointmentDate');
        }
        return columnsData;
    };

    return (
        <Paper elevation={5}>
            <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1}>
                <Grid item xs={12} paddingBottom={1}>
                    <span onClick={backToWidget}>
                        <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                    </span>
                    <Typography display={'inline-block'} variant="h6" color={'primary'}>
                        FUHFUM Undetermined Summary
                    </Typography>
                </Grid>
            </Grid>
            <Grid container pl={2} mt={0} spacing={2}>
                <Grid item xs={1.5}>
                    <FormControl size="small" fullWidth>
                        <InputLabel>Referral Days</InputLabel>
                        <Select
                            value={undeterminedReferralDay}
                            label="Referral Days"
                            onChange={(e) => {
                                setUndeterminedReferralDay(e.target.value as string);
                            }}
                        >
                            {daysList.map(function (Pro) {
                                return (
                                    <MenuItem key={Pro.Id} value={Pro.Id}>
                                        {Pro.Name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={1.5}>
                    <FormControlLabel
                        checked={undeterminedIncludeScheduledStatus}
                        control={<Checkbox onChange={(e) => setUndeterminedIncludeScheduledStatus(e.target.checked)} />}
                        label="Include Schedule Status"
                    />
                </Grid>
                <Grid item xs={6.5}></Grid>
                <Grid item xs={1}>
                    <Button
                        style={{ minWidth: '120px', marginLeft: 'auto' }}
                        onClick={() => exportDetail()}
                        variant="contained"
                        color="success"
                        disabled={rows.length ? false : true}
                    >
                        {downLoading ? 'Exporting..' : 'Export'}
                    </Button>
                </Grid>
            </Grid>
            <hr />
            <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                <Grid item xs={12} minHeight="60vh" sx={{ position: 'relative' }}>
                    {!isFetched ? (
                        <Box className="loader-center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <div style={{ width: '100%', height: 'Calc(65vh - 65px)', position: 'relative', paddingTop: '10px' }}>
                            <DataGrid
                                onCellClick={(params: any) => {
                                    window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                }}
                                rows={rows}
                                columns={prepareCloumns()}
                                pageSize={pageSize}
                                rowsPerPageOptions={[5]}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                getRowId={(row) => row.Id}
                                getRowHeight={() => 'auto'}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                }}
                            />
                        </div>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default FuhFumUndeterminedSummaryDetail;
