export enum HamiltonAnxietyScaleValuesEnum {
    NotPresent = 0,
    NotPresentDescription = 'Not Present',
    Mild = 1,
    MildDescription = 'Mild',
    Moderate = 2,
    ModerateDescription = 'Moderate',
    Severe = 3,
    SevereDescription = 'Severe',
    VerySevere = 4,
    VerySevereDescription = 'Very Severe',
}
