import { Grid, Paper, Typography, IconButton, Tooltip, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router';
import { DataGrid, GridColDef, gridClasses, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState, useRef } from 'react';

import { useWidgetContext } from '../../../../hooks/widgetContext';
import WidgetLoading from '../../../SmartDashboard/Shared/WidgetLoading';
import { dateFormatter } from '../../../../utils/timeFormat';
import { fetchFuhFumUndeterminedSummary } from '../../../Widgets/WidgetService';
import { isDefaultDate } from '../../../../utils/common';
import { parentUrl } from '../../../../utils/constants';
import { ReferralDaysEnum } from '../../../../Enum/ReferralDaysEnum';

const daysList = [
    { Id: ReferralDaysEnum.From1To7Days, Name: ReferralDaysEnum.From1To7DaysDescription },
    { Id: ReferralDaysEnum.From8To30Days, Name: ReferralDaysEnum.From8To30DaysDescription },
];

const FuhFumUndeterminedSummary = () => {
    const history = useNavigate();
    const [rows, setRows] = useState([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const [pageSize, setPageSize] = useState(15);
    const ref = useRef(null);
    const { undeterminedReferralDay, setUndeterminedReferralDay, undeterminedIncludeScheduledStatus, setUndeterminedIncludeScheduledStatus } =
        useWidgetContext();

    const handleClick = (event) => {
        setShow(!show);
    };

    const columns: GridColDef[] = [
        {
            field: 'MemberName',
            headerName: 'Member Name',
            flex: 1,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            flex: 0.8,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : dateFormatter(new Date(params.row.DOB))}`,
        },
        {
            field: 'ReferralDate',
            headerName: 'Date Referred',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.ReferralDate) ? '-' : dateFormatter(new Date(params.row.ReferralDate))}`,
        },
        {
            field: 'LastAppointmentDate',
            headerName: 'Appointment',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.LastAppointmentDate) ? '-' : dateFormatter(new Date(params.row.LastAppointmentDate))}`,
        },
        {
            field: 'NumberOfOutreachCalls',
            headerName: '# Outreach Calls',
            flex: 1,
        },
        {
            field: 'LastAttemptDate',
            headerName: 'Last Contacted',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.LastAttemptDate) ? '-' : dateFormatter(new Date(params.row.LastAttemptDate))}`,
        },
        {
            field: 'IsMemberReached',
            headerName: 'Member Reached',
            flex: 1.2,
            valueGetter: (params: GridValueGetterParams) => `${Boolean(params.row.IsMemberReached) ? 'Yes' : 'No'}`,
        },
    ];

    const prepareCloumns = () => {
        let columnsData = columns;
        if (undeterminedIncludeScheduledStatus === false) {
            columnsData = columnsData.filter((c) => c.field !== 'LastAppointmentDate');
        }
        return columnsData;
    };

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 80 : 90) + ''));
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFuhFumSummary = async () => {
        setIsFetched(false);
        const data = await fetchFuhFumUndeterminedSummary(undeterminedReferralDay, undeterminedIncludeScheduledStatus);
        setRows(data);
        setIsFetched(true);
    };

    useEffect(() => {
        getFuhFumSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [undeterminedReferralDay, undeterminedIncludeScheduledStatus]);

    const navigateToMembers = () => {
        history(`/provider/metricsummary/fuhfumundeterminedmemberdetail`);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography color={'primary'} variant="h6" textAlign={'center'} ref={ref}>
                            <span className="pointer" onClick={navigateToMembers}>
                                FUHFUM Undetermined Summary
                            </span>
                            <Tooltip
                                open={show}
                                title={
                                    <div>
                                        <Typography variant="caption">
                                            Members with FUH-UND or Undetermined status and/or Scheduled status within the referral days.
                                        </Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Grid>
                    <Grid item xs={2.4}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Referral Days</InputLabel>
                            <Select
                                value={undeterminedReferralDay}
                                label="Referral Days"
                                onChange={(e) => {
                                    setUndeterminedReferralDay(e.target.value as string);
                                }}
                            >
                                {daysList.map(function (Pro) {
                                    return (
                                        <MenuItem key={Pro.Id} value={Pro.Id}>
                                            {Pro.Name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            checked={undeterminedIncludeScheduledStatus}
                            control={<Checkbox onChange={(e) => setUndeterminedIncludeScheduledStatus(e.target.checked)} />}
                            label="Include Scheduled Status"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} marginTop={2}>
                    <div style={{ width: '100%', height: 'Calc(50vh - 65px)', position: 'relative' }}>
                        {!isFetched ? (
                            <WidgetLoading />
                        ) : (
                            <DataGrid
                                onCellClick={(params: any) => {
                                    window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                }}
                                rows={rows}
                                columns={prepareCloumns()}
                                pageSize={pageSize}
                                rowsPerPageOptions={[5]}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                getRowId={(row) => row.Id}
                                getRowHeight={() => 'auto'}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                }}
                            />
                        )}
                    </div>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default FuhFumUndeterminedSummary;
