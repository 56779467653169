import React, { useEffect, useState } from 'react';
import { Paper, TextField, Tabs, Tab, FormControlLabel, Checkbox } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parentUrl } from '../../../utils/constants';
import { useWidgetContext } from '../../../hooks/widgetContext';
import { MenuProps, fetchFullProgramAppointments, fetchHraAppointments } from '../WidgetService';
import { DataGrid, gridClasses, GridColDef} from '@mui/x-data-grid';
import { AppointmentTimeFilterEnum } from '../../../Enum/AppointmentTimeFilterEnum';
import { useHealthPlanContext } from '../../../hooks/healthPlanContext';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import AppointmentTrackingSummary from '../../../Models/Widget/AppointmentTrackingSummary.model';
import WidgetLoading from '../../SmartDashboard/Shared/WidgetLoading';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../Widgets.css';

const dayFilterList = [
    { Id: AppointmentTimeFilterEnum.Next30Days, Value: AppointmentTimeFilterEnum.Next30DaysDescription },
    { Id: AppointmentTimeFilterEnum.Next60Days, Value: AppointmentTimeFilterEnum.Next60DaysDescription },
    { Id: AppointmentTimeFilterEnum.Next90Days, Value: AppointmentTimeFilterEnum.Next90DaysDescription },
    { Id: AppointmentTimeFilterEnum.Custom, Value: AppointmentTimeFilterEnum.CustomDescription },
];

const AppointmentTrackingSummaryDetail = () => {
    const history = useNavigate();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const { healthPlans } = useHealthPlanContext();
    const { population } = useSelector((state: RootState) => state.adminMembers);
    const { activeProviders, npProviders } = useSelector((state: RootState) => state.provider);
    const { appointmentTrackingTab, setAppointmentTrackingTab } = useWidgetContext();
    const { fullProgramAppointment, setFullProgramAppointment } = useWidgetContext();
    const { hraAppointment, setHraAppointment } = useWidgetContext();
    const [fullProgramSummary, setFullProgramSummary] = useState<AppointmentTrackingSummary>();
    const [hraSummary, setHraSummary] = useState<AppointmentTrackingSummary>();
    const filteredProviders =
        (fullProgramAppointment.includeInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const filteredStaffs =
        (fullProgramAppointment.includeInActiveStaffs ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const filteredHraProviders = (hraAppointment.includeInActiveProviders ? npProviders : npProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const filteredHraStaffs = (hraAppointment.includeInActiveStaffs ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const [pageSize, setPageSize] = useState(15);

    const filteredHealthPlans = healthPlans
        .filter((item: any) => {
            return population.map((item) => item.HealthPlan?.Name).includes(item.Name);
        })
        .sort((a, b) => a.Name.localeCompare(b.Name));

    const fullProgramDetail = async () => {
        if (
            fullProgramAppointment.timeFilter !== AppointmentTimeFilterEnum.Custom ||
            (Boolean(fullProgramAppointment.fromDate) && Boolean(fullProgramAppointment.toDate))
        ) {
            const changeFilter = async () => {
                const data = await fetchFullProgramAppointments(
                    fullProgramAppointment.provider,
                    fullProgramAppointment.schedulingStaff,
                    fullProgramAppointment.healthPlan,
                    fullProgramAppointment.timeFilter,
                    Boolean(fullProgramAppointment.fromDate) && fullProgramAppointment.timeFilter === AppointmentTimeFilterEnum.Custom
                        ? new Date(fullProgramAppointment.fromDate).toISOString()
                        : '',
                    Boolean(fullProgramAppointment.toDate) && fullProgramAppointment.timeFilter === AppointmentTimeFilterEnum.Custom
                        ? new Date(fullProgramAppointment.toDate).toISOString()
                        : '',
                    fullProgramAppointment.includeInActiveProviders,
                    fullProgramAppointment.includeInActiveStaffs,
                    true
                );
                setFullProgramSummary(data);
                setIsFetched(true);
            };
            setIsFetched(false);
            changeFilter();
        }

        if (
            fullProgramAppointment.timeFilter !== AppointmentTimeFilterEnum.Custom &&
            Boolean(fullProgramAppointment.fromDate) &&
            Boolean(fullProgramAppointment.toDate)
        ) {
            fullProgramAppointment.fromDate = null;
            fullProgramAppointment.toDate = null;
            setFullProgramAppointment(fullProgramAppointment);
        }
    };

    const hraDetail = async () => {
        if (hraAppointment.timeFilter !== AppointmentTimeFilterEnum.Custom || (Boolean(hraAppointment.fromDate) && Boolean(hraAppointment.toDate))) {
            const changeFilter = async () => {
                const data = await fetchHraAppointments(
                    hraAppointment.provider,
                    hraAppointment.schedulingStaff,
                    hraAppointment.healthPlan,
                    hraAppointment.timeFilter,
                    Boolean(hraAppointment.fromDate) && hraAppointment.timeFilter === AppointmentTimeFilterEnum.Custom
                        ? new Date(hraAppointment.fromDate).toISOString()
                        : '',
                    Boolean(hraAppointment.toDate) && hraAppointment.timeFilter === AppointmentTimeFilterEnum.Custom
                        ? new Date(hraAppointment.toDate).toISOString()
                        : '',
                    hraAppointment.includeInActiveProviders,
                    hraAppointment.includeInActiveStaffs,
                    true
                );
                setHraSummary(data);
                setIsFetched(true);
            };
            setIsFetched(false);
            changeFilter();
        }

        if (hraAppointment.timeFilter !== AppointmentTimeFilterEnum.Custom && Boolean(hraAppointment.fromDate) && Boolean(hraAppointment.toDate)) {
            hraAppointment.fromDate = null;
            hraAppointment.toDate = null;
            setHraAppointment(hraAppointment);
        }
    };

    useEffect(() => {
        if (appointmentTrackingTab === 2) {
            hraDetail();
        } else {
            setAppointmentTrackingTab(1);
            fullProgramDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentTrackingTab]);

    useEffect(() => {
        if (appointmentTrackingTab === 1) {
            fullProgramDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullProgramAppointment]);

    useEffect(() => {
        if (appointmentTrackingTab === 2) {
            hraDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hraAppointment]);

    const changeFromDate = (date: any) => {
        if (appointmentTrackingTab === 1) {
            const updatedFullProgramAppointment = { ...fullProgramAppointment, fromDate: date };
            setFullProgramAppointment(updatedFullProgramAppointment);
        } else if (appointmentTrackingTab === 2) {
            const updatedHraAppointment = { ...hraAppointment, fromDate: date };
            setHraAppointment(updatedHraAppointment);
        }
    };

    const changeToDate = (date: any) => {
        if (appointmentTrackingTab === 1) {
            const updatedFullProgramAppointment = { ...fullProgramAppointment, toDate: date };
            setFullProgramAppointment(updatedFullProgramAppointment);
        } else if (appointmentTrackingTab === 2) {
            const updatedHraAppointment = { ...hraAppointment, toDate: date };
            setHraAppointment(updatedHraAppointment);
        }
    };

    const toggleProviderHandler = (isChecked: boolean) => {
        if (appointmentTrackingTab === 1) {
            const updatedFullProgramAppointment = { ...fullProgramAppointment, includeInActiveProviders: isChecked, provider: 'All' };
            setFullProgramAppointment(updatedFullProgramAppointment);
        } else if (appointmentTrackingTab === 2) {
            const updatedHraAppointment = { ...hraAppointment, includeInActiveProviders: isChecked, provider: 'All' };
            setHraAppointment(updatedHraAppointment);
        }
    };

    const toggleStaffHandler = (isChecked: boolean) => {
        if (appointmentTrackingTab === 1) {
            const updatedFullProgramAppointment = { ...fullProgramAppointment, includeInActiveStaffs: isChecked, schedulingStaff: 'All' };
            setFullProgramAppointment(updatedFullProgramAppointment);
        } else if (appointmentTrackingTab === 2) {
            const updatedHraAppointment = { ...hraAppointment, includeInActiveStaffs: isChecked, schedulingStaff: 'All' };
            setHraAppointment(updatedHraAppointment);
        }
    };

    const handleAlertsTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setAppointmentTrackingTab(newValue);
    };

    const totalColumns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Member Name',
            minWidth: 220,
            flex: 1.3,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.Name}
                    </Typography>
                );
            },
        },
        {
            field: 'AppointmentDate',
            headerName: 'Date of Onboard Appointment',
            minWidth: 220,
            flex: 1.2,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.AppointmentDate}
                    </Typography>
                );
            },
        },
        {
            field: 'ScheduledProvider',
            headerName: 'Scheduled By',
            minWidth: 180,
            flex: 1,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.ScheduledProvider}
                    </Typography>
                );
            },
        },
        {
            field: 'IsDBOnboard',
            headerName: 'Is DriveBy Onboard',
            minWidth: 180,
            flex: 1,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {Boolean(params.row.IsDBOnboard) ? 'Yes' : 'No'}
                    </Typography>
                );
            },
        },
    ];

    const activeColumns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Member Name',
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.Name}
                    </Typography>
                );
            },
        },
        {
            field: 'AppointmentDate',
            headerName: 'Date of Onboard Appointment',
            minWidth: 210,
            flex: 1.2,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.AppointmentDate}
                    </Typography>
                );
            },
        },
        {
            field: 'ScheduledProvider',
            headerName: 'Scheduled By',
            minWidth: 200,
            flex: 1.2,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.ScheduledProvider}
                    </Typography>
                );
            },
        },
        {
            field: 'DateTurnedToStatus',
            headerName: 'Date turned to Active',
            minWidth: 200,
            flex: 1.2,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.DateTurnedToStatus}
                    </Typography>
                );
            },
        },
        {
            field: 'IsOnboarded',
            headerName: 'Is Onboarded',
            minWidth: 120,
            flex: 1.2,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {Boolean(params.row.IsOnboarded) ? 'Yes' : 'No'}
                    </Typography>
                );
            },
        },
        {
            field: 'IsDBOnboard',
            headerName: 'Is DriveBy Onboard',
            minWidth: 180,
            flex: 1,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {Boolean(params.row.IsDBOnboard) ? 'Yes' : 'No'}
                    </Typography>
                );
            },
        },
    ];

    const refusedColumns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Member Name',
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.Name}
                    </Typography>
                );
            },
        },
        {
            field: 'AppointmentDate',
            headerName: 'Date Of Onboard Appointment',
            minWidth: 220,
            flex: 1,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.AppointmentDate}
                    </Typography>
                );
            },
        },
        {
            field: 'ScheduledProvider',
            headerName: 'Scheduled By',
            minWidth: 200,
            flex: 1.2,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.ScheduledProvider}
                    </Typography>
                );
            },
        },
        {
            field: 'DateTurnedToStatus',
            headerName: 'Date turned to Refused to Enroll',
            minWidth: 220,
            flex: 1.5,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.DateTurnedToStatus}
                    </Typography>
                );
            },
        },
        {
            field: 'IsDBOnboard',
            headerName: 'Is DriveBy Onboard',
            minWidth: 180,
            flex: 1,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {Boolean(params.row.IsDBOnboard) ? 'Yes' : 'No'}
                    </Typography>
                );
            },
        },
    ];

    const totalHraColumns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Member Name',
            minWidth: 220,
            flex: 1.3,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.Name}
                    </Typography>
                );
            },
        },
        {
            field: 'AppointmentDate',
            headerName: 'Date of HRA Appointment',
            minWidth: 200,
            flex: 1.2,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.AppointmentDate}
                    </Typography>
                );
            },
        },
        {
            field: 'ScheduledProvider',
            headerName: 'Scheduled By',
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.ScheduledProvider}
                    </Typography>
                );
            },
        },
        {
            field: 'IsDBOnboard',
            headerName: 'Is DriveBy Onboard',
            minWidth: 180,
            flex: 1,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {Boolean(params.row.IsDBOnboard) ? 'Yes' : 'No'}
                    </Typography>
                );
            },
        },
    ];

    const hraInReviewColumns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Member Name',
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.Name}
                    </Typography>
                );
            },
        },
        {
            field: 'AppointmentDate',
            headerName: 'Date of HRA Appointment',
            minWidth: 200,
            flex: 1.2,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.AppointmentDate}
                    </Typography>
                );
            },
        },
        {
            field: 'ScheduledProvider',
            headerName: 'Scheduled By',
            minWidth: 200,
            flex: 1.2,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.ScheduledProvider}
                    </Typography>
                );
            },
        },
        {
            field: 'DateTurnedToStatus',
            headerName: 'Date turned to HRAInReview',
            minWidth: 200,
            flex: 1.2,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.DateTurnedToStatus}
                    </Typography>
                );
            },
        },
        {
            field: 'IsDBOnboard',
            headerName: 'Is DriveBy Onboard',
            minWidth: 180,
            flex: 1,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {Boolean(params.row.IsDBOnboard) ? 'Yes' : 'No'}
                    </Typography>
                );
            },
        },
    ];

    const hraRefusedColumns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Member Name',
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.Name}
                    </Typography>
                );
            },
        },
        {
            field: 'AppointmentDate',
            headerName: 'Date of HRA Appointment',
            minWidth: 200,
            flex: 1.2,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.AppointmentDate}
                    </Typography>
                );
            },
        },
        {
            field: 'ScheduledProvider',
            headerName: 'Scheduled By',
            minWidth: 200,
            flex: 1.2,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.ScheduledProvider}
                    </Typography>
                );
            },
        },
        {
            field: 'DateTurnedToStatus',
            headerName: 'Date turned to Refused to Enroll',
            minWidth: 230,
            flex: 1.2,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.row.DateTurnedToStatus}
                    </Typography>
                );
            },
        },
        {
            field: 'IsDBOnboard',
            headerName: 'Is DriveBy Onboard',
            minWidth: 180,
            flex: 1,
            renderCell: (params) => {
                const color = params.row.IsDBOnboard ? '#FFA500' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {Boolean(params.row.IsDBOnboard) ? 'Yes' : 'No'}
                    </Typography>
                );
            },
        },
    ];

    const backToWidget = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1} paddingBottom={2}>
                    <Grid item xs={12}>
                        <span onClick={backToWidget}>
                            <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                        </span>
                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                            Appointment Tracking Summary
                        </Typography>
                    </Grid>
                    <Grid item xs={12} display={'flex'} mb={2.5}>
                        <Tabs sx={{ display: 'inline-flex', marginRight: 'auto' }} value={appointmentTrackingTab} onChange={handleAlertsTabChange}>
                            <Tab value={1} label="Full Program" />
                            <Tab value={2} label="HRA" />
                        </Tabs>
                    </Grid>
                    {appointmentTrackingTab === 1 ? (
                        <>
                            <Grid item xs={1.5}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel>Providers</InputLabel>
                                    <Select
                                        value={fullProgramAppointment.provider}
                                        label="Providers"
                                        name="Providers"
                                        onChange={(e) => setFullProgramAppointment({ ...fullProgramAppointment, provider: e.target.value })}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem key="All" value="All">
                                            All
                                        </MenuItem>
                                        <FormControlLabel
                                            sx={{ pl: '12px', mb: '0px' }}
                                            control={
                                                <Checkbox
                                                    checked={fullProgramAppointment.includeInActiveProviders}
                                                    onChange={(e) => toggleProviderHandler(e.target.checked)}
                                                />
                                            }
                                            label="Show Inactive"
                                        />
                                        {filteredProviders.map((pro) => (
                                            <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                                {pro.LastName}, {pro.FirstName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1.5}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel>Scheduling Staff</InputLabel>
                                    <Select
                                        value={fullProgramAppointment.schedulingStaff}
                                        label="Scheduling Staff"
                                        name="Scheduling Staff"
                                        onChange={(e) => setFullProgramAppointment({ ...fullProgramAppointment, schedulingStaff: e.target.value })}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem key="All" value="All">
                                            All
                                        </MenuItem>
                                        <FormControlLabel
                                            sx={{ pl: '12px', mb: '0px' }}
                                            control={
                                                <Checkbox
                                                    checked={fullProgramAppointment.includeInActiveStaffs}
                                                    onChange={(e) => toggleStaffHandler(e.target.checked)}
                                                />
                                            }
                                            label="Show Inactive"
                                        />
                                        {filteredStaffs.map((pro) => (
                                            <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                                {pro.LastName}, {pro.FirstName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1.5}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel>Health Plan</InputLabel>
                                    <Select
                                        value={fullProgramAppointment.healthPlan}
                                        label="Health Plan"
                                        name="plan"
                                        onChange={(e) => {
                                            setFullProgramAppointment({ ...fullProgramAppointment, healthPlan: e.target.value });
                                        }}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem key="All" value="All">
                                            All
                                        </MenuItem>
                                        {filteredHealthPlans.map((plan) => (
                                            <MenuItem key={plan.Id} value={plan.Id}>
                                                {plan.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1.5}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel>Date Filter</InputLabel>
                                    <Select
                                        value={fullProgramAppointment.timeFilter}
                                        label="Date Filter"
                                        name="Date"
                                        onChange={(e) =>
                                            setFullProgramAppointment({
                                                ...fullProgramAppointment,
                                                timeFilter: parseInt(e.target.value as any),
                                            })
                                        }
                                    >
                                        {dayFilterList.map((date) => (
                                            <MenuItem key={date.Id} value={date.Id}>
                                                {date.Value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {fullProgramAppointment.timeFilter === AppointmentTimeFilterEnum.Custom ? (
                                <React.Fragment>
                                    <Grid item md={1.5}>
                                        <DesktopDatePicker
                                            label="From Date"
                                            value={Boolean(fullProgramAppointment.fromDate) ? fullProgramAppointment.fromDate : null}
                                            minDate={new Date('2017-01-01')}
                                            onChange={(newValue) => changeFromDate(newValue)}
                                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                        />
                                    </Grid>
                                    <Grid item md={1.5}>
                                        <DesktopDatePicker
                                            label="To Date"
                                            value={Boolean(fullProgramAppointment.toDate) ? fullProgramAppointment.toDate : null}
                                            minDate={new Date('2017-01-01')}
                                            onChange={(newValue) => changeToDate(newValue)}
                                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                        />
                                    </Grid>
                                </React.Fragment>
                            ) : null}
                            <Grid item xs={12}>
                                <hr />
                            </Grid>
                            <Grid item xs={12} minHeight="60vh" sx={{ position: 'relative' }}>
                                {!isFetched ? (
                                    <WidgetLoading />
                                ) : (
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Grid item xs={12} textAlign="center">
                                                <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                    Onboard Appointments ({fullProgramSummary?.TotalAppointments?.length ?? 0})
                                                </Typography>
                                            </Grid>
                                            <div style={{ width: '100%', height: 'Calc(60vh - 65px)', position: 'relative' }}>
                                                <DataGrid
                                                    onCellClick={(params: any) => {
                                                        window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                                    }}
                                                    rows={fullProgramSummary?.TotalAppointments || []}
                                                    columns={totalColumns}
                                                    pageSize={pageSize}
                                                    rowsPerPageOptions={[5]}
                                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                    getRowId={(row) => row.Id}
                                                    getRowHeight={() => 'auto'}
                                                    sx={{
                                                        [`& .${gridClasses.cell}`]: {
                                                            py: 1,
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Grid item xs={12} textAlign="center">
                                                <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                    Active Members ({fullProgramSummary?.ActiveMembers?.length ?? 0})
                                                </Typography>
                                            </Grid>
                                            <div style={{ width: '100%', height: 'Calc(60vh - 65px)', position: 'relative' }}>
                                                <DataGrid
                                                    onCellClick={(params: any) => {
                                                        window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                                    }}
                                                    rows={fullProgramSummary?.ActiveMembers || []}
                                                    columns={activeColumns}
                                                    pageSize={pageSize}
                                                    rowsPerPageOptions={[5]}
                                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                    getRowId={(row) => row.Id}
                                                    getRowHeight={() => 'auto'}
                                                    sx={{
                                                        [`& .${gridClasses.cell}`]: {
                                                            py: 1,
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Grid item xs={12} textAlign="center">
                                                <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                    Refused To Enroll Members ({fullProgramSummary?.RefusedMembers?.length ?? 0})
                                                </Typography>
                                            </Grid>
                                            <div style={{ width: '100%', height: 'Calc(60vh - 65px)', position: 'relative' }}>
                                                <DataGrid
                                                    onCellClick={(params: any) => {
                                                        window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                                    }}
                                                    rows={fullProgramSummary?.RefusedMembers || []}
                                                    columns={refusedColumns}
                                                    pageSize={pageSize}
                                                    rowsPerPageOptions={[5]}
                                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                    getRowId={(row) => row.Id}
                                                    getRowHeight={() => 'auto'}
                                                    sx={{
                                                        [`& .${gridClasses.cell}`]: {
                                                            py: 1,
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    ) : appointmentTrackingTab === 2 ? (
                        <>
                            <Grid item xs={1.5}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel>Providers</InputLabel>
                                    <Select
                                        value={hraAppointment.provider}
                                        label="Providers"
                                        name="Providers"
                                        onChange={(e) => setHraAppointment({ ...hraAppointment, provider: e.target.value })}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem key="All" value="All">
                                            All
                                        </MenuItem>
                                        <FormControlLabel
                                            sx={{ pl: '12px', mb: '0px' }}
                                            control={
                                                <Checkbox
                                                    checked={hraAppointment.includeInActiveProviders}
                                                    onChange={(e) => toggleProviderHandler(e.target.checked)}
                                                />
                                            }
                                            label="Show Inactive"
                                        />
                                        {filteredHraProviders.map((pro) => (
                                            <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                                {pro.LastName}, {pro.FirstName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1.5}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel>Scheduling Staff</InputLabel>
                                    <Select
                                        value={hraAppointment.schedulingStaff}
                                        label="Scheduling Staff"
                                        name="Scheduling Staff"
                                        onChange={(e) => setHraAppointment({ ...hraAppointment, schedulingStaff: e.target.value })}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem key="All" value="All">
                                            All
                                        </MenuItem>
                                        <FormControlLabel
                                            sx={{ pl: '12px', mb: '0px' }}
                                            control={
                                                <Checkbox
                                                    checked={hraAppointment.includeInActiveStaffs}
                                                    onChange={(e) => toggleStaffHandler(e.target.checked)}
                                                />
                                            }
                                            label="Show Inactive"
                                        />
                                        {filteredHraStaffs.map((pro) => (
                                            <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                                {pro.LastName}, {pro.FirstName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1.5}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel>Health Plan</InputLabel>
                                    <Select
                                        value={hraAppointment.healthPlan}
                                        label="Health Plan"
                                        name="plan"
                                        onChange={(e) => {
                                            setHraAppointment({ ...hraAppointment, healthPlan: e.target.value });
                                        }}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem key="All" value="All">
                                            All
                                        </MenuItem>
                                        {filteredHealthPlans.map((plan) => (
                                            <MenuItem key={plan.Id} value={plan.Id}>
                                                {plan.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1.5}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel>Date Filter</InputLabel>
                                    <Select
                                        value={hraAppointment.timeFilter}
                                        label="Date Filter"
                                        name="Date"
                                        onChange={(e) =>
                                            setHraAppointment({
                                                ...hraAppointment,
                                                timeFilter: parseInt(e.target.value as any),
                                            })
                                        }
                                    >
                                        {dayFilterList.map((date) => (
                                            <MenuItem key={date.Id} value={date.Id}>
                                                {date.Value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {hraAppointment.timeFilter === AppointmentTimeFilterEnum.Custom ? (
                                <React.Fragment>
                                    <Grid item md={1.5}>
                                        <DesktopDatePicker
                                            label="From Date"
                                            value={Boolean(hraAppointment.fromDate) ? hraAppointment.fromDate : null}
                                            minDate={new Date('2017-01-01')}
                                            onChange={(newValue) => changeFromDate(newValue)}
                                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                        />
                                    </Grid>
                                    <Grid item md={1.5}>
                                        <DesktopDatePicker
                                            label="To Date"
                                            value={Boolean(hraAppointment.toDate) ? hraAppointment.toDate : null}
                                            minDate={new Date('2017-01-01')}
                                            onChange={(newValue) => changeToDate(newValue)}
                                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                        />
                                    </Grid>
                                </React.Fragment>
                            ) : null}
                            <Grid item xs={12}>
                                <hr />
                            </Grid>
                            <Grid item xs={12} minHeight="60vh" sx={{ position: 'relative' }}>
                                {!isFetched ? (
                                    <WidgetLoading />
                                ) : (
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Grid item xs={12} textAlign="center">
                                                <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                    HRA Appointments ({hraSummary?.TotalAppointments?.length ?? 0})
                                                </Typography>
                                            </Grid>
                                            <div style={{ width: '100%', height: 'Calc(60vh - 65px)', position: 'relative' }}>
                                                <DataGrid
                                                    onCellClick={(params: any) => {
                                                        window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                                    }}
                                                    rows={hraSummary?.TotalAppointments || []}
                                                    columns={totalHraColumns}
                                                    pageSize={pageSize}
                                                    rowsPerPageOptions={[5]}
                                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                    getRowId={(row) => row.Id}
                                                    getRowHeight={() => 'auto'}
                                                    sx={{
                                                        [`& .${gridClasses.cell}`]: {
                                                            py: 1,
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Grid item xs={12} textAlign="center">
                                                <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                    HRAInReview Members ({hraSummary?.ActiveMembers?.length ?? 0})
                                                </Typography>
                                            </Grid>
                                            <div style={{ width: '100%', height: 'Calc(60vh - 65px)', position: 'relative' }}>
                                                <DataGrid
                                                    onCellClick={(params: any) => {
                                                        window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                                    }}
                                                    rows={hraSummary?.ActiveMembers || []}
                                                    columns={hraInReviewColumns}
                                                    pageSize={pageSize}
                                                    rowsPerPageOptions={[5]}
                                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                    getRowId={(row) => row.Id}
                                                    getRowHeight={() => 'auto'}
                                                    sx={{
                                                        [`& .${gridClasses.cell}`]: {
                                                            py: 1,
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Grid item xs={12} textAlign="center">
                                                <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                    Refused To Enroll ({hraSummary?.RefusedMembers?.length ?? 0})
                                                </Typography>
                                            </Grid>
                                            <div style={{ width: '100%', height: 'Calc(60vh - 65px)', position: 'relative' }}>
                                                <DataGrid
                                                    onCellClick={(params: any) => {
                                                        window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                                    }}
                                                    rows={hraSummary?.RefusedMembers || []}
                                                    columns={hraRefusedColumns}
                                                    pageSize={pageSize}
                                                    rowsPerPageOptions={[5]}
                                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                    getRowId={(row) => row.Id}
                                                    getRowHeight={() => 'auto'}
                                                    sx={{
                                                        [`& .${gridClasses.cell}`]: {
                                                            py: 1,
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Paper>
        </LocalizationProvider>
    );
};

export default AppointmentTrackingSummaryDetail;
