import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Paper, TextField, IconButton, Tooltip, Tabs, Tab, FormControlLabel, Checkbox, ListSubheader } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parentUrl } from '../../../utils/constants';
import { useWidgetContext } from '../../../hooks/widgetContext';
import { MenuProps, fetchFullProgramAppointments, fetchHraAppointments, fetchFuhfumAppointments } from '../WidgetService';
import { DataGrid, gridClasses, GridColDef } from '@mui/x-data-grid';
import { AppointmentTimeFilterEnum } from '../../../Enum/AppointmentTimeFilterEnum';
import { useHealthPlanContext } from '../../../hooks/healthPlanContext';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { configureStackedBarChart } from '../../ChartConfiguration/ChartConfiguration';
import AppointmentTrackingSummary from '../../../Models/Widget/AppointmentTrackingSummary.model';
import Authorization from '../../../utils/Authorization';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import WidgetMemberInfo from '../../../Models/Widget/WidgetMemberInfo.model';
import WidgetLoading from '../../SmartDashboard/Shared/WidgetLoading';
import '../Widgets.css';

const dayFilterList = [
    { Id: AppointmentTimeFilterEnum.Next30Days, Value: AppointmentTimeFilterEnum.Next30DaysDescription },
    { Id: AppointmentTimeFilterEnum.Next60Days, Value: AppointmentTimeFilterEnum.Next60DaysDescription },
    { Id: AppointmentTimeFilterEnum.Next90Days, Value: AppointmentTimeFilterEnum.Next90DaysDescription },
    { Id: AppointmentTimeFilterEnum.Custom, Value: AppointmentTimeFilterEnum.CustomDescription },
];

const AppointmentTrackingSummaries = () => {
    const ref = useRef(null);
    const history = useNavigate();
    const authData = new Authorization();
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const [pageSize, setPageSize] = useState(15);
    const { healthPlans } = useHealthPlanContext();
    const { population } = useSelector((state: RootState) => state.adminMembers);
    const { activeProviders, npProviders, tccProviders, isFetchedTCCProvider } = useSelector((state: RootState) => state.provider);
    const { appointmentTrackingTab, setAppointmentTrackingTab } = useWidgetContext();
    const { fullProgramAppointment, setFullProgramAppointment } = useWidgetContext();
    const { hraAppointment, setHraAppointment } = useWidgetContext();
    const { fuhfumAppointment, setFuhfumAppointment } = useWidgetContext();
    const [fullProgramChartOptions, setFullProgramChartOptions] = useState({});
    const [hraChartOptions, setHraChartOptions] = useState({});
    const [fullProgramSummary, setFullProgramSummary] = useState<AppointmentTrackingSummary>();
    const [hraSummary, setHraSummary] = useState<AppointmentTrackingSummary>();
    const [fuhfumSummary, setFuhfumSummary] = useState<WidgetMemberInfo[]>([]);
    const filteredProviders =
        (fullProgramAppointment.includeInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const filteredStaffs =
        (fullProgramAppointment.includeInActiveStaffs ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const filteredHraProviders =
        (hraAppointment.includeInActiveProviders ? npProviders : npProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const filteredHraStaffs =
        (hraAppointment.includeInActiveStaffs ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const [fuhfumProviders, setFuhfumProviders] = useState([]);
    const filteredTccProviders =
        (fuhfumAppointment.includeInActiveProviders ? tccProviders : tccProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const filteredFuhfumNpProviders =
        (fuhfumAppointment.includeInActiveProviders ? npProviders : npProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const filteredFuhfumStaffs =
        (fuhfumAppointment.includeInActiveStaffs ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const filteredHealthPlans = healthPlans
        .filter((item: any) => {
            return population.map((item) => item.HealthPlan?.Name).includes(item.Name);
        })
        .sort((a, b) => a.Name.localeCompare(b.Name));

    const handleClick = (event) => {
        setShow(!show);
    };

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (isFetchedTCCProvider) {
            let providersList = [
                {
                    Name: 'TCC Providers',
                    List: filteredTccProviders,
                },
                {
                    Name: 'NP Providers',
                    List: filteredFuhfumNpProviders,
                },
            ];
            setFuhfumProviders(providersList);
            if (providersList.length) {
                const loggedInProviderId = authData.UserId;
                if (Boolean(loggedInProviderId) && !Boolean(fuhfumAppointment.provider)) {
                    const tccInfo = filteredTccProviders.find((t) => t.Id === loggedInProviderId);
                    const licensdInfo = filteredFuhfumNpProviders.find((t) => t.Id === loggedInProviderId);
                    if (Boolean(tccInfo) || Boolean(licensdInfo)) {
                        setFuhfumAppointment({ ...fuhfumAppointment, provider: loggedInProviderId });
                    } else {
                        setFuhfumAppointment({ ...fuhfumAppointment, provider: 'All' });
                    }
                } else if (!Boolean(fuhfumAppointment.provider)) {
                    setFuhfumAppointment({ ...fuhfumAppointment, provider: 'All' });
                }
            } else {
                setFuhfumAppointment({ ...fuhfumAppointment, provider: 'All' });
            }
        }

        if (!isGraphShow) {
            setFullProgramChartOptions(
                configureStackedBarChart(
                    'Appointment Summary',
                    '',
                    '',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    '',
                    history
                )
            );
        }

        setIsFetched(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetched, isGraphShow, setIsFetched, setFullProgramChartOptions, history]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    const fullProgramDetail = async () => {
        if (
            fullProgramAppointment.timeFilter !== AppointmentTimeFilterEnum.Custom ||
            (Boolean(fullProgramAppointment.fromDate) && Boolean(fullProgramAppointment.toDate))
        ) {
            const changeFilter = async () => {
                const data = await fetchFullProgramAppointments(
                    fullProgramAppointment.provider,
                    fullProgramAppointment.schedulingStaff,
                    fullProgramAppointment.healthPlan,
                    fullProgramAppointment.timeFilter,
                    Boolean(fullProgramAppointment.fromDate) && fullProgramAppointment.timeFilter === AppointmentTimeFilterEnum.Custom
                        ? new Date(fullProgramAppointment.fromDate).toISOString()
                        : '',
                    Boolean(fullProgramAppointment.toDate) && fullProgramAppointment.timeFilter === AppointmentTimeFilterEnum.Custom
                        ? new Date(fullProgramAppointment.toDate).toISOString()
                        : '',
                    fullProgramAppointment.includeInActiveProviders,
                    fullProgramAppointment.includeInActiveStaffs,
                    false
                );
                setFullProgramSummary(data);
                setIsGraphShow(true);
            };
            setIsGraphShow(false);
            setFullProgramChartOptions(
                configureStackedBarChart(
                    'Appointments Summary',
                    '',
                    '',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    '',
                    history
                )
            );
            changeFilter();
        }

        if (
            fullProgramAppointment.timeFilter !== AppointmentTimeFilterEnum.Custom &&
            Boolean(fullProgramAppointment.fromDate) &&
            Boolean(fullProgramAppointment.toDate)
        ) {
            fullProgramAppointment.fromDate = null;
            fullProgramAppointment.toDate = null;
            setFullProgramAppointment(fullProgramAppointment);
        }
    };

    const hraDetail = async () => {
        if (
            hraAppointment.timeFilter !== AppointmentTimeFilterEnum.Custom ||
            (Boolean(hraAppointment.fromDate) && Boolean(hraAppointment.toDate))
        ) {
            const changeFilter = async () => {
                const data = await fetchHraAppointments(
                    hraAppointment.provider,
                    hraAppointment.schedulingStaff,
                    hraAppointment.healthPlan,
                    hraAppointment.timeFilter,
                    Boolean(hraAppointment.fromDate) && hraAppointment.timeFilter === AppointmentTimeFilterEnum.Custom
                        ? new Date(hraAppointment.fromDate).toISOString()
                        : '',
                    Boolean(hraAppointment.toDate) && hraAppointment.timeFilter === AppointmentTimeFilterEnum.Custom
                        ? new Date(hraAppointment.toDate).toISOString()
                        : '',
                    hraAppointment.includeInActiveProviders,
                    hraAppointment.includeInActiveStaffs,
                    false
                );
                setHraSummary(data);
                setIsGraphShow(true);
            };
            setIsGraphShow(false);
            setHraChartOptions(
                configureStackedBarChart(
                    'Appointments Summary',
                    '',
                    '',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    '',
                    history
                )
            );
            changeFilter();
        }

        if (
            hraAppointment.timeFilter !== AppointmentTimeFilterEnum.Custom &&
            Boolean(hraAppointment.fromDate) &&
            Boolean(hraAppointment.toDate)
        ) {
            hraAppointment.fromDate = null;
            hraAppointment.toDate = null;
            setHraAppointment(hraAppointment);
        }
    };

    const fuhfumDetail = async () => {
        if (
            fuhfumAppointment.timeFilter !== AppointmentTimeFilterEnum.Custom ||
            (Boolean(fuhfumAppointment.fromDate) && Boolean(fuhfumAppointment.toDate))
        ) {
            const changeFilter = async () => {
                const data = await fetchFuhfumAppointments(
                    fuhfumAppointment.provider,
                    fuhfumAppointment.schedulingStaff,
                    fuhfumAppointment.timeFilter,
                    Boolean(fuhfumAppointment.fromDate) && fuhfumAppointment.timeFilter === AppointmentTimeFilterEnum.Custom
                        ? new Date(fuhfumAppointment.fromDate).toISOString()
                        : '',
                    Boolean(fuhfumAppointment.toDate) && fuhfumAppointment.timeFilter === AppointmentTimeFilterEnum.Custom
                        ? new Date(fuhfumAppointment.toDate).toISOString()
                        : '',
                    fuhfumAppointment.includeInActiveProviders,
                    fuhfumAppointment.includeInActiveStaffs,
                    true
                );
                setFuhfumSummary(data);
                setIsGraphShow(true);
            };
            setIsGraphShow(false);
            changeFilter();
        }

        if (
            fuhfumAppointment.timeFilter !== AppointmentTimeFilterEnum.Custom &&
            Boolean(fuhfumAppointment.fromDate) &&
            Boolean(fuhfumAppointment.toDate)
        ) {
            fuhfumAppointment.fromDate = null;
            fuhfumAppointment.toDate = null;
            setFuhfumAppointment(fuhfumAppointment);
        }
    };

    useEffect(() => {
        if (appointmentTrackingTab === 1) {
            fullProgramDetail();
        }
        if (appointmentTrackingTab === 2) {
            hraDetail();
        }
        if (appointmentTrackingTab === 3) {
            fuhfumDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentTrackingTab]);

    useEffect(() => {
        if (appointmentTrackingTab === 1) {
            fullProgramDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullProgramAppointment]);

    useEffect(() => {
        if (appointmentTrackingTab === 2) {
            hraDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hraAppointment]);

    useEffect(() => {
        if (appointmentTrackingTab === 3) {
            fuhfumDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fuhfumAppointment]);

    useEffect(() => {
        if (fullProgramSummary) {
            let xAxisValues = [''];
            let yAxisValues = [];
            let totalAppointmentList = [];
            let activeList = [];
            let refusedList = [];

            if (Boolean(fullProgramSummary.TotalAppointmentsCount)) {
                let d1 = {
                    y: fullProgramSummary.TotalAppointmentsCount,
                };
                totalAppointmentList.push(d1);
                yAxisValues.push({ name: 'Onboard Appointments', data: totalAppointmentList, color: '#ff7d7d', showInLegend: true });
            }

            if (Boolean(fullProgramSummary.ActiveMembersCount)) {
                let d2 = {
                    y: fullProgramSummary.ActiveMembersCount,
                };
                activeList.push(d2);
                yAxisValues.push({ name: 'Active', data: activeList, color: '#4682B4', showInLegend: true });
            }

            if (Boolean(fullProgramSummary.RefusedMembersCount)) {
                let d3 = {
                    y: fullProgramSummary.RefusedMembersCount,
                };
                refusedList.push(d3);
                yAxisValues.push({ name: 'Refused', data: refusedList, color: '#A569BD', showInLegend: true });
            }
            if (!Boolean(fullProgramSummary.TotalAppointmentsCount) && !Boolean(fullProgramSummary.ActiveMembersCount) && !Boolean(fullProgramSummary.RefusedMembersCount)) {
                setFullProgramChartOptions(
                    configureStackedBarChart(
                        'Appointments Summary',
                        '',
                        'Appointments',
                        'Count',
                        [{ name: '', data: [0], showInLegend: false, title: { text: null } }],
                        [0],
                        'AppointmentSummary',
                        history
                    )
                );
            } else {
                let subTitle = '',
                    xAxisTitle = '',
                    yAxisTitle = 'Count';
                setFullProgramChartOptions(
                    configureStackedBarChart(
                        'Appointment Chart',
                        subTitle,
                        xAxisTitle,
                        yAxisTitle,
                        yAxisValues,
                        xAxisValues,
                        'AppointmentSummary',
                        history,
                        null,
                        true
                    )
                );
            }

            setIsGraphShow(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullProgramSummary]);

    useEffect(() => {
        if (hraSummary) {
            let xAxisValues = [''];
            let yAxisValues = [];
            let totalAppointmentList = [];
            let activeList = [];
            let refusedList = [];

            if (Boolean(hraSummary.TotalAppointmentsCount)) {
                let d1 = {
                    y: hraSummary.TotalAppointmentsCount,
                };
                totalAppointmentList.push(d1);
                yAxisValues.push({ name: 'HRA Appointments', data: totalAppointmentList, color: '#ff7d7d', showInLegend: true });
            }

            if (Boolean(hraSummary.ActiveMembersCount)) {
                let d2 = {
                    y: hraSummary.ActiveMembersCount,
                };
                activeList.push(d2);
                yAxisValues.push({ name: 'HRAInReview', data: activeList, color: '#4682B4', showInLegend: true });
            }

            if (Boolean(hraSummary.RefusedMembersCount)) {
                let d3 = {
                    y: hraSummary.RefusedMembersCount,
                };
                refusedList.push(d3);
                yAxisValues.push({ name: 'Refused', data: refusedList, color: '#A569BD', showInLegend: true });
            }

            if (!Boolean(hraSummary.TotalAppointmentsCount) && !Boolean(hraSummary.ActiveMembersCount) && !Boolean(hraSummary.RefusedMembersCount)) {
                setHraChartOptions(
                    configureStackedBarChart(
                        'Appointments Summary',
                        '',
                        'Appointments',
                        'Count',
                        [{ name: '', data: [0], showInLegend: false, title: { text: null } }],
                        [0],
                        'AppointmentSummary',
                        history
                    )
                );
            } else {
                let subTitle = '',
                    xAxisTitle = '',
                    yAxisTitle = 'Count';
                setHraChartOptions(
                    configureStackedBarChart(
                        'Appointment Chart',
                        subTitle,
                        xAxisTitle,
                        yAxisTitle,
                        yAxisValues,
                        xAxisValues,
                        'AppointmentSummary',
                        history,
                        null,
                        true
                    )
                );
            }

            setIsGraphShow(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hraSummary]);

    const navigateToMembers = () => {
        history(`/provider/metricsummary/appointmenttrackingsummarydetail`);
    };

    const changeFromDate = (date: any) => {
        if (appointmentTrackingTab === 1) {
            const updatedFullProgramAppointment = { ...fullProgramAppointment, fromDate: date };
            setFullProgramAppointment(updatedFullProgramAppointment);
        } else if (appointmentTrackingTab === 2) {
            const updatedHraAppointment = { ...hraAppointment, fromDate: date };
            setHraAppointment(updatedHraAppointment);
        } else if (appointmentTrackingTab === 3) {
            const updatedFuhfumAppointment = { ...fuhfumAppointment, fromDate: date };
            setFuhfumAppointment(updatedFuhfumAppointment);
        }
    };

    const changeToDate = (date: any) => {
        if (appointmentTrackingTab === 1) {
            const updatedFullProgramAppointment = { ...fullProgramAppointment, toDate: date };
            setFullProgramAppointment(updatedFullProgramAppointment);
        } else if (appointmentTrackingTab === 2) {
            const updatedHraAppointment = { ...hraAppointment, toDate: date };
            setHraAppointment(updatedHraAppointment);
        } else if (appointmentTrackingTab === 3) {
            const updatedFuhfumAppointment = { ...fuhfumAppointment, toDate: date };
            setFuhfumAppointment(updatedFuhfumAppointment);
        }
    };

    const toggleProviderHandler = (isChecked: boolean) => {
        if (appointmentTrackingTab === 1) {
            const updatedFullProgramAppointment = { ...fullProgramAppointment, includeInActiveProviders: isChecked, provider: 'All' };
            setFullProgramAppointment(updatedFullProgramAppointment);
        } else if (appointmentTrackingTab === 2) {
            const updatedHraAppointment = { ...hraAppointment, includeInActiveProviders: isChecked, provider: 'All' };
            setHraAppointment(updatedHraAppointment);
        } else if (appointmentTrackingTab === 3) {
            const updatedFuhfumAppointment = { ...fuhfumAppointment, includeInActiveProviders: isChecked, provider: 'All' };
            setFuhfumAppointment(updatedFuhfumAppointment);
        }
    };

    const toggleStaffHandler = (isChecked: boolean) => {
        if (appointmentTrackingTab === 1) {
            const updatedFullProgramAppointment = { ...fullProgramAppointment, includeInActiveStaffs: isChecked, schedulingStaff: 'All' };
            setFullProgramAppointment(updatedFullProgramAppointment);
        } else if (appointmentTrackingTab === 2) {
            const updatedHraAppointment = { ...hraAppointment, includeInActiveStaffs: isChecked, schedulingStaff: 'All' };
            setHraAppointment(updatedHraAppointment);
        } else if (appointmentTrackingTab === 3) {
            const updatedFuhfumAppointment = { ...fuhfumAppointment, includeInActiveStaffs: isChecked, schedulingStaff: 'All' };
            setFuhfumAppointment(updatedFuhfumAppointment);
        }
    };

    const handleAlertsTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setAppointmentTrackingTab(newValue);
    };

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Member Name',
            minWidth: 200,
            flex: 2,
        },
        {
            field: 'ReferralDate',
            headerName: 'Referral Date',
            minWidth: 100,
            flex: 2,
        },
        {
            field: 'AdmitDate',
            headerName: 'Admit Date',
            minWidth: 100,
            flex: 2,
        },
        {
            field: 'DischargeDate',
            headerName: 'Discharge Date',
            minWidth: 120,
            flex: 2,
        },
        {
            field: 'DateNPSeen',
            headerName: 'Date NP Seen',
            minWidth: 120,
            flex: 2,
        },
        {
            field: 'DateTCCSeen',
            headerName: 'Date TCC Seen',
            minWidth: 120,
            flex: 2,
        },
        {
            field: 'DateLastReached',
            headerName: 'Date Last Reached',
            minWidth: 150,
            flex: 2,
        },
        {
            field: 'NumberOfOutReachCall',
            headerName: 'Number Of Out Reach Call',
            minWidth: 200,
            flex: 2,
        },
        {
            field: 'CurrentMemberStatus',
            headerName: 'Current Status',
            minWidth: 150,
            flex: 2,
        },
        {
            field: 'NextAppointmentDate',
            headerName: 'Next Appointment Date',
            minWidth: 180,
            flex: 2,
        },
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                                <span className="pointer" onClick={navigateToMembers}>
                                    Appointment Tracking
                                </span>
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                Full Program
                                            </Typography>
                                            <div>
                                                <Typography variant="caption">
                                                    <b>Onboard appointments :</b> All upcoming, non-canceled Onboard appointments meeting the filter criteria.
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography variant="caption">
                                                    <b>Active :</b> List of all Onboard appointments within the selected filters that result in the member
                                                    successfully onboarding as an Active member in the Full Program.
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography variant="caption">
                                                    <b>Refused :</b> List of all Onboard appointments within the selected filters that result in the member
                                                    Refusing to Enroll in the Full Program.
                                                </Typography>
                                            </div>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                HRA
                                            </Typography>
                                            <div>
                                                <Typography variant="caption">
                                                    <b>HRA appointments :</b> List of all upcoming, not canceled, HRA appointments meeting filter requirements.
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography variant="caption">
                                                    <b>HRAInReview :</b> List of all HRA appointments within the selected filters that result in the member
                                                    successfully consenting to the HRA.
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography variant="caption">
                                                    <b>Refused :</b> List of all HRA appointments within the selected filters that result in the member Refusing
                                                    to Enroll.
                                                </Typography>
                                            </div>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                FUHFUM
                                            </Typography>
                                            <div>
                                                <Typography variant="caption">
                                                    All FUHFUM members of Undetermined, FuhUnd, FUHFUMInProgress or Scheduled status assigned to the chosen TCC or
                                                    scheduled FUHFUM appointments with the selected provider if not a TCC.
                                                </Typography>
                                            </div>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} display={'flex'} mb={2.5}>
                            <Tabs sx={{ display: 'inline-flex', marginRight: 'auto' }} value={appointmentTrackingTab} onChange={handleAlertsTabChange}>
                                <Tab value={1} label="Full Program" />
                                <Tab value={2} label="HRA" />
                                <Tab value={3} label="FUHFUM" />
                            </Tabs>
                        </Grid>
                        {appointmentTrackingTab === 1 ? (
                            <>
                                <Grid item xs={4}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel>Providers</InputLabel>
                                        <Select
                                            value={fullProgramAppointment.provider}
                                            label="Providers"
                                            name="Providers"
                                            onChange={(e) => setFullProgramAppointment({ ...fullProgramAppointment, provider: e.target.value })}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem key="All" value="All">
                                                All
                                            </MenuItem>
                                            <FormControlLabel
                                                sx={{ pl: '12px', mb: '0px' }}
                                                control={
                                                    <Checkbox
                                                        checked={fullProgramAppointment.includeInActiveProviders}
                                                        onChange={(e) => toggleProviderHandler(e.target.checked)}
                                                    />
                                                }
                                                label="Show Inactive"
                                            />
                                            {filteredProviders.map((pro) => (
                                                <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                                    {pro.LastName}, {pro.FirstName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel>Scheduling Staff</InputLabel>
                                        <Select
                                            value={fullProgramAppointment.schedulingStaff}
                                            label="Scheduling Staff"
                                            name="Scheduling Staff"
                                            onChange={(e) =>
                                                setFullProgramAppointment({ ...fullProgramAppointment, schedulingStaff: e.target.value })
                                            }
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem key="All" value="All">
                                                All
                                            </MenuItem>
                                            <FormControlLabel
                                                sx={{ pl: '12px', mb: '0px' }}
                                                control={
                                                    <Checkbox
                                                        checked={fullProgramAppointment.includeInActiveStaffs}
                                                        onChange={(e) => toggleStaffHandler(e.target.checked)}
                                                    />
                                                }
                                                label="Show Inactive"
                                            />
                                            {filteredStaffs.map((pro) => (
                                                <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                                    {pro.LastName}, {pro.FirstName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel>Health Plan</InputLabel>
                                        <Select
                                            value={fullProgramAppointment.healthPlan}
                                            label="Health Plan"
                                            name="plan"
                                            onChange={(e) => {
                                                setFullProgramAppointment({ ...fullProgramAppointment, healthPlan: e.target.value });
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem key="All" value="All">
                                                All
                                            </MenuItem>
                                            {filteredHealthPlans.map((plan) => (
                                                <MenuItem key={plan.Id} value={plan.Id}>
                                                    {plan.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel>Date Filter</InputLabel>
                                        <Select
                                            value={fullProgramAppointment.timeFilter}
                                            label="Date Filter"
                                            name="Date"
                                            onChange={(e) =>
                                                setFullProgramAppointment({
                                                    ...fullProgramAppointment,
                                                    timeFilter: parseInt(e.target.value as any),
                                                })
                                            }
                                        >
                                            {dayFilterList.map((date) => (
                                                <MenuItem key={date.Id} value={date.Id}>
                                                    {date.Value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {fullProgramAppointment.timeFilter === AppointmentTimeFilterEnum.Custom ? (
                                    <React.Fragment>
                                        <Grid item md={4}>
                                            <DesktopDatePicker
                                                label="From Date"
                                                value={Boolean(fullProgramAppointment.fromDate) ? fullProgramAppointment.fromDate : null}
                                                minDate={new Date('2017-01-01')}
                                                onChange={(newValue) => changeFromDate(newValue)}
                                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <DesktopDatePicker
                                                label="To Date"
                                                value={Boolean(fullProgramAppointment.toDate) ? fullProgramAppointment.toDate : null}
                                                minDate={new Date('2017-01-01')}
                                                onChange={(newValue) => changeToDate(newValue)}
                                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ) : null}
                                <Grid item xs={12} minHeight="45vh" sx={{ position: 'relative' }}>
                                    {!isFetched ? (
                                        <Box className="loader-center">
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <div style={{ width: '100%', height: 'Calc(50vh - 65px)', position: 'relative' }}>
                                            <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                                                {!isGraphShow ? (
                                                    <Box className="loader-center">
                                                        <CircularProgress />
                                                    </Box>
                                                ) : null}
                                                <HighchartsReact highcharts={Highcharts} options={fullProgramChartOptions} />
                                            </Grid>
                                        </div>
                                    )}
                                </Grid>
                            </>
                        ) : appointmentTrackingTab === 2 ? (
                            <>
                                <Grid item xs={4}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel>Providers</InputLabel>
                                        <Select
                                            value={hraAppointment.provider}
                                            label="Providers"
                                            name="Providers"
                                            onChange={(e) => setHraAppointment({ ...hraAppointment, provider: e.target.value })}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem key="All" value="All">
                                                All
                                            </MenuItem>
                                            <FormControlLabel
                                                sx={{ pl: '12px', mb: '0px' }}
                                                control={
                                                    <Checkbox
                                                        checked={hraAppointment.includeInActiveProviders}
                                                        onChange={(e) => toggleProviderHandler(e.target.checked)}
                                                    />
                                                }
                                                label="Show Inactive"
                                            />
                                            {filteredHraProviders.map((pro) => (
                                                <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                                    {pro.LastName}, {pro.FirstName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel>Scheduling Staff</InputLabel>
                                        <Select
                                            value={hraAppointment.schedulingStaff}
                                            label="Scheduling Staff"
                                            name="Scheduling Staff"
                                            onChange={(e) => setHraAppointment({ ...hraAppointment, schedulingStaff: e.target.value })}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem key="All" value="All">
                                                All
                                            </MenuItem>
                                            <FormControlLabel
                                                sx={{ pl: '12px', mb: '0px' }}
                                                control={
                                                    <Checkbox
                                                        checked={hraAppointment.includeInActiveStaffs}
                                                        onChange={(e) => toggleStaffHandler(e.target.checked)}
                                                    />
                                                }
                                                label="Show Inactive"
                                            />
                                            {filteredHraStaffs.map((pro) => (
                                                <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                                    {pro.LastName}, {pro.FirstName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel>Health Plan</InputLabel>
                                        <Select
                                            value={hraAppointment.healthPlan}
                                            label="Health Plan"
                                            name="plan"
                                            onChange={(e) => {
                                                setHraAppointment({ ...hraAppointment, healthPlan: e.target.value });
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem key="All" value="All">
                                                All
                                            </MenuItem>
                                            {filteredHealthPlans.map((plan) => (
                                                <MenuItem key={plan.Id} value={plan.Id}>
                                                    {plan.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel>Date Filter</InputLabel>
                                        <Select
                                            value={hraAppointment.timeFilter}
                                            label="Date Filter"
                                            name="Date"
                                            onChange={(e) =>
                                                setHraAppointment({
                                                    ...hraAppointment,
                                                    timeFilter: parseInt(e.target.value as any),
                                                })
                                            }
                                        >
                                            {dayFilterList.map((date) => (
                                                <MenuItem key={date.Id} value={date.Id}>
                                                    {date.Value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {hraAppointment.timeFilter === AppointmentTimeFilterEnum.Custom ? (
                                    <React.Fragment>
                                        <Grid item md={4}>
                                            <DesktopDatePicker
                                                label="From Date"
                                                value={Boolean(hraAppointment.fromDate) ? hraAppointment.fromDate : null}
                                                minDate={new Date('2017-01-01')}
                                                onChange={(newValue) => changeFromDate(newValue)}
                                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <DesktopDatePicker
                                                label="To Date"
                                                value={Boolean(hraAppointment.toDate) ? hraAppointment.toDate : null}
                                                minDate={new Date('2017-01-01')}
                                                onChange={(newValue) => changeToDate(newValue)}
                                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ) : null}
                                <Grid item xs={12} minHeight="45vh" sx={{ position: 'relative' }}>
                                    {!isFetched ? (
                                        <Box className="loader-center">
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <div style={{ width: '100%', height: 'Calc(50vh - 65px)', position: 'relative' }}>
                                            <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                                                {!isGraphShow ? (
                                                    <Box className="loader-center">
                                                        <CircularProgress />
                                                    </Box>
                                                ) : null}
                                                <HighchartsReact highcharts={Highcharts} options={hraChartOptions} />
                                            </Grid>
                                        </div>
                                    )}
                                </Grid>
                            </>
                        ) : appointmentTrackingTab === 3 ? (
                            <>
                                <Grid item xs={4}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel id="pro-select-label">Provider</InputLabel>
                                        <Select
                                            label="Provider"
                                            value={fuhfumAppointment.provider}
                                            onChange={(e) => setFuhfumAppointment({ ...fuhfumAppointment, provider: e.target.value })}
                                        >
                                            <MenuItem key="All" value="All">
                                                All
                                            </MenuItem>
                                            <FormControlLabel
                                                sx={{ pl: '12px', mb: '0px' }}
                                                control={
                                                    <Checkbox
                                                        checked={fuhfumAppointment.includeInActiveProviders}
                                                        onChange={(e) => toggleProviderHandler(e.target.checked)}
                                                    />
                                                }
                                                label="Show Inactive"
                                            />
                                            {fuhfumProviders.map((provider, index) => [
                                                <ListSubheader key={index}>{provider.Name}</ListSubheader>,
                                                provider.List.map((pro) => {
                                                    return (
                                                        <MenuItem
                                                            key={pro.Id}
                                                            value={pro.Id}
                                                            sx={{ paddingLeft: 4, color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}
                                                        >
                                                            {pro.LastName}, {pro.FirstName}
                                                        </MenuItem>
                                                    );
                                                }),
                                            ])}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel>Scheduling Staff</InputLabel>
                                        <Select
                                            value={fuhfumAppointment.schedulingStaff}
                                            label="Scheduling Staff"
                                            name="Scheduling Staff"
                                            onChange={(e) => setFuhfumAppointment({ ...fuhfumAppointment, schedulingStaff: e.target.value })}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem key="All" value="All">
                                                All
                                            </MenuItem>
                                            <FormControlLabel
                                                sx={{ pl: '12px', mb: '0px' }}
                                                control={
                                                    <Checkbox
                                                        checked={fuhfumAppointment.includeInActiveStaffs}
                                                        onChange={(e) => toggleStaffHandler(e.target.checked)}
                                                    />
                                                }
                                                label="Show Inactive"
                                            />
                                            {filteredFuhfumStaffs.map((pro) => (
                                                <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                                    {pro.LastName}, {pro.FirstName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel>Date Filter</InputLabel>
                                        <Select
                                            value={fuhfumAppointment.timeFilter}
                                            label="Date Filter"
                                            name="Date"
                                            onChange={(e) =>
                                                setFuhfumAppointment({
                                                    ...fuhfumAppointment,
                                                    timeFilter: parseInt(e.target.value as any),
                                                })
                                            }
                                        >
                                            {dayFilterList.map((date) => (
                                                <MenuItem key={date.Id} value={date.Id}>
                                                    {date.Value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {fuhfumAppointment.timeFilter === AppointmentTimeFilterEnum.Custom ? (
                                    <React.Fragment>
                                        <Grid item md={4}>
                                            <DesktopDatePicker
                                                label="From Date"
                                                value={Boolean(fuhfumAppointment.fromDate) ? fuhfumAppointment.fromDate : null}
                                                minDate={new Date('2017-01-01')}
                                                onChange={(newValue) => changeFromDate(newValue)}
                                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <DesktopDatePicker
                                                label="To Date"
                                                value={Boolean(fuhfumAppointment.toDate) ? fuhfumAppointment.toDate : null}
                                                minDate={new Date('2017-01-01')}
                                                onChange={(newValue) => changeToDate(newValue)}
                                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ) : null}
                                <Grid item xs={12} minHeight="45vh" sx={{ position: 'relative' }}>
                                    <div style={{ width: '100%', height: 'Calc(50vh - 65px)', position: 'relative' }}>
                                        {!isGraphShow ? (
                                            <WidgetLoading />
                                        ) : (
                                            <DataGrid
                                                onCellClick={(params: any) => {
                                                    window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                                }}
                                                rows={fuhfumSummary}
                                                columns={columns}
                                                pageSize={pageSize}
                                                rowsPerPageOptions={[5]}
                                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                getRowId={(row) => row.Id}
                                                getRowHeight={() => 'auto'}
                                                sx={{
                                                    [`& .${gridClasses.cell}`]: {
                                                        py: 1,
                                                    },
                                                    '& .MuiDataGrid-root': {
                                                        overflowX: 'auto',
                                                    },
                                                }}
                                            />
                                        )}
                                    </div>
                                </Grid>
                            </>
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default AppointmentTrackingSummaries;
