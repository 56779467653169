import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { fetchMemberStatuses } from '../../store/members.slice';
import moment from 'moment';
import Loading from '../Shared/Loading';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import MembersDialog from './MembersDialog';
import { fetchInsuranceTypes, fetchHealthPlans } from '../../store/memberDetail.slice';
import {
    setFilterBy,
    getMembers,
    fetchPopulationList,
    handleEditClose,
    deleteMember,
    deleteHandler,
    fetchMemberDemographicInfo,
    setForm,
    closeOpenDelete,
    updateMember,
    handleOpen,
    handleClose,
    insertMember,
    setIncludeArchivedMembers,
} from '../../store/adminMembers.slice';
import { Search } from '@mui/icons-material';
import { isDefaultDate } from '../../utils/common';
import { isValidName } from '../../utils/common';

const AdminMembers = (props) => {
    const dispatch = useDispatch();

    const { rowData, editing, filterBy, open, deleteHelper, openEdit, form, openDelete, formIsValid, includeArchivedMembers } = useSelector(
        (state: RootState) => state.adminMembers
    );

    const { memberStatuses } = useSelector((state: RootState) => state.members);
    const [pageSize, setPageSize] = useState(15);
    const [localMembers, setLocalMembers] = useState([]);

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setForm({ ...form, [e.target.name]: e.target.value }));
    };

    const validation = () => {
        if (
            !Boolean(form.FirstName) ||
            (Boolean(form.FirstName) && !isValidName(form.FirstName)) ||
            !Boolean(form.LastName) ||
            (Boolean(form.LastName) && !isValidName(form.LastName)) ||
            (Boolean(form.MiddleName) && !isValidName(form.MiddleName)) ||
            form.DOB === null ||
            !Boolean(form.Address1) ||
            !Boolean(form.City) ||
            !Boolean(form.State) ||
            !Boolean(form.ZipCode) ||
            (Boolean(form.ZipCode) && form.ZipCode.length !== 5 && form.ZipCode.length !== 10) ||
            !Boolean(form.ContractingHealthPlan) ||
            !Boolean(form.ContractingHealthPlan.Name) ||
            form.Population === -1 ||
            form.InsuranceType === -1 ||
            !Boolean(form.Mem_AHCCCS_ID) ||
            !Boolean(form.MemberId)
        ) {
            return false;
        } else {
            return true;
        }
    };

    const passwordRef = useRef(null);

    const columns: GridColDef[] = [
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 160,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            width: 140,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : moment(params.row.DOB).format('MM/DD/YYYY')}`,
        },
        {
            field: 'Address1',
            headerName: 'Address',
            minWidth: 200,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.Address1}`,
        },
        {
            field: 'City',
            headerName: 'City',
            width: 180,
            valueGetter: (params: GridValueGetterParams) => `${params.row.City}`,
        },
        {
            field: 'State',
            headerName: 'State',
            width: 80,
            valueGetter: (params: GridValueGetterParams) => `${params.row.State}`,
        },
        {
            field: 'ZipCode',
            headerName: 'Zip',
            width: 80,
            valueGetter: (params: GridValueGetterParams) => `${params.row.ZipCode}`,
        },
        {
            field: 'HealthPlan',
            headerName: 'Contracting Health Plan',
            minWidth: 200,
        },
        {
            field: 'Status',
            headerName: 'Status',
            width: 160,
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            minWidth: 160,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Edit" placement="left">
                            <EditIcon
                                color="primary"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => dispatch(fetchMemberDemographicInfo(params.id))}
                            />
                        </Tooltip>
                        <Tooltip title="Delete" placement="right">
                            <CancelIcon color="error" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => dispatch(deleteHandler(params))} />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const [memberSearchValue, setMemberSearchValue] = useState('');
    function executeSearch() {
        var tmp = [];

        const searchValue = memberSearchValue.toLowerCase();
        for (const member of rowData) {
            var DOB = '';
            if (member.DOB) {
                const tmpDOB = new Date(member.DOB);
                DOB = tmpDOB.toLocaleDateString('en-US');
                DOB = DOB.replace(/\D/g, '');
            }

            let searchValueOnlyDigits = searchValue.replace(/\D/g, '');

            if (
                (member.LastName && member.LastName.toLowerCase().indexOf(searchValue) > -1) ||
                (member.FirstName && member.FirstName.toLowerCase().indexOf(searchValue) > -1) ||
                (member.RiskScore && member.RiskScore.toString().toLowerCase().indexOf(searchValue) > -1) ||
                (member.DOB && DOB === searchValue.replace(/\D/g, '')) ||
                (member.HealthPlan && member.HealthPlan.toLowerCase().indexOf(searchValue) > -1) ||
                (member.LastSeen && member.LastSeen.toString().toLowerCase().indexOf(searchValue) > -1) ||
                (member.Address1 && member.Address1.toLowerCase().indexOf(searchValue) > -1) ||
                (member.City && member.City.toLowerCase().indexOf(searchValue) > -1) ||
                (member.State && member.State.toLowerCase().indexOf(searchValue) > -1) ||
                (member.ZipCode && member.ZipCode.toString().toLowerCase().indexOf(searchValue) > -1) ||
                (member.Status && member.Status.toLowerCase().indexOf(searchValue) > -1) ||
                (member.MobilePhone && searchValueOnlyDigits && member.MobilePhone.replace(/\D/g, '').indexOf(searchValueOnlyDigits) > -1) ||
                (member.AhcccsId && member.AhcccsId.toLowerCase().indexOf(searchValue) > -1) ||
                (member.TabletId && member.TabletId.indexOf(searchValue) > -1)
            ) {
                tmp.push(member);
            }
        }

        setLocalMembers(tmp);
    }

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 54 : 62) + ''));
    };

    const changeSearch = (event: any) => {
        if (!event.key || event.key === 'Enter') {
            executeSearch();
        }
    };

    useEffect(() => {
        executeSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowData]);

    useEffect(() => {
        dispatch(fetchMemberStatuses());
        dispatch(fetchHealthPlans());
        dispatch(fetchPopulationList());
        dispatch(fetchInsuranceTypes());
        dispatch(getMembers(filterBy));
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            {<Loading message={'Loading...'} />}
            <MembersDialog
                form={form}
                formIsValid={formIsValid}
                onChangeHandler={onChangeHandler}
                open={open}
                onClose={() => dispatch(handleClose())}
                submitHandler={() => dispatch(insertMember(validation(), form, filterBy))}
                pageSize={pageSize}
            />
            <MembersDialog
                editing={editing}
                form={form}
                formIsValid={formIsValid}
                onChangeHandler={onChangeHandler}
                open={openEdit}
                onClose={() => dispatch(handleEditClose())}
                submitHandler={() => dispatch(updateMember(validation(), form, filterBy))}
                pageSize={pageSize}
            />
            <Dialog
                open={openDelete}
                onClose={() => dispatch(closeOpenDelete())}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableEnforceFocus
                fullWidth
            >
                <Paper
                    sx={{
                        padding: '16px',
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        <Typography variant="h6" component={'p'} color={'error'}>
                            Are You Sure You Want To Delete{' '}
                            <span style={{ color: '#333', fontWeight: 'bold' }}>
                                {form.FirstName || ''} {form.LastName || ''}
                            </span>
                            ?
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth>
                            <TextField
                                style={{ marginTop: 5 }}
                                label="Password"
                                autoComplete="off"
                                color="error"
                                type="password"
                                required
                                error={deleteHelper.length > 0}
                                variant="outlined"
                                inputRef={passwordRef}
                                helperText={deleteHelper}
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={{ minWidth: '120px' }}
                            variant="contained"
                            color="error"
                            onClick={() => dispatch(deleteMember(form, passwordRef, filterBy))}
                            autoFocus
                        >
                            Confirm Delete
                        </Button>
                        <Button sx={{ minWidth: '120px' }} variant="outlined" onClick={() => dispatch(closeOpenDelete())}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>

            <Grid container justifyContent={'flex-end'} spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                <Grid item xs={6} display="flex" alignItems="center">
                    <FormControl style={{ minWidth: '300px', margin: '0px' }}>
                        <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type="text"
                            value={memberSearchValue}
                            onChange={(e) => {
                                setMemberSearchValue(e.target.value as string);
                            }}
                            onKeyUp={(event: any) => {
                                changeSearch(event);
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="Search" onClick={changeSearch} edge="end">
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Search"
                        />
                    </FormControl>
                    <FormControlLabel
                        style={{ paddingLeft: '20px', paddingTop: '10px' }}
                        label={
                            <Box component="div" fontSize={14}>
                                Include Archived Members
                            </Box>
                        }
                        control={
                            <Checkbox
                                checked={includeArchivedMembers}
                                onChange={() => {
                                    dispatch(setIncludeArchivedMembers(!includeArchivedMembers));
                                    dispatch(getMembers(filterBy));
                                }}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="row" className="float-right" spacing={2} display="flex" alignItems="center">
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Member Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Member Status"
                                value={filterBy}
                                multiple
                                onChange={(e) => {
                                    dispatch(setFilterBy(e.target.value as []));
                                }}
                                onClose={() => {
                                    dispatch(getMembers(filterBy));
                                }}
                            >
                                <MenuItem value={'-1'}>Show All</MenuItem>
                                {memberStatuses.map((status) => {
                                    return (
                                        <MenuItem key={status.value} value={status.value}>
                                            {status.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <Button variant="contained" onClick={() => dispatch(handleOpen())}>
                            Add New Member
                        </Button>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <div style={{ height: '80vh', width: '100%' }}>
                        <DataGrid
                            rows={localMembers}
                            columns={columns}
                            pageSize={pageSize}
                            rowsPerPageOptions={[]}
                            getRowId={(row) => row.Id}
                            getRowHeight={() => 'auto'}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AdminMembers;
