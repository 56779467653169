import Highcharts from 'highcharts';

const navigateToDetailPage = (type: string, history: any) => {
    if (Boolean(type) && Boolean(history)) {
        switch (type) {
            case 'Engagement':
                history(`/provider/metricsummary/engagementmemberdetail`);
                break;
            case 'Scheduling':
                history(`/provider/metricsummary/schedulingsummarydetail`);
                break;
            case 'Request':
                history(`requestsummarydetail`);
                break;
            case 'Appointment':
                history(`/provider/metricsummary/appointmentmemberdetail`);
                break;
            case 'SocialWorkerRequest':
                history(`/provider/metricsummary/socialworkerrequestsummarydetail`);
                break;
            case 'TotalCost':
                history(`/provider/metricsummary/totalcostdetail`);
                break;
            case 'AcCost':
                history(`/provider/metricsummary/accostdetail`);
                break;
            case 'PhCost':
                history(`/provider/metricsummary/phcostdetail`);
                break;
            case 'FfCost':
                history(`/provider/metricsummary/ffcostdetail`);
                break;
            case 'BhRtcCost':
                history(`/provider/metricsummary/bhrtccostdetail`);
                break;
            case 'EdVist':
                history(`/provider/metricsummary/edvisitdetail`);
                break;
            case 'NpTc':
                history(`/provider/metricsummary/npteamtcmemberdetail`);
                break;
            case 'BhTc':
                history(`/provider/metricsummary/bhteamtcmemberdetail`);
                break;
            case 'EngagementSummary':
                history(`/provider/metricsummary/engagementsummarydetail`);
                break;
            case 'TeamLevelTcSummary':
                history(`/provider/metricsummary/teamleveltcsummarydetail`);
                break;
            case 'IndividualEngagement':
                history(`/provider/metricsummary/individualinpersonvisitsdetail`);
                break;
            case 'AppointmentSummary':
                history(`/provider/metricsummary/appointmenttrackingsummarydetail`);
                break;
        }
    }
};

export const configureStackedBarChart = function (
    chartTitle,
    chartSubTitle,
    xAxisTitle,
    yAxisTitle,
    yAxisValues,
    xAxisValues,
    type?,
    history?,
    average?,
    staticDataLabel?
) {
    xAxisValues = xAxisValues ? xAxisValues : [1];
    let barChart = {
        chart: {
            type: 'column',
        },
        title: {
            text: '',
        },
        subtitle: {
            text: chartSubTitle,
        },
        yAxis: {
            min: 0,
            title: {
                text: yAxisTitle,
            },
            plotLines: [
                Boolean(average)
                    ? {
                          color: 'red',
                          value: Boolean(average) ? average : '',
                          width: '1',
                          zIndex: 99,
                          label: {
                              text: 'Average : ' + average,
                              align: 'right',
                              x: -30,
                              y: -10 /*moves label down*/,
                              style: {
                                  color: 'red',
                                  fontWeight: 'normal',
                                  fontSize: '15px',
                              },
                          },
                      }
                    : {},
            ],
        },
        tooltip: {
            formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
                let customName = this.point.name ? `<b>#</b>${this.point.y} (${this.point.name}%)` : `<b>#</b>${this.point.y}`;
                return customName;
            },
        },
        colors: ['#4682B4', '#6495ED', '#9ec7e8', '#b3b0de', '#7864ed', '#3676e8', '#647188', '#5373af'],
        series: yAxisValues,
        xAxis: {
            categories: xAxisValues,
            title: {
                text: xAxisTitle,
            },
        },
        credits: {
            enabled: false,
        },
        legend: {
            align: 'right',
            x: -10,
            verticalAlign: 'top',
            y: -10,
            floating: true,
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false,
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true,
                    formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
                        let customName = '';
                        if (staticDataLabel) {
                            customName = this.point.name ? `<b>#</b>${this.point.y} (${this.point.name}%)` : `${this.point.y}`;
                        } else {
                            customName = this.point.name !== undefined ? `${this.point.name}%` : ``;
                        }
                        return customName;
                    },
                },
            },
            series: {
                cursor: 'pointer',
                point: {
                    events: {
                        click: function () {
                            navigateToDetailPage(type, history);
                        },
                    },
                },
            },
        },
    };
    return barChart;
};

export const configureStackedSplitBarChart = function (
    chartTitle,
    chartSubTitle,
    xAxisTitle,
    yAxisTitle,
    yAxisValues,
    xAxisValues,
    type,
    history,
    isCountDisplay
) {
    let barChart = {
        chart: {
            type: 'column',
        },
        title: {
            text: '',
        },
        subtitle: {
            text: chartSubTitle,
        },
        xAxis: {
            categories: xAxisValues,
            title: {
                text: xAxisTitle,
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: yAxisTitle,
            },
            stackLabels: {
                enabled: false,
            },
        },
        legend: {
            align: 'right',
            x: -10,
            verticalAlign: 'top',
            y: -10,
            floating: true,
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false,
        },
        tooltip: {
            formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
                let customName = this.point.name ? `<b>#</b>${this.point.y} (${this.point.name}%)` : `<b>#</b>${this.point.y}`;
                return customName;
            },
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: isCountDisplay,
                },
            },
            series: {
                maxPointWidth: 200,
                cursor: 'pointer',
                point: {
                    events: {
                        click: function () {
                            navigateToDetailPage(type, history);
                        },
                    },
                },
            },
        },
        series: yAxisValues,
        credits: {
            enabled: false,
        },
        colors: ['#4682B4', '#6495ED'],
    };
    return barChart;
};

export const configureColumnComparisonChart = function (chartTitle, chartSubTitle, xAxisTitle, yAxisTitle, yAxisValues, xAxisValues) {
    let barChart = {
        chart: {
            type: 'column',
        },
        title: {
            text: '',
        },
        subtitle: {
            text: chartSubTitle,
        },
        xAxis: {
            categories: xAxisValues,
            title: {
                text: xAxisTitle,
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: yAxisTitle,
            },
            stackLabels: {
                enabled: false,
            },
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true,
                    formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
                        let customName = this.point.name ? `${this.point.name}%` : ``;
                        return customName;
                    },
                },
            },
        },
        tooltip: {
            formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
                let customName = this.point.name ? `<b>#</b>${this.point.y} (${this.point.name}%)` : `<b>#</b>${this.point.y}`;
                return customName;
            },
        },
        credits: {
            enabled: false,
        },
        colors: ['#4682B4', '#6495ED'],
        series: yAxisValues,
        legend: {
            align: 'right',
            x: -10,
            verticalAlign: 'top',
            y: -10,
            floating: true,
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false,
        },
    };
    return barChart;
};

export const configureLineChart = function (chartTitle, chartSubTitle, xAxisTitle, yAxisTitle, yAxisValues, xAxisValues) {
    let lineChart = {
        title: {
            text: '',
        },
        subtitle: {
            text: chartSubTitle,
        },
        yAxis: {
            min: 0,
            title: {
                text: yAxisTitle,
            },
        },
        xAxis: {
            categories: xAxisValues,
            title: {
                text: xAxisTitle,
            },
        },
        legend: {
            align: 'right',
            x: -10,
            verticalAlign: 'top',
            y: -10,
            floating: true,
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false,
        },
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false,
                },
            },
        },
        responsive: {
            rules: [
                {
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom',
                        },
                    },
                },
            ],
        },
        credits: {
            enabled: false,
        },
        series: yAxisValues,
    };
    return lineChart;
};

export const configureStackedBarChartWithoutLegend = function (chartTitle, chartSubTitle, xAxisTitle, yAxisTitle, yAxisValues, xAxisValues, onClick = null) {
    xAxisValues = xAxisValues ? xAxisValues : [1];
    let barChart = {
        chart: {
            type: 'column',
        },
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            marginTop: -20,
        },
        title: {
            text: '',
        },
        subtitle: {
            text: chartSubTitle,
        },
        yAxis: {
            min: 0,
            title: {
                text: yAxisTitle,
            },
        },
        tooltip: {
            formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
                let customName = this.point.name ? `${this.point.name} : <b>#${this.point.y}</b>` : `<b>#</b>${this.point.y}`;
                return customName;
            },
        },
        colors: ['#B0C4DE', '#6495ED', '#4682B4', '#9ec7e8', '#b3b0de', '#498aa0', '#b0dbde', '#7864ed', '#5373af'],
        series: yAxisValues,
        xAxis: {
            categories: xAxisValues,
            title: {
                text: xAxisTitle,
            },
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            series: {
                cursor: 'pointer',
                point: {
                    events: {
                        click: onClick,
                    },
                },
            },
        },
    };
    return barChart;
};

export const configureStackedBarChartForCost = function (chartTitle, chartSubTitle, xAxisTitle, yAxisTitle, yAxisValues, xAxisValues, type, history) {
    xAxisValues = xAxisValues ? xAxisValues : [1];
    let barChart = {
        chart: {
            type: 'column',
        },
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            marginTop: -20,
        },
        title: {
            text: '',
        },
        subtitle: {
            text: chartSubTitle,
        },
        yAxis: {
            min: 0,
            title: {
                text: yAxisTitle,
            },
        },
        tooltip: {
            formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
                let customName = this.point.name ? `${this.point.name} : <b>$${this.point.y}</b>` : `<b>$</b>${this.point.y}`;
                return customName;
            },
        },
        plotOptions: {
            series: {
                cursor: 'pointer',
                point: {
                    events: {
                        click: function () {
                            navigateToDetailPage(type, history);
                        },
                    },
                },
            },
        },
        colors: ['#B0C4DE', '#6495ED', '#4682B4', '#9ec7e8', '#b3b0de', '#498aa0', '#b0dbde', '#7864ed', '#5373af'],
        series: yAxisValues,
        xAxis: {
            categories: xAxisValues,
            title: {
                text: xAxisTitle,
            },
        },
        credits: {
            enabled: false,
        },
    };
    return barChart;
};

export const configureStackedBarChartForEdVisit = function (chartTitle, chartSubTitle, xAxisTitle, yAxisTitle, yAxisValues, xAxisValues, type, history) {
    xAxisValues = xAxisValues ? xAxisValues : [1];
    let barChart = {
        chart: {
            type: 'column',
        },
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            marginTop: -20,
        },
        title: {
            text: '',
        },
        subtitle: {
            text: chartSubTitle,
        },
        yAxis: {
            min: 0,
            title: {
                text: yAxisTitle,
            },
        },
        tooltip: {
            formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
                let customName = `${this.point.name} : <b>#${this.point.percentage}</b>`;
                return customName;
            },
        },
        plotOptions: {
            series: {
                cursor: 'pointer',
                point: {
                    events: {
                        click: function () {
                            navigateToDetailPage(type, history);
                        },
                    },
                },
            },
        },
        colors: ['#B0C4DE', '#6495ED', '#4682B4', '#9ec7e8', '#b3b0de', '#498aa0', '#b0dbde', '#7864ed', '#5373af'],
        series: yAxisValues,
        xAxis: {
            categories: xAxisValues,
            title: {
                text: xAxisTitle,
            },
        },
        credits: {
            enabled: false,
        },
    };
    return barChart;
};

export const configureBhrtcChart = function (chartTitle, chartSubTitle, xAxisTitle, yAxisTitle, yAxisValues, xAxisValues, type, history) {
    let barChart = {
        chart: {
            type: 'column',
        },
        title: {
            text: '',
        },
        subtitle: {
            text: chartSubTitle,
        },
        xAxis: {
            categories: xAxisValues,
            title: {
                text: xAxisTitle,
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: yAxisTitle,
            },
            stackLabels: {
                enabled: false,
            },
        },
        legend: {
            align: 'right',
            x: -10,
            verticalAlign: 'top',
            y: -10,
            floating: true,
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false,
        },
        tooltip: {
            formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
                let customName = `<b>${this.point.getLabelConfig().series.name}</b><br/>${this.point.name}: <b>$${this.point.y}</b>`;
                return customName;
            },
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false,
                },
            },
            series: {
                cursor: 'pointer',
                point: {
                    events: {
                        click: function () {
                            navigateToDetailPage(type, history);
                        },
                    },
                },
            },
        },
        series: yAxisValues,
        credits: {
            enabled: false,
        },
        colors: ['#B0DBDE', '#917DFF'],
    };
    return barChart;
};
