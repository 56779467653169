import {
    Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Popper,
    Select,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import React, { useState } from 'react';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../../store/adminMembers.slice';
import { RootState } from '../../reducers';
import { states } from '../../utils/constants';
import { isValidZip } from '../../utils/validation';
import MemberPhone from '../Members/MemberDetail/MemberPhone';
import { isValidName } from '../../utils/common';
import { fetchPlaceDetail, fetchPlacePredictions } from '../../store/googlePlace.slice';
import Address from '../../Models/Shared/Address.model';

const MembersDialog = (props) => {
    const dispatch = useDispatch();
    const { isFetching } = useSelector((state: RootState) => state.googlePlace);
    const { population, form } = useSelector((state: RootState) => state.adminMembers);
    const { insuranceTypes, healthPlans } = useSelector((state: RootState) => state.memberDetail);
    const { memberStatuses } = useSelector((state: RootState) => state.members);
    const healthPlans2 = healthPlans.filter((item) => {
        return population.map((item) => item.HealthPlan.Name).includes(item.Name);
    });
    const [placeList, setPlaceList] = useState([]);

    const getAddressByKeyword = (input: string) => {
        dispatch(setForm({ ...form, Address1: input }));
        if (input?.length > 4) {
            dispatch(
                fetchPlacePredictions(input, (data: any) => {
                    setPlaceList(data);
                })
            );
        }
    };

    const getAddressDetail = (e: any, option: any) => {
        if (Boolean(option)) {
            dispatch(
                fetchPlaceDetail(option.PlacePrediction.PlaceId, (data: Address) => {
                    dispatch(setForm({ ...form, ...data }));
                })
            );
        }
    };

    const MEMBERS = true;
    const getRanHex = () => {
        let result = [];
        let hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

        for (let n = 0; n < 24; n++) {
            result.push(hexRef[Math.floor(Math.random() * 16)]);
        }
        return result.join('');
    };

    const getDefaultPhone = () => {
        return { Id: null, Number: null, Note: null, Textable: false, IsPrimary: false };
    };

    const phoneStyles = {
        padding: '8px',
        textAlign: 'right',
        boxShadow: 'none',
    };

    const reset = (row: any) => {
        let update = row ? { ...row } : getDefaultPhone();
        setFormPhone(update);
    };

    const addPhoneHandler = (row: any = null) => {
        reset(row);
        setShowPhone(true);
    };

    const deletePhone = () => {
        const newPhones = form.Phones.filter((item, j) => item.Id !== formPhone.Id);
        const newState = { ...form };
        newState.Phones = newPhones;
        dispatch(setForm(newState));
        setShowDelete(false);
    };

    const saveLocalPhone = () => {
        let update = { ...formPhone };
        update.Number = formPhone.Number.replace(/[^\d.]/g, '');

        if (update.Id) {
            const newPhones = updatePrimary(form.Phones, update);
            const newState = { ...form };
            newState.Phones = newPhones;
            dispatch(setForm(newState));
        } else {
            update.Id = getRanHex();
            let newPhones = form.Phones.concat(update);
            const newState = { ...form };
            newState.Phones = newPhones;
            newState.Phones = updatePrimary(newPhones, update);
            dispatch(setForm(newState));
        }
        setShowPhone(false);
    };

    const updatePrimary = (phones: any, update: any) => {
        let newPhones = phones.map((existingPhone) => {
            if (existingPhone.Id === update.Id) {
                return update;
            } else {
                let existingClone = { ...existingPhone };
                if (formPhone.IsPrimary) {
                    existingClone.IsPrimary = false;
                }
                return existingClone;
            }
        });
        return newPhones;
    };

    const [showPhone, setShowPhone] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [formPhone, setFormPhone] = useState(getDefaultPhone());

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Dialog open={props.open} onClose={props.onClose} fullWidth disableEnforceFocus maxWidth="xl">
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    <Grid container rowSpacing={3} columnSpacing={3}>
                        <Grid item xs={12}>
                            <DialogContentText fontWeight={'bold'}>Personal Info</DialogContentText>
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                autoFocus
                                autoComplete="off"
                                required
                                value={props.form.FirstName || ''}
                                id="first-name"
                                label="First Name"
                                name="FirstName"
                                fullWidth
                                error={
                                    (!props.formIsValid && !Boolean(props.form.FirstName)) ||
                                    (Boolean(props.form.FirstName) && !isValidName(props.form.FirstName))
                                }
                                helperText={
                                    !props.formIsValid && !Boolean(props.form.FirstName)
                                        ? 'Required'
                                        : Boolean(props.form.FirstName) && !isValidName(props.form.FirstName)
                                        ? 'First Name must have no leading/trailing spaces or special characters, except hyphen.'
                                        : ''
                                }
                                variant="outlined"
                                onChange={props.onChangeHandler}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                autoComplete="off"
                                id="middle-name"
                                value={props.form.MiddleName || ''}
                                label="Middle Name"
                                name="MiddleName"
                                fullWidth
                                error={Boolean(props.form.MiddleName) && !isValidName(props.form.MiddleName)}
                                helperText={
                                    Boolean(props.form.MiddleName) && !isValidName(props.form.MiddleName)
                                        ? 'Middle Name must have no leading/trailing spaces or special characters, except hyphen.'
                                        : ''
                                }
                                variant="outlined"
                                onChange={props.onChangeHandler}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                autoComplete="off"
                                required
                                id="last-name"
                                value={props.form.LastName || ''}
                                label="Last Name"
                                name="LastName"
                                fullWidth
                                error={
                                    (!props.formIsValid && !Boolean(props.form.LastName)) || (Boolean(props.form.LastName) && !isValidName(props.form.LastName))
                                }
                                helperText={
                                    !props.formIsValid && !Boolean(props.form.LastName)
                                        ? 'Required'
                                        : Boolean(props.form.LastName) && !isValidName(props.form.LastName)
                                        ? 'Last Name must have no leading/trailing spaces or special characters, except hyphen.'
                                        : ''
                                }
                                variant="outlined"
                                onChange={props.onChangeHandler}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl>
                                <DesktopDatePicker
                                    label="Date of Birth"
                                    onChange={(newValue: Date) => dispatch(setForm({ ...form, DOB: newValue }))}
                                    value={props.form.DOB}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            helperText={!props.formIsValid && props.form.DOB === null ? 'Required' : ''}
                                            error={!props.formIsValid && props.form.DOB === null}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl>
                                <ToggleButtonGroup
                                    exclusive
                                    color="primary"
                                    defaultValue={1}
                                    value={props.form.Gender}
                                    onChange={(event: React.MouseEvent<HTMLElement>, value: number | null) => {
                                        dispatch(setForm({ ...props.form, Gender: value }));
                                    }}
                                >
                                    <ToggleButton value={1}>Male</ToggleButton>
                                    <ToggleButton value={2}>Female</ToggleButton>
                                </ToggleButtonGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <DialogContentText marginTop={2} fontWeight={'bold'}>
                                Address Info
                            </DialogContentText>
                        </Grid>

                        <Grid item xs={5}>
                            <Autocomplete
                                id="address1"
                                value={props?.form?.Address1 || ''}
                                inputValue={props?.form?.Address1 || ''}
                                freeSolo
                                disablePortal
                                disableClearable
                                fullWidth
                                PopperComponent={(inputProps) => <Popper {...inputProps} style={{ width: 500 }} placement="bottom-start" />}
                                getOptionLabel={(option) => option?.PlacePrediction?.Text?.Text || ''}
                                options={placeList}
                                style={{ width: '100%' }}
                                onChange={(e, option) => {
                                    getAddressDetail(e, option);
                                }}
                                onInputChange={(event, newInputValue: any) => {
                                    if (event?.type === 'change') {
                                        getAddressByKeyword(newInputValue);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Address 1"
                                        name="Address1"
                                        variant="outlined"
                                        required
                                        error={!props.formIsValid && !Boolean(props.form.Address1)}
                                        helperText={!props.formIsValid && !Boolean(props.form.Address1) ? 'Required' : ''}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            {/* <TextField
                                autoComplete="off"
                                required
                                id="address1"
                                label="Address 1"
                                name="Address1"
                                value={props.form.Address1 || ''}
                                fullWidth
                                error={!props.formIsValid && !Boolean(props.form.Address1)}
                                helperText={!props.formIsValid && !Boolean(props.form.Address1) ? 'Required' : ''}
                                variant="outlined"
                                onChange={props.onChangeHandler}
                            /> */}
                        </Grid>

                        <Grid item xs={5}>
                            <TextField
                                autoComplete="off"
                                id="address2"
                                label="Address 2"
                                name="Address2"
                                value={props.form.Address2 || ''}
                                fullWidth
                                variant="outlined"
                                onChange={props.onChangeHandler}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                autoComplete="off"
                                required
                                id="city"
                                label="City"
                                name="City"
                                value={props.form.City || ''}
                                fullWidth
                                error={!props.formIsValid && !Boolean(props.form.City)}
                                helperText={!props.formIsValid && !Boolean(props.form.City) ? 'Required' : ''}
                                variant="outlined"
                                onChange={props.onChangeHandler}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel error={!props.formIsValid && !Boolean(props.form.State)} required id="demo-simple-select-label">
                                    State
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={props.form.State || ''}
                                    name="State"
                                    label="State"
                                    error={!props.formIsValid && !Boolean(props.form.State)}
                                    onChange={props.onChangeHandler}
                                >
                                    {states.map(function (state) {
                                        return (
                                            <MenuItem key={state} value={state}>
                                                {state}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText error={!props.formIsValid && !Boolean(props.form.State)}>
                                    {!props.formIsValid && !Boolean(props.form.State) ? 'Required' : ''}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                autoComplete="off"
                                id="country"
                                label="Country"
                                name="Country"
                                value={props.form.Country || ''}
                                fullWidth
                                variant="outlined"
                                onChange={props.onChangeHandler}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                autoComplete="off"
                                required
                                id="zip-code"
                                label="Zip Code"
                                name="ZipCode"
                                value={props.form.ZipCode || ''}
                                fullWidth
                                error={!props.formIsValid && !isValidZip(props.form.ZipCode)}
                                helperText={
                                    !props.formIsValid && !Boolean(props.form.ZipCode)
                                        ? 'Required'
                                        : !props.formIsValid && !isValidZip(props.form.ZipCode)
                                        ? 'Invalid Zip Code'
                                        : ''
                                }
                                variant="outlined"
                                onChange={props.onChangeHandler}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DialogContentText marginTop={2} fontWeight={'bold'}>
                                Phone
                            </DialogContentText>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    addPhoneHandler();
                                }}
                            >
                                Add Phone
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <MemberPhone
                                members={MEMBERS}
                                phoneStyles={phoneStyles}
                                selectedMember={form}
                                showPhone={showPhone}
                                setShowPhone={setShowPhone}
                                formPhone={formPhone}
                                setFormPhone={setFormPhone}
                                showDelete={showDelete}
                                setShowDelete={setShowDelete}
                                deletePhone={deletePhone}
                                addPhoneHandler={addPhoneHandler}
                                saveLocalPhone={saveLocalPhone}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DialogContentText marginTop={2} fontWeight={'bold'}>
                                Additional Info
                            </DialogContentText>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={healthPlans2}
                                    getOptionLabel={(option) => option.Name || ''}
                                    fullWidth
                                    isOptionEqualToValue={(option, value) => (option.Name || '') === (value.Name || '')}
                                    value={healthPlans2.find((el) => el.Name === props.form.ContractingHealthPlan.Name) || ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Contracting Health Plan"
                                            error={!props.formIsValid && !Boolean(props.form.ContractingHealthPlan?.Name)}
                                            helperText={!props.formIsValid && !Boolean(props.form.ContractingHealthPlan?.Name) ? 'Required' : ''}
                                            required
                                        />
                                    )}
                                    onChange={(e, newValue: any) => {
                                        if (newValue === null) {
                                            newValue = '';
                                        }
                                        dispatch(
                                            setForm({
                                                ...props.form,
                                                Population: -1,
                                                ContractingHealthPlan: { Id: newValue.Id, Name: newValue.Name },
                                            })
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel error={!props.formIsValid && props.form.Population === -1} required id="demo-simple-select-label">
                                    Population
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={-1}
                                    error={!props.formIsValid && props.form.Population === -1}
                                    value={props.form.Population || -1}
                                    label="Population"
                                    name="Population"
                                    onChange={props.onChangeHandler}
                                >
                                    <MenuItem disabled value={-1}>
                                        Select
                                    </MenuItem>
                                    {population
                                        .filter((item) => item.HealthPlan.Name === props.form.ContractingHealthPlan.Name)
                                        .map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.PopulationNumber}>
                                                    {item.PopulationName}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                                <FormHelperText error={!props.formIsValid && props.form.Population === -1}>
                                    {!props.formIsValid && props.form.Population === -1 ? 'Required' : ''}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel error={!props.formIsValid && props.form.InsuranceType === -1} required id="demo-simple-select-label">
                                    Insurance Type
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={-1}
                                    error={!props.formIsValid && props.form.InsuranceType === -1}
                                    value={props.form.InsuranceType || -1}
                                    label="Insurance Type"
                                    name="InsuranceType"
                                    onChange={(e) => {
                                        dispatch(
                                            setForm({
                                                ...props.form,
                                                InsuranceType: e.target.value as number,
                                            })
                                        );
                                    }}
                                >
                                    <MenuItem disabled value={-1}>
                                        Select
                                    </MenuItem>
                                    {insuranceTypes.map((item) => {
                                        return (
                                            <MenuItem key={item.value} value={parseInt(item.value)}>
                                                {item.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText error={!props.formIsValid && props.form.InsuranceType === -1}>
                                    {!props.formIsValid && props.form.InsuranceType === -1 ? 'Required' : ''}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel required id="demo-simple-select-label">
                                    Member Status
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={1}
                                    disabled={props.editing}
                                    value={props.form.Status || 1}
                                    label="Member Status"
                                    name="Status"
                                    onChange={props.onChangeHandler}
                                >
                                    <MenuItem disabled value={-1}>
                                        Select
                                    </MenuItem>
                                    {memberStatuses.map((item, index: number) => {
                                        return (
                                            <MenuItem key={index} value={parseInt(item.value)}>
                                                {item.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                autoComplete="off"
                                required
                                id="member-id"
                                label="MemberId"
                                name="MemberId"
                                value={props.form.MemberId || ''}
                                fullWidth
                                error={!props.formIsValid && !Boolean(props.form.MemberId)}
                                helperText={!props.formIsValid && !Boolean(props.form.MemberId) ? 'Required' : ''}
                                variant="outlined"
                                onChange={props.onChangeHandler}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                autoComplete="off"
                                required
                                id="ahcccs-id"
                                label="AhcccsId"
                                name="Mem_AHCCCS_ID"
                                value={props.form.Mem_AHCCCS_ID || ''}
                                fullWidth
                                error={!props.formIsValid && !Boolean(props.form.Mem_AHCCCS_ID)}
                                helperText={!props.formIsValid && !Boolean(props.form.Mem_AHCCCS_ID) ? 'Required' : ''}
                                variant="outlined"
                                onChange={props.onChangeHandler}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ paddingX: '16px', marginTop: 4 }}>
                    <Button className="button-120" variant="contained" onClick={() => props.submitHandler(props.form)}>
                        Save
                    </Button>
                    <Button className="button-120" variant="outlined" onClick={props.onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </LocalizationProvider>
    );
};

export default MembersDialog;
