import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiString as API_URL } from '../utils/constants';
import { AppThunk } from './store';
import { HamiltonAnxietyScaleModel } from '../Models/HamiltonAnxietyScale/HamiltonAnxietyScale.model';
import { saveEngagementDraft } from './documentEngagement.slice';
import { copyObject } from '../utils/common';
import { SummaryNoteTypeEnum } from '../Enum/SummaryNoteTypeEnum';
import { gotoNextMenu, setAssessmentError } from './assessment.slice';

export const getTotalScore = (hamiltonAnxietyScale: HamiltonAnxietyScaleModel) => {
    let totalScore = 0;
    hamiltonAssessmentFields.forEach((field) => {
        totalScore += hamiltonAnxietyScale[field] === null || hamiltonAnxietyScale[field] === undefined ? 0 : Number(hamiltonAnxietyScale[field]);
    });
    return totalScore;
};

export const hamiltonAssessmentFields = [
    'Anxious',
    'Tension',
    'Fears',
    'Insomnia',
    'Intellectual',
    'DepressedMood',
    'SomaticMuscular',
    'SomaticSensory',
    'Cardiovascular',
    'Respiratory',
    'Gastrointestinal',
    'Genitourinary',
    'AutonomicSymptoms',
    'BehaviorAtInterview',
];

export const checkCompletion = (hamiltonAnxietyScale: HamiltonAnxietyScaleModel) => {
    const incomplete = hamiltonAssessmentFields.filter((field) => hamiltonAnxietyScale[field] === null || hamiltonAnxietyScale[field] === undefined);
    return incomplete;
};

const hamiltonAnxietyScaleSlice = createSlice({
    name: 'hamiltonAnxietyScaleSlice',
    initialState: { hamiltonAnxietyScale: new HamiltonAnxietyScaleModel(), isFetchingHamiltonAnxietyScale: false },
    reducers: {
        _setIsFetchingHamiltonAnxietyScale(state, action) {
            state.isFetchingHamiltonAnxietyScale = action.payload;
        },
        _setHamiltonAnxietyScale(state, action) {
            state.hamiltonAnxietyScale = action.payload;
        },
    },
});

const { _setIsFetchingHamiltonAnxietyScale, _setHamiltonAnxietyScale } = hamiltonAnxietyScaleSlice.actions;

export const setHamiltonAnxietyScale =
    (hamiltonAnxietyScale: HamiltonAnxietyScaleModel): AppThunk =>
    async (dispatch) => {
        dispatch(_setHamiltonAnxietyScale(hamiltonAnxietyScale));
    };

export const setIsFetchingHamiltonAnxietyScale =
    (isFetching: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsFetchingHamiltonAnxietyScale(isFetching));
    };

export const saveHamiltonAnxietyScale =
    (hamiltonAnxietyScaleDto: HamiltonAnxietyScaleModel, saveLocal = false, gotoNext = false): AppThunk =>
    async (dispatch, getState) => {
        let engagementDraft = copyObject(getState().documentEngagement.engagementDraft);
        const isUpdate = Boolean(hamiltonAnxietyScaleDto.Id);
        const url = isUpdate ? `${API_URL}/hamiltonAnxietyScale/updateHamiltonAnxietyScale/` : `${API_URL}/hamiltonAnxietyScale/insertHamiltonAnxietyScale/`;

        axios
            .put(url, hamiltonAnxietyScaleDto)
            .then((response) => {
                if (Boolean(response.data)) {
                    if (isUpdate) {
                        dispatch(setHamiltonAnxietyScale(hamiltonAnxietyScaleDto));
                    } else {
                        dispatch(
                            setHamiltonAnxietyScale({
                                ...hamiltonAnxietyScaleDto,
                                Id: response.data.Id,
                            })
                        );
                    }
                }
                if (saveLocal) {
                    dispatch(
                        saveEngagementDraft({
                            ...engagementDraft,
                            HamiltonAnxietyScaleId: isUpdate ? hamiltonAnxietyScaleDto.Id : response.data.Id,
                            HamiltonAnxietyScaleStatus: hamiltonAnxietyScaleDto.AssessmentStatus,
                        })
                    );
                } else {
                    dispatch(prepareHamiltonAnxietyNotes(isUpdate ? hamiltonAnxietyScaleDto : response.data));
                }

                if (gotoNext) {
                    dispatch(gotoNextMenu());
                }
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            });
    };

const prepareHamiltonAnxietyNotes =
    (hamiltonAnxietyScaleDto: HamiltonAnxietyScaleModel): AppThunk =>
    (dispatch, getState) => {
        if (Boolean(hamiltonAnxietyScaleDto.Id)) {
            let engagementDraft = copyObject(getState().documentEngagement.engagementDraft);
            let summaryNotes = (engagementDraft.SummaryNotes || []).filter((s) => s.Type !== SummaryNoteTypeEnum.HamiltonAnxietyScale);
            summaryNotes.push({
                Id: hamiltonAnxietyScaleDto.MemberId,
                CptCode: '',
                Zcode: '',
                Note: 'Completed Hamilton Anxiety Scale - Score: ' + getTotalScore(hamiltonAnxietyScaleDto),
                Type: SummaryNoteTypeEnum.HamiltonAnxietyScale,
                IsConfirm: false,
                DisplayName: 'Hamilton Anxiety Scale',
            });

            engagementDraft.SummaryNotes = summaryNotes;
            dispatch(
                saveEngagementDraft({
                    ...engagementDraft,
                    HamiltonAnxietyScaleId: hamiltonAnxietyScaleDto.Id,
                    HamiltonAnxietyScaleStatus: hamiltonAnxietyScaleDto.AssessmentStatus,
                })
            );
        }
    };

export const fetchHamiltonAnxietyScale =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsFetchingHamiltonAnxietyScale(true));
        const response = await axios.get(`${API_URL}/hamiltonAnxietyScale/gethamiltonanxietyscalebyid?id=${id}`);
        dispatch(setHamiltonAnxietyScale(response.data));
        dispatch(setIsFetchingHamiltonAnxietyScale(false));
    };

export default hamiltonAnxietyScaleSlice.reducer;
