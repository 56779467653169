import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import {
    Box,
    Tabs,
    Tab,
    Button,
    Grid,
    Snackbar,
    Alert,
    CircularProgress,
    DialogActions,
    DialogContent,
    Dialog,
    DialogContentText,
    Stack,
    Checkbox,
    Link,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Tooltip,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

import {
    resetAssessment,
    fetchRoles,
    resetAssessmentError,
    resetAssessmentSuccess,
    setAssessmentSuccess,
    updateAssessmentMenu,
} from '../../store/assessment.slice';
import { resetFuhFumAssessment } from '../../store/fuhfum.slice';
import { resetHraAssessment } from '../../store/hra.slice';
import { fetchMemberDemoGraphicInfo, getMemberBasicInfo } from '../../store/memberDetail.slice';
import Role from '../../Models/Role/Role.model';
import { roleEnum, assessmentMenuEnum } from '../../utils/assessments';
import Consent from './Consent/Consent';
import './Assessment.css';
import { MenuDetails } from '../../Models/Assessment/AssessmentMenu.model';
import VitalSigns from './VitalSigns/VitalSigns';
import PastHistory from './PastHistory/PastHistory';
import { AssessmentTypeEnum } from '../../Enum/AssessmentTypeEnum';
import Sdoh from './Sdoh/SdohAssessment';
import ReviewSystem from './ReviewSystem/ReviewSystem';
import PhysicalExam from './PhysicalExam/PhysicalExam';
import PatientHealthQuestionnaire from './PHQ9/PHQ9';
// import PatientAdmission from './TransitionalCare/PatientAdmission';
import DiabetesFoot from './DiabetesFoot/DiabetesFoot';
import UrinalysisReport from './UrinalysisReport/UrinalysisReport';
import ProgressNotes from './ProgressNotes/ProgressNotes';
import ProcedureSignOff from './ProcedureSignOff/ProcedureSignOff';
import SetAppointment from './SetAppointment/SetAppointment';
import SummaryNotes from './SummaryNotes/SummaryNotes';
import MemberAllergyComponent from './MemberAllergy/MemberAllergy';
import CounselingComponent from './Counseling/Counseling';
import MiniMentalExamComponent from './MiniMentalExam/MiniMentalExam';
import MemberPcpSpecialist from '../Members/MemberDetail/MemberPcpSpecialist/MemberPcpSpecialist';
import SubjectiveAssessmentComponent from './SubjectiveAssessment/SubjectiveAssessment';
import OtherFactorAndRiskAssessment from './OtherFactorAndRiskAssessment/OtherFactorAndRiskAssessment';
import HgbA1C from './HgbA1C/HgbA1C';
import CarePlans from './CarePlans/CarePlans';
import LiqidAssessment from './LipidAssessment/LipidAssessment';
import {
    deleteAssessmentDrafts,
    getEngagementDraftPreview,
    getMemberAssessmentHistoryForWorkflow,
    saveEngagementDraft,
    setEngagementDraftPreviews,
    toggleIsOpenSavedDraftsModel,
    resetDocumentEngagement,
} from '../../store/documentEngagement.slice';
import classes from './Styles';
import { copyObject } from '../../utils/common';
import AlertDialog from '../AlertDialog/AlertDialog';
import MemberAssessmentDemographics from './Demographics/Demographics';
import Interventions from './Interventions/Interventions';
import BudgetForm from './BudgetForm/BudgetForm';
import MemberAssessmentCareCircle from './CareCircle/CareCircle';
import { PrepareMenuClass } from '../../Models/Shared/PrepareMenuClass.model';
import TabletComponent from './Tablet/Tablet';
import FollowUp from './FollowUp/FollowUp';
import MedicationReconciliation from './MedicationReconciliation/MedicationReconciliation';
import AssessmentHistory from './History/History';
import RemoteMonitoring from './RemoteMonitoring/RemoteMonitoring';
import ActivitiesOfDailyLiving from './ActivitiesOfDailyLiving/ActivitiesOfDailyLiving';
import SexualHealthAssessment from './SexualHealth/SexualHealth';
import FallRiskAssessment from './FallRiskAssessment/FallRiskAssessment';
import VisionAndHearingAssessment from './VisionAndHearingAssessment/VisionAndHearingAssessment';
import TobaccoAndDrug from './TobaccoAndDrug/TobaccoAndDrug';
import PreventativeHealthAdviceAssessment from './PreventativeHealthAdvice/PreventativeHealthAdvice';
import MemberAdvancedDirectives from '../Members/MemberDetail/MemberAdvancedDirectives';
import HraEducationAssessment from './HraEducation/HraEducation';
import Requests from '../Requests/Requests';
import Authorization from '../../utils/Authorization';
import MemberEducation from '../MemberEducation/MemberEducation';
import AdditionalCHPCriteriaAssessment from './AdditionalCHPCriteria/AdditionalCHPCriteria';
import { MemberStatusEnum } from '../../Enum/MemberStatusEnum';
import { insertTimeline } from '../../store/timeline.slice';
import { TimelineTypeEnum } from '../../Enum/TimelineTypeEnum';
import ScreeningFuhFumAssessment from './ScreeningFuhFum/ScreeningFuhFum';
import TransitionalCare from '../TransitionalCare/TransitionalCare';
import { useParams } from 'react-router-dom';
import HamiltonAnxietyScale from './HamiltonAnxietyScale/HamiltonAnxietyScale';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    sx?: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const Assessment = (props: any) => {
    const dispatch = useDispatch();
    const params = useParams();
    const memberId = props.memberId || params.memberId;
    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
    const [showStatusChangeAlert, setShowStatusChangeAlert] = useState<boolean>(false);
    const [selectedRoleId, setSelectedRoleId] = useState<string>('');
    const [showHistory, setShowHistory] = useState<boolean>(false);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isSavingDraft, setIsSavingDraft] = useState<boolean>(false);
    const [roleList, setRoleList] = useState<Role[]>([]);
    const [selectedEngagementDraftId, setSelectedEngagementDraftId] = useState<string>('');
    const { roles, assessmentMenuList, selectedRole, error, success } = useSelector((state: RootState) => state.assessment);
    const { memberBasicInfo } = useSelector((state: RootState) => state.memberDetail);
    const { memberAssessmentHistory, engagementDraft, engagementDraftPreviews, isFetchingPreviews, isOpenSavedDraftsModel, isMemberHistoryFetching } =
        useSelector((state: RootState) => state.documentEngagement);
    const selectedMenu = assessmentMenuList.find((a) => a.IsActive)?.Name;
    const isMultiDraftsSelected = engagementDraftPreviews.filter((e) => e.IsSelected).length > 1;

    const authData = new Authorization();
    const canShowAssessment =
        authData.Role === roleEnum.NURSE_PRACTITIONER ||
        authData.Role === roleEnum.CARE_COORDINATOR ||
        authData.Role === roleEnum.CEO ||
        authData.Role === roleEnum.ADMIN ||
        authData.Role === roleEnum.CLINICAL_LIAISON ||
        authData.Role === roleEnum.BEHAVIOURAL_HEALTH_SPECIALIST ||
        authData.Role === roleEnum.FIELD_ONBOARDING ||
        authData.Role === roleEnum.COMMUNITY_HEALTH_WORKER ||
        authData.Role === roleEnum.HRA ||
        authData.Role === roleEnum.CODER ||
        authData.Role === roleEnum.TRANSITIONAL_CARE_COORDINATOR;
    const isInHra = selectedRole === roleEnum.HRA;

    useEffect(() => {
        if (canShowAssessment && roles.length > 0) {
            dispatch(
                getMemberBasicInfo(memberId, () => {
                    dispatch(getEngagementDraftPreview(memberId, true, roles));
                })
            );
        }
    }, [dispatch, memberId, canShowAssessment, roleList, roles]);

    useEffect(() => {
        if (roles.length > 0 && !isFetched && Boolean(memberBasicInfo?.Id)) {
            let selectedRoles = roles.filter(
                (r) =>
                    r.Name === roleEnum.NURSE_PRACTITIONER ||
                    r.Name === roleEnum.CARE_COORDINATOR ||
                    r.Name === roleEnum.CLINICAL_LIAISON ||
                    r.Name === roleEnum.CEO ||
                    (memberBasicInfo?.IsInHra && r.Name === roleEnum.HRA) ||
                    r.Name === roleEnum.ADMIN ||
                    r.Name === roleEnum.MEDICAL_LIAISON ||
                    r.Name === roleEnum.BEHAVIOURAL_HEALTH_SPECIALIST ||
                    r.Name === roleEnum.FIELD_ONBOARDING ||
                    r.Name === roleEnum.COMMUNITY_HEALTH_WORKER ||
                    r.Name === roleEnum.TRANSITIONAL_CARE_COORDINATOR
            );

            setRoleList(selectedRoles);
            setIsFetched(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetched, roles, memberBasicInfo]);

    const initialPage = useCallback(async () => {
        dispatch(fetchRoles());
        dispatch(fetchMemberDemoGraphicInfo(memberId));
    }, [dispatch, memberId]);

    useEffect(() => {
        if (isFetched) {
            return;
        }
        initialPage();
    }, [initialPage, isFetched]);

    useEffect(() => {
        dispatch(resetAssessment());
        dispatch(resetHraAssessment());
        dispatch(resetFuhFumAssessment());
        dispatch(resetDocumentEngagement());
    }, [dispatch]);

    const changeRoles = (role: string, isConfimedChanged = false) => {
        if (
            !memberBasicInfo.IsInHra &&
            !memberBasicInfo.IsInFuhFum &&
            selectedRole === roleEnum.FIELD_ONBOARDING &&
            (memberBasicInfo.Status === MemberStatusEnum.UndeterminedDescription || memberBasicInfo.Status === MemberStatusEnum.ScheduledDescription)
        ) {
            if (isConfimedChanged) {
                const meesage = `Provider has started ${role} Assessment when status is in ${memberBasicInfo.Status}.`;
                dispatch(insertTimeline(memberId, meesage, TimelineTypeEnum.Assessments));
                setShowStatusChangeAlert(false);
            } else {
                setSelectedRoleId(role);
                setShowStatusChangeAlert(true);
                return;
            }
        }

        if (isFetched) {
            handleOnClickNew(role);
        }
    };

    const handleOnClickNew = (role = '') => {
        dispatch(resetAssessment());
        dispatch(resetHraAssessment());
        dispatch(resetFuhFumAssessment());
        dispatch(resetDocumentEngagement());
        dispatch(getMemberAssessmentHistoryForWorkflow(memberId, null, role, roleList));
    };

    const handleOnClickSaveDraft = () => {
        setIsSavingDraft(true);
        dispatch(
            saveEngagementDraft(engagementDraft, false, () => {
                setIsSavingDraft(false);
                dispatch(
                    setAssessmentSuccess({
                        Show: true,
                        Message: 'Your draft is saved successfully',
                    })
                );
            })
        );
    };

    const handleChangeMenu = (event: React.ChangeEvent<{}>, newValue: string) => {
        dispatch(updateAssessmentMenu({ ActiveMenu: newValue } as PrepareMenuClass));
    };

    const renderTabLable = (menu: MenuDetails, index: Number) => {
        const errorStatus = menu.IsPending ? 'Pending' : menu.IsExpired ? 'Expired' : '';
        return (
            <div className="menu-item" key={`assessment-menu-item-${index}`}>
                <ArrowForwardIosIcon fontSize="small" className="arrow-icon" />
                <span className="fill">{menu.DisplayName || menu.Name}</span>
                {(menu.IsPending || menu.IsExpired) && (
                    <Tooltip title={errorStatus}>
                        <ErrorIcon fontSize="small" />
                    </Tooltip>
                )}
                {menu.IsCompleted && (
                    <Tooltip title="Complete">
                        <CheckCircleIcon fontSize="small" />
                    </Tooltip>
                )}
            </div>
        );
    };

    const handleOnClickDraftOrNew = (id: string = null) => {
        setShowHistory(false);
        setSelectedEngagementDraftId(id);
        dispatch(getMemberAssessmentHistoryForWorkflow(memberId, id, null, roleList));
    };

    const handleCloseDocumentDrafts = () => {
        dispatch(getMemberAssessmentHistoryForWorkflow(memberId, null, null, roleList));
        dispatch(toggleIsOpenSavedDraftsModel(false));
    };

    const handleOnSelectDraftPreview = (selected: boolean, index: number) => {
        let data = copyObject(engagementDraftPreviews);
        data[index].IsSelected = selected;

        dispatch(setEngagementDraftPreviews(data));
    };

    const renderDocumentDrafts = () => {
        return (
            <Dialog open={isOpenSavedDraftsModel} onClose={handleCloseDocumentDrafts} scroll="paper">
                <DialogContent dividers={true}>
                    <DialogContentText id="scroll-dialog-content" tabIndex={-1} fontSize={14}>
                        There are assessments available in drafts. You can load from the draft or start a new one.
                    </DialogContentText>
                    <Button
                        variant="contained"
                        color="info"
                        sx={{ marginY: 2 }}
                        className="text-transform-none"
                        size="small"
                        onClick={() => setShowDeleteAlert(true)}
                        disabled={!engagementDraftPreviews.some((d) => d.IsSelected)}
                    >
                        Delete Selected Draft{isMultiDraftsSelected ? 's' : ''}
                    </Button>
                    {engagementDraftPreviews.map((draft, index) => (
                        <Stack key={`saved-draft${draft.Id}`} direction="row" spacing={0} alignItems="center" style={{ marginLeft: '-10px' }}>
                            <Checkbox checked={draft.IsSelected} onChange={(e) => handleOnSelectDraftPreview(e.target.checked, index)} />
                            <Link underline="none" onClick={() => handleOnClickDraftOrNew(draft.Id)}>
                                {`Draft ${index + 1} - ${moment(draft?.CreatedDate).format('MMMM DD, yyyy hh:mm A')}`}
                            </Link>
                            {selectedEngagementDraftId === draft.Id ? <CircularProgress size={16} className="ml-2" /> : null}
                        </Stack>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => handleOnClickDraftOrNew()}
                        className="text-transform-none"
                        disabled={isMemberHistoryFetching}
                        endIcon={isMemberHistoryFetching && !Boolean(selectedEngagementDraftId) ? <CircularProgress size={18} color="inherit" /> : null}
                    >
                        Start a New One
                    </Button>
                    <Button variant="outlined" onClick={handleCloseDocumentDrafts} className="text-transform-none">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            {!canShowAssessment ? (
                <Stack padding={4} alignItems="center">
                    <Button variant="contained" className="text-transform" onClick={() => setShowHistory(true)}>
                        History
                    </Button>
                </Stack>
            ) : !isFetched || (isFetchingPreviews && !showHistory) || isOpenSavedDraftsModel || isMemberHistoryFetching ? (
                <Box display="flex" justifyContent="center" sx={classes.loadingContainer}>
                    {isOpenSavedDraftsModel ? null : <CircularProgress />}
                </Box>
            ) : (
                <>
                    <Grid container spacing={2} p={2}>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <InputLabel id="assessment-role-select-label">Role</InputLabel>
                                <Select
                                    labelId="assessment-role-select-label"
                                    label="Role"
                                    value={selectedRole}
                                    onChange={(event) => changeRoles(event.target.value)}
                                >
                                    {roleList
                                        .filter((r) => r.Id)
                                        .map((type, index) => (
                                            <MenuItem key={`${type.Id}-${index}`} value={type.Name}>
                                                {type.Name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={10}>
                            <Stack columnGap={1} flexDirection="row" height="100%" justifyContent="flex-end" alignItems="center">
                                <div className="pr-2">
                                    <label className="pull-left mt-2 font-bold">Last Assessment Completed : </label>
                                    <div className="pull-left mt-1 ml-1 vital-sign-date">
                                        {moment(memberAssessmentHistory.LastAssessmentCompletedOn).isValid()
                                            ? moment(memberAssessmentHistory.LastAssessmentCompletedOn).format('MM/DD/yyyy')
                                            : '-'}
                                    </div>
                                </div>
                                <Button variant="contained" size="small" className="text-transform mt-0" onClick={() => setShowHistory(true)}>
                                    History
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className="text-transform mt-0"
                                    onClick={handleOnClickSaveDraft}
                                    endIcon={isSavingDraft ? <CircularProgress size={18} color="inherit" /> : null}
                                >
                                    Save Draft
                                </Button>
                                <Button size="small" variant="contained" color="primary" className="text-transform mt-0" onClick={() => handleOnClickNew()}>
                                    <ControlPointIcon fontSize="small" />
                                    &nbsp;New
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} p={2} paddingTop={0}>
                        <Grid item xs={2}>
                            <div className="assessment-tab">
                                <Tabs orientation="vertical" value={selectedMenu} onChange={handleChangeMenu}>
                                    {assessmentMenuList.map((menu, index) => {
                                        return (
                                            <Tab
                                                key={`${menu.Id}`}
                                                disabled={menu.IsDisabled}
                                                value={menu.Name}
                                                label={renderTabLable(menu, index)}
                                                {...a11yProps(menu.Name)}
                                                className={`text-transform ${menu.IsExpired && 'menu-error'}`}
                                            />
                                        );
                                    })}
                                </Tabs>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="assmnt-tab-panel-container">
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.CONSENT}>
                                    <Consent memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.DEMOGRAPHICS}>
                                    <MemberAssessmentDemographics memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.CARE_PLANS}>
                                    <CarePlans memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.VITAL_SIGNS}>
                                    <VitalSigns memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.PRAPARE_SDOH}>
                                    <Sdoh memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.BUDGET_FORM}>
                                    <BudgetForm memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.PAST_PRESENT_HISTORY}>
                                    <PastHistory type={AssessmentTypeEnum.PastPresentHistory} memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.PAST_SURGICAL_HISTORY}>
                                    <PastHistory type={AssessmentTypeEnum.PastSurgicalHistory} memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.REVIEW_SYSTEMS}>
                                    <ReviewSystem memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.PHYSICAL_EXAM}>
                                    <PhysicalExam memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.HGBA1C_ASSESSMENT}>
                                    <HgbA1C memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.TRANSITIONAL_CARE}>
                                    <TransitionalCare memberId={memberId} isAssessment={true} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.EDUCATION}>
                                    <MemberEducation memberId={memberId} isAssessment={true} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.MINI_MENTAL_STATUS_EXAM}>
                                    <MiniMentalExamComponent memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.SUBJECTIVE_ASSESSMENT}>
                                    <SubjectiveAssessmentComponent memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.INTERVENTIONS}>
                                    <Interventions memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.OTHER_FACTORS_AND_RISKS}>
                                    <OtherFactorAndRiskAssessment memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.PHQ9}>
                                    <PatientHealthQuestionnaire memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.TOBACCO_AND_DRUG}>
                                    <TobaccoAndDrug memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.SEXUAL_HEALTH}>
                                    <SexualHealthAssessment memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.ADL}>
                                    <ActivitiesOfDailyLiving memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.FALL_RISK}>
                                    <FallRiskAssessment memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.VISION_AND_HEARING}>
                                    <VisionAndHearingAssessment memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.PREVENTATION_HEALTH_ADVICE}>
                                    <PreventativeHealthAdviceAssessment memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.ADVANCED_DIRECTIVE}>
                                    <MemberAdvancedDirectives isAssessment />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.ADDITIONAL_CHP_CRITERIA}>
                                    <AdditionalCHPCriteriaAssessment memberId={memberId} />
                                </TabPanel>
                                {isInHra ? (
                                    <TabPanel value={selectedMenu} index={assessmentMenuEnum.HRA_EDUCATION}>
                                        <HraEducationAssessment memberId={memberId} />
                                    </TabPanel>
                                ) : null}
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.REQUESTS_REFERRALS}>
                                    <Requests memberId={memberId} isAssessment title={'CHP - Member Chart'} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.FOLLOW_UP}>
                                    <FollowUp memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.TABLETS}>
                                    <TabletComponent memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.LIPID_ASSESSMENT}>
                                    <LiqidAssessment memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.ALLERGIES}>
                                    <MemberAllergyComponent memberId={memberId} isAssessment={true} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.MEDICATION_RECONCILIATION}>
                                    <MedicationReconciliation memberId={memberId} memberDetail={false} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.DIABETES_FOOT}>
                                    <DiabetesFoot memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.URINALYSIS_REPORT}>
                                    <UrinalysisReport memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.REMOTE_MONITORING}>
                                    <RemoteMonitoring memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.PCP_SPECIALIST}>
                                    <MemberPcpSpecialist isAssessment />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.CARE_CIRCLE} sx={classes.careCircleTab}>
                                    <MemberAssessmentCareCircle />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.COUNSELING}>
                                    <CounselingComponent memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.SCREENING_FUHFUM}>
                                    <ScreeningFuhFumAssessment memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.HAMILTON_ANXIETY_SCALE}>
                                    <HamiltonAnxietyScale memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.PROGRESS_NOTES}>
                                    <ProgressNotes memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.SET_APPOINTMENTS}>
                                    <SetAppointment memberId={memberId} />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.SUMMARY_NOTES}>
                                    <SummaryNotes />
                                </TabPanel>
                                <TabPanel value={selectedMenu} index={assessmentMenuEnum.PROCEDURE_SIGN_OFF}>
                                    <ProcedureSignOff memberId={memberId} />
                                </TabPanel>
                            </div>
                        </Grid>
                    </Grid>
                </>
            )}
            {renderDocumentDrafts()}
            <AssessmentHistory
                memberId={memberId}
                open={showHistory}
                canShowDrafts={canShowAssessment}
                onClose={() => setShowHistory(false)}
                onSelectDraft={handleOnClickDraftOrNew}
            />
            <AlertDialog
                open={showDeleteAlert}
                onConfirm={() => {
                    setShowDeleteAlert(false);
                    dispatch(deleteAssessmentDrafts(engagementDraftPreviews.filter((e) => e.IsSelected).map((e) => e.Id)));
                }}
                onClose={() => setShowDeleteAlert(false)}
                message={`Are you sure you want to delete the selected draft${isMultiDraftsSelected ? 's' : ''}?`}
            />
            <AlertDialog
                open={showStatusChangeAlert}
                onConfirm={() => changeRoles(selectedRoleId, true)}
                okText="Yes"
                cancelText="No"
                onClose={() => setShowStatusChangeAlert(false)}
                message={`You have chosen to change the role for this assessment. This member is not yet in active status and requires an onboarding assessment available only under the field onboarding role. Do you still wish to proceed?`}
            />
            <Snackbar
                open={error?.Show}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => dispatch(resetAssessmentError())}
            >
                <Alert onClose={() => dispatch(resetAssessmentError())} severity="error" sx={{ width: '100%' }}>
                    {error?.Message}
                </Alert>
            </Snackbar>
            <Snackbar
                open={success?.Show}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => dispatch(resetAssessmentSuccess())}
            >
                <Alert onClose={() => dispatch(resetAssessmentSuccess())} severity="success" sx={{ width: '100%' }}>
                    {success?.Message}
                </Alert>
            </Snackbar>
        </LocalizationProvider>
    );
};

export default Assessment;
