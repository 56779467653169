import {
    Grid,
    Paper,
    Typography,
    IconButton,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    ListSubheader,
    MenuItem,
    TextField,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { DataGrid, GridColDef, gridClasses, GridValueGetterParams } from '@mui/x-data-grid';
import { RootState } from '../../../../reducers';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useNavigate } from 'react-router';

import { useWidgetContext } from '../../../../hooks/widgetContext';
import WidgetLoading from '../../../SmartDashboard/Shared/WidgetLoading';
import { dateFormatter } from '../../../../utils/timeFormat';
import { WidgetTimeFilterEnum } from '../../../../Enum/WidgetTimeFilterEnum';
import Authorization from '../../../../utils/Authorization';
import { fetchFuhFumInProgressSummary } from '../../../Widgets/WidgetService';
import { isDefaultDate } from '../../../../utils/common';
import { parentUrl } from '../../../../utils/constants';

const daysList = [
    { Id: 1, Name: '1-7 Days' },
    { Id: 2, Name: '8-30 Days' },
    { Id: 3, Name: '30+ Days' },
];

const dayFilterList = [
    { Id: WidgetTimeFilterEnum.Last30Days, Value: WidgetTimeFilterEnum.Last30DaysDescription },
    { Id: WidgetTimeFilterEnum.Last60Days, Value: WidgetTimeFilterEnum.Last60DaysDescription },
    { Id: WidgetTimeFilterEnum.Custom, Value: WidgetTimeFilterEnum.CustomDescription },
];

const FuhFumInProgressSummary = () => {
    const ref = useRef(null);
    const authData = new Authorization();
    const history = useNavigate();
    const [rows, setRows] = useState([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const [pageSize, setPageSize] = useState(15);
    const { activeProviders, tccProviders, licensedProviders, isFetchedTCCProvider, isFetchedLicensedProvider } = useSelector(
        (state: RootState) => state.provider
    );
    const [providers, setProviders] = useState([]);
    const {
        inProgressProvider,
        setInProgressProvider,
        inProgressSelectedDay,
        setInProgressSelectedDay,
        inProgressDateFilter,
        setInProgressDateFilter,
        inProgressFromDate,
        setInProgressFromDate,
        inProgressToDate,
        setInProgressToDate,
        inProgressShowInActiveProviders,
        setInProgressShowInActiveProviders,
    } = useWidgetContext();
    const filteredTccProviders = (inProgressShowInActiveProviders ? tccProviders : tccProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const filteredLicensedProviders =
        (inProgressShowInActiveProviders ? licensedProviders : licensedProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const schedulingProviders = activeProviders.filter((p) => p.Role.RoleName === 'Scheduling') || [];
    const filteredSchedulingProviders =
        (inProgressShowInActiveProviders ? schedulingProviders : schedulingProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const initialPage = useCallback(() => {
        if (isFetchedTCCProvider && isFetchedLicensedProvider) {
            let providersList = [
                {
                    Name: 'TCC Providers',
                    List: filteredTccProviders,
                },
                {
                    Name: 'Scheduling Providers',
                    List: filteredSchedulingProviders,
                },
                {
                    Name: 'Licensed Providers',
                    List: filteredLicensedProviders,
                },
            ];
            setProviders(providersList);
            if (providersList.length) {
                const loggedInProviderId = authData.UserId;
                if (Boolean(loggedInProviderId) && !Boolean(inProgressProvider)) {
                    const tccInfo = filteredTccProviders.find((t) => t.Id === loggedInProviderId);
                    const licensdInfo = filteredLicensedProviders.find((t) => t.Id === loggedInProviderId);
                    const schedulingInfo = filteredSchedulingProviders.find((t) => t.Id === loggedInProviderId);
                    if (Boolean(tccInfo) || Boolean(licensdInfo) || Boolean(schedulingInfo)) {
                        setInProgressProvider(loggedInProviderId);
                    } else {
                        setInProgressProvider('All');
                    }
                } else if (!Boolean(inProgressProvider)) {
                    setInProgressProvider('All');
                }
            } else {
                setInProgressProvider('All');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchedLicensedProvider, isFetchedTCCProvider, inProgressProvider, setInProgressProvider, authData.UserId, inProgressShowInActiveProviders]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    const handleClick = (event) => {
        setShow(!show);
    };

    const columns: GridColDef[] = [
        {
            field: 'MemberName',
            headerName: 'Member Name',
            flex: 1,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            flex: 0.8,
            renderCell: (params) => {
                return <>{isDefaultDate(params.row.DOB) ? '-' : dateFormatter(new Date(params.row.DOB))}</>;
            },
        },
        {
            field: 'LastAppointmentDate',
            headerName: 'Last Appointment Date',
            flex: 1.2,
            renderCell: (params) => {
                return <>{isDefaultDate(params.row.LastAppointmentDate) ? '-' : dateFormatter(new Date(params.row.LastAppointmentDate))}</>;
            },
        },
        {
            field: 'SeenByLicensedStaff',
            headerName: 'Seen By Licensed Staff',
            flex: 1.2,
            valueGetter: (params: GridValueGetterParams) => `${Boolean(params.row.SeenByLicensedStaff) ? 'Yes' : 'No'}`,
        },
        {
            field: 'IsAssessed',
            headerName: 'Assessed',
            flex: 0.8,
            valueGetter: (params: GridValueGetterParams) => `${Boolean(params.row.IsAssessed) ? 'Yes' : 'No'}`,
        },
        {
            field: 'IsDocumentationCompleted',
            headerName: 'Documented in 24hrs',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${Boolean(params.row.IsDocumentationCompleted) ? 'Yes' : 'No'}`,
        },
    ];

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 80 : 90) + ''));
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFuhFumSummary = async () => {
        if (
            Boolean(inProgressProvider) &&
            (inProgressDateFilter !== WidgetTimeFilterEnum.Custom || (Boolean(inProgressFromDate) && Boolean(inProgressToDate)))
        ) {
            setIsFetched(false);
            let dateFrom =
                Boolean(inProgressFromDate) && inProgressDateFilter === WidgetTimeFilterEnum.Custom ? new Date(inProgressFromDate).toISOString() : '';
            let dateTo = Boolean(inProgressToDate) && inProgressDateFilter === WidgetTimeFilterEnum.Custom ? new Date(inProgressToDate).toISOString() : '';

            const data = await fetchFuhFumInProgressSummary(
                inProgressProvider,
                inProgressSelectedDay,
                inProgressDateFilter,
                dateFrom,
                dateTo,
                inProgressShowInActiveProviders
            );
            setRows(data);
            setIsFetched(true);
        }

        if (inProgressDateFilter !== WidgetTimeFilterEnum.Custom && Boolean(inProgressFromDate) && Boolean(inProgressToDate)) {
            setInProgressFromDate(null);
            setInProgressToDate(null);
        }
    };

    const changeFromDate = (date: any) => {
        setInProgressFromDate(date);
    };

    const changeToDate = (date: any) => {
        setInProgressToDate(date);
    };

    useEffect(() => {
        getFuhFumSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inProgressProvider, inProgressSelectedDay, inProgressDateFilter, inProgressFromDate, inProgressToDate, inProgressShowInActiveProviders]);

    const navigateToMembers = () => {
        history(`/provider/metricsummary/fuhfuminprogressmemberdetail`);
    };

    const toggleProviderHandler = (isChecked: boolean) => {
        setInProgressShowInActiveProviders(isChecked);
        setInProgressProvider('All');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography color={'primary'} variant="h6" textAlign={'center'} ref={ref}>
                                <span className="pointer" onClick={navigateToMembers}>
                                    FUHFUM In-Progress Summary
                                </span>
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography variant="caption">Members with FUHFUM In-Progress status within the date range</Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={2.5}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="pro-select-label">Provider</InputLabel>
                                <Select
                                    label="Provider"
                                    value={inProgressProvider}
                                    onChange={(e) => {
                                        setInProgressProvider(e.target.value as string);
                                    }}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={
                                            <Checkbox checked={inProgressShowInActiveProviders} onChange={(e) => toggleProviderHandler(e.target.checked)} />
                                        }
                                        label="Show Inactive"
                                    />
                                    {providers.map((provider, index) => [
                                        <ListSubheader key={index}>{provider.Name}</ListSubheader>,
                                        provider.List.map((pro) => {
                                            return (
                                                <MenuItem
                                                    key={pro.Id}
                                                    value={pro.Id}
                                                    sx={{ paddingLeft: 4, color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}
                                                >
                                                    {pro.LastName}, {pro.FirstName}
                                                </MenuItem>
                                            );
                                        }),
                                    ])}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2.5}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="provider-label">Days Since Discharge</InputLabel>
                                <Select
                                    value={inProgressSelectedDay}
                                    label="Days Since Discharge"
                                    onChange={(e) => {
                                        setInProgressSelectedDay(e.target.value as string);
                                    }}
                                >
                                    {daysList.map(function (day) {
                                        return (
                                            <MenuItem key={day.Id} value={day.Id}>
                                                {day.Name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2.5}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Referral Date</InputLabel>
                                <Select
                                    value={inProgressDateFilter}
                                    label="Referral Date"
                                    name="Referral Date"
                                    onChange={(e) => setInProgressDateFilter(parseInt(e.target.value as any))}
                                >
                                    {dayFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {inProgressDateFilter === WidgetTimeFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item md={2}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(inProgressFromDate) ? inProgressFromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item md={2}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(inProgressToDate) ? inProgressToDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} marginTop={2}>
                        <div style={{ width: '100%', height: 'Calc(50vh - 65px)', position: 'relative' }}>
                            {!isFetched ? (
                                <WidgetLoading />
                            ) : (
                                <DataGrid
                                    onCellClick={(params: any) => {
                                        window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                    }}
                                    rows={rows}
                                    columns={columns}
                                    pageSize={pageSize}
                                    rowsPerPageOptions={[5]}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    getRowId={(row) => row.Id}
                                    getRowHeight={() => 'auto'}
                                    sx={{
                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },
                                        [`& .green`]: {
                                            color: 'green',
                                        },
                                        [`& .red`]: {
                                            color: 'red',
                                        },
                                        [`& .yellow`]: {
                                            color: 'gold',
                                        },
                                        [`& .black`]: {
                                            color: 'rgba(0,0,0,0.87)',
                                        },
                                        [`& .bggreen`]: {
                                            backgroundColor: 'green',
                                            color: 'white',
                                        },
                                        [`& .bgred`]: {
                                            backgroundColor: 'red',
                                            color: 'white',
                                        },
                                        [`& .bgyellow`]: {
                                            backgroundColor: 'gold',
                                            color: 'black',
                                        },
                                    }}
                                />
                            )}
                        </div>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default FuhFumInProgressSummary;
