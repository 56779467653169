import { MeansOfEngagementEnum } from '../../Enum/MeansOfEngagementEnum';
import { MemberStatusEnum } from '../../Enum/MemberStatusEnum';
import { PatientOutreachOrInitiatedTypeEnum } from '../../Enum/PatientOutreachOrInitiatedTypeEnum';
import { StatusEnum } from '../../Enum/StatusEnum';
import { Option } from '../Assessment/AssessmentCategory.model';
import { MemberEncounter } from '../Member/MemberEncounter.model';
import { SummaryNote } from './SummaryNote.model';

export default interface IEngagementDraft {
    Id: string;
    MemberId: string;
}

export class ReasonForVisit {
    Id: string;
    MemberId: string;
    Name?: string;
}

export class StaffReferral {
    Id: string;
    ProviderName: string;
}

export class RequestReviewStatus {
    Reviewed: boolean;
    ForgotAndUnable: boolean;
    ReviewedPriorOrNoReviewNeeded: boolean;
}

export class ProviderRole {
    Id: string;
    RoleName: string;
}

export class TelemedicineDetails {
    Id: string;
    Options: Option[];
}

export class EngagementDraft implements IEngagementDraft {
    Id: string;
    MemberId: string;
    MeansOfEngagement: MeansOfEngagementEnum | null;
    VisitedTime: string;
    ReasonForVisit: ReasonForVisit;
    StaffReferral: StaffReferral;
    PatientOutreachOrInitiatedType: PatientOutreachOrInitiatedTypeEnum;
    Contacted: boolean;
    ContactResult: string;
    AvoidedER: boolean | null;
    VitalsignAssessmentId: string;
    SdohAssessmentId: string;
    PastPresentHistoryId: string;
    PastSurgicalHistoryId: string;
    ReviewSystemId: string;
    PhysicalExamId: string;
    PhqAssessmentId: string;
    HgbA1CAssessmentId: string;
    DiabetesFootAssessmentId: string;
    UrinalysisReportId: string;
    MiniMentalExamId: string;
    FactorAndRiskAssessmentId: string;
    SubjectiveAssessmentId: string;
    LipidAssessmentId: string;
    CounselingAssessmentId: string;
    ProgressNotes: string;
    Notes: string;
    FollowupRecommendations: string;
    SummaryNotes: SummaryNote[];
    GoalStatus: StatusEnum;
    MedicationReconciliationStatus: StatusEnum;
    CarePlanStatus: StatusEnum;
    RequestStatus: StatusEnum;
    TransitionalCareStatus: StatusEnum;
    EducationStatus: StatusEnum;
    DocumentEngagementStatus: StatusEnum;
    FollowUpStatus: StatusEnum;
    RequestReviewStatus: RequestReviewStatus;
    InterventionStatus: StatusEnum;
    Encounters: MemberEncounter[];
    AppointmentIds: string[];
    AssociatedAppointmentId: string;
    FollowUpRequestIds: string[];
    HraEducationFollowUpRequestIds: string[];
    AssessedRole: ProviderRole;
    IsCompletedBasicInfo: boolean;
    IsCompletedAddressInfo: boolean;
    IsCompletedContactInfo: boolean;
    DemographicStatus: StatusEnum;
    AllergyStatus: StatusEnum;
    CareCircleStatus: StatusEnum;
    TabletStatus: StatusEnum;
    PcpSpecialistStatus: StatusEnum;
    ConsentFormId: string;
    BudgetFormId: string;
    HealthPlanStatus: StatusEnum;
    RemoteMonitoringStatus: StatusEnum;
    ProgressNoteStatus: StatusEnum;
    EducationMaterialAssigned: boolean;
    EducationSessionIds: string[];
    TransitionalCareId: string;
    AdlAssessmentId: string;
    SexualHealthId: string;
    FallRiskId: string;
    VisionAndHearingId: string;
    AdvancedDirectiveId: string;
    TobaccoAndDrugAssessmentId: string;
    PreventativeHealthAdviceId: string;
    AdditionalCHPCriteriaId: string;
    HraEducationId: string;
    AddKeyContact: boolean;
    EngageWithMember: boolean;
    AddPlanOfCare: boolean;
    MedicalReconcilation: boolean;
    PlanDischargeLocation: boolean;
    IsInTransition: boolean;
    DateOfOnboard: string;
    MemberStatus: MemberStatusEnum;
    CreatedOn: string;
    CreatedBy: string;
    TelemedicineDetails: TelemedicineDetails;
    IgnoreSaveSummaryNotes: boolean;
    IgnoreTimelineInsert?: boolean;
    IsCarePlanReviewed: boolean;
    IsDefaultReasonForVisitChanged: boolean;
    ScreeningFuhFumId: string;
    ChpFullProgramId: string;
    IsElevatedPHQ9Addressed: boolean;
    ElevatedPHQ9Notes: string;
    HamiltonAnxietyScaleStatus: StatusEnum;
    HamiltonAnxietyScaleId: string;

    constructor() {
        this.MemberId = '';
        this.MeansOfEngagement = null;
        this.IgnoreSaveSummaryNotes = false;
    }
}
