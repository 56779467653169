import {
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { parentUrl } from '../../../../utils/constants';
import { apiString as API_URL } from '../../../../utils/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import WidgetLoading from '../../Shared/WidgetLoading';
import { isDefaultDate } from '../../../../utils/common';
import { TransitionalCareStepTypeEnum } from '../../../../Enum/TransitionalCareStepTypeEnum';
import moment from 'moment';
import { FacilityTypeEnum } from '../../../../Enum/FacilityTypeEnum';
import { copyObject } from '../../../../utils/common';
import Authorization from '../../../../utils/Authorization';

const TransitionalCare = (props) => {
    const auth = new Authorization();
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [transitionalCare, setTransitionalCare] = useState([]);
    const providerId = props.providerId === undefined ? auth.UserId : props.providerId;
    const showProviders = auth.Role === 'CEO' || props.showProviders;
    const [selectedProvider, setSelectedProvider] = useState(auth.Role === 'CEO' || showProviders ? 'All' : providerId);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [showInActive, setShowInActive] = useState<boolean>(false);
    const ref = useRef(null);
    const copyProviders = copyObject(activeProviders);
    const filteredProviders = (showInActive ? copyProviders : copyProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const handleClick = (event) => {
        setShow(!show);
    };

    const openDocumentHandler = (memberId: string) => {
        window.open(`${parentUrl}provider/members-list/member-chart/${memberId}/transitional-care`, '_blank');
    };

    useEffect(() => {
        const changeFilter = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(
                    `${API_URL}/transitionalcare/transitionalcarewidget?providerId=${selectedProvider}&includeInActiveProviders=${showInActive}`
                );
                setTransitionalCare(response.data);
                setIsLoading(false);
            } catch (error) {
                setTransitionalCare([]);
                setIsLoading(false);
                console.log(error);
            }
        };
        changeFilter();
    }, [selectedProvider, showInActive]);

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActive(isChecked);
        setSelectedProvider('All');
        setTransitionalCare([]);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'}>
                            Transitional Care
                        </Typography>
                        <Typography display={'inline-block'} variant="body1" color={'primary'} ref={ref}>
                            &#40;{transitionalCare.length}&#41;
                            <Tooltip
                                open={show}
                                title={
                                    <div>
                                        <Typography variant="caption">Members in transitional care</Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Grid>

                    {showProviders && (
                        <Grid item xs={12} marginTop={2}>
                            <FormControl className="form-control-200" sx={{ marginRight: 2 }}>
                                <InputLabel id="demo-simple-select-label">Provider</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={filteredProviders.find((provider) => provider.Id === selectedProvider)?.Id || 'All'}
                                    size="small"
                                    label="Provider"
                                    disabled={isLoading ? true : false}
                                    onChange={(e) => {
                                        setSelectedProvider(e.target.value as string);
                                    }}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                                >
                                    <MenuItem value={'All'}>All</MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={showInActive} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredProviders.map((pro) => (
                                        <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                            {pro.LastName}, {pro.FirstName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    <Grid container spacing={2} marginTop={2} height={props.showProviders ? '52vh' : '58vh'} sx={{ overflowY: 'scroll', position: 'relative' }}>
                        {isLoading ? (
                            <WidgetLoading />
                        ) : transitionalCare.length > 0 ? (
                            transitionalCare.map((member) => {
                                const admissionStep = member.TransitionSteps.find((step) => step.TransitionStepType === TransitionalCareStepTypeEnum.Admission);
                                const dischargeSteps = member.TransitionSteps.filter(
                                    (step) => step.TransitionStepType === TransitionalCareStepTypeEnum.Discharge
                                );
                                const lastStep = member.TransitionSteps[member.TransitionSteps.length - 1];
                                const address = [
                                    lastStep?.Facility?.Name,
                                    lastStep?.Facility?.Address,
                                    lastStep?.Facility?.City,
                                    lastStep?.Facility?.State,
                                    lastStep?.Facility?.ZipCode,
                                ];
                                const movedTo =
                                    lastStep?.TransitionStepType === TransitionalCareStepTypeEnum.Discharge ? lastStep?.Address : address.join(', ');
                                return (
                                    <Grid key={member.Id} item xs={6}>
                                        <Card
                                            sx={{
                                                ':hover': {
                                                    cursor: 'pointer',
                                                    boxShadow: 10,
                                                    border: 0,
                                                },
                                                boxShadow: 0,
                                            }}
                                            onClick={() => openDocumentHandler(member.MemberId)}
                                            variant="outlined"
                                        >
                                            <CardContent
                                                sx={{ display: 'flex', flexDirection: 'column', alignItem: 'flex-start', justifyContent: 'space-between' }}
                                            >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Member
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2" color={member.Priority === 3 ? 'error.main' : ''}>
                                                            {member.MemberName}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Health Plan
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2" color={member.Priority === 3 ? 'error.main' : ''}>
                                                            {member.HealthPlan}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Admission Date
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2" color={member.Priority === 3 ? 'error.main' : ''}>
                                                            {isDefaultDate(admissionStep?.AdmissionDate)
                                                                ? '-'
                                                                : moment(admissionStep?.AdmissionDate).format('MM/DD/yyyy')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Stage
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2" color={member.Priority === 3 ? 'error.main' : ''}>
                                                            {TransitionalCareStepTypeEnum[lastStep.TransitionStepType]}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Admission Type
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2" color={member.Priority === 3 ? 'error.main' : ''}>
                                                            {FacilityTypeEnum[admissionStep?.AdmissionType]}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Discharge Date
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2" color={member.Priority === 3 ? 'error.main' : ''}>
                                                            {dischargeSteps.length > 0
                                                                ? moment(dischargeSteps[dischargeSteps.length - 1].AdmissionDate).format('MM/DD/yyyy')
                                                                : '-'}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Moved To
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2" color={member.Priority === 3 ? 'error.main' : ''}>
                                                            {movedTo.length > 0 ? movedTo : '-'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                );
                            })
                        ) : (
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="h6">No Matching Records</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default TransitionalCare;
