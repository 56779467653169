import { VitalSignResultStatusEnum } from '../../Enum/VitalSignResultStatusEnum';
import { EngagementDraft } from '../DocumentEngagements/EngagementDraft.model';

export default interface IMemberAssessmentHistoryDto {
    LastAssessmentCompletedOn: string;
    LastVitalSignCompletedOn: string;
    LastSdohCreatedOn: string;
}

export class MemberAssessmentHistoryDto implements IMemberAssessmentHistoryDto {
    LastAssessmentCompletedOn: string;
    LastVitalSignCompletedOn: string;
    VitalSignStatus: VitalSignResultStatusEnum;
    LastSdohCreatedOn: string;
    ShowSdohAlert: boolean;
    SdohStatus: number;
    LastSdohCreatedBy: string;
    LastPastPresentHistoryCreatedBy: string;
    PastPresentHistoryStatus: number;
    LastPastSurgicalHistoryCreatedOn: string;
    LastPastSurgicalHistoryCreatedBy: string;
    PastSurgicalHistoryStatus: number;
    ReviewSystemStatus: number;
    LastReviewSystemCreatedBy: string;
    LastPhysicalExamCreatedOn: string;
    LastPhysicalExamCreatedBy: string;
    PhysicalExamStatus: number;
    LastPhqCreatedOn: string;
    LastPhqCreatedBy: string;
    LastPhqScore: number;
    ShowPhqAlert: boolean;
    PhqStatus: number;
    LastUrinalysisReportCreatedOn: string;
    LastUrinalysisReportCreatedBy: string;
    UrinalysisReportStatus: number;
    LastHgbA1CCreatedOn: string;
    LastHgbA1CCreatedBy: string;
    HgbA1CStatus: number;
    LastDiabetesFootCreatedOn: string;
    LastDiabetesFootCreatedBy: string;
    DiabetesFootStatus: number;
    ShowCarePlanAlert: boolean;
    CurrentHgbA1CCreatedOn: string;
    CurrentHgbA1CCreatedBy: string;
    IsHgbA1CNeedsReview: boolean;
    CurrentUrinalysisReportCreatedOn: string;
    CurrentUrinalysisReportCreatedBy: string;
    IsUrinalysisNeedsReview: boolean;
    LastMiniMentalExamCreatedOn: string;
    LastMiniMentalExamCreatedBy: string;
    MiniMentalExamStatus: number;
    LastFactorAndRiskAssessmentCreatedOn: string;
    LastFactorAndRiskAssessmentCreatedBy: string;
    FactorAndRiskAssessmentStatus: number;
    LastSubjectiveAssessmentCreatedOn: string;
    LastSubjectiveAssessmentCreatedBy: string;
    SubjectiveAssessmentStatus: number;
    LastLipidCreatedOn: string;
    LastLipidCreatedBy: string;
    LipidStatus: number;
    CurrentLipidCreatedOn: string;
    CurrentLipidCreatedBy: string;
    IsLipidNeedsReview: boolean;
    LastCounselingCreatedOn: string;
    LastCounselingCreatedBy: string;
    CounselingStatus: number;
    CurrentCounselingCreatedOn: string;
    CurrentCounselingCreatedBy: string;
    ShowConsentFormAlert: boolean;
    IsConsentToService: boolean;
    LastAdlAssessmentCreatedOn: string;
    LastAdlAssessmentCreatedBy: string;
    AdlAssessmentStatus: number;
    LastSexualHealthCreatedOn: string;
    LastSexualHealthCreatedBy: string;
    SexualHealthStatus: number;
    LastFallRiskCreatedOn: string;
    LastFallRiskCreatedBy: string;
    FallRiskStatus: number;
    LastVisionAndHearingCreatedOn: string;
    LastVisionAndHearingCreatedBy: string;
    VisionAndHearingStatus: number;
    LastTobaccoAndDrugAssessmentCreatedOn: string;
    LastTobaccoAndDrugAssessmentCreatedBy: string;
    TobaccoAndDrugAssessmentStatus: number;
    LastPreventativeHealthAdviceCreatedOn: string;
    LastPreventativeHealthAdviceCreatedBy: string;
    PreventativeHealthAdviceStatus: number;
    LastAdditionalCHPCriteriaCreatedOn: string;
    LastAdditionalCHPCriteriaCreatedBy: string;
    AdditionalCHPCriteriaStatus: number;
    HraEducationStatus: number;
    LastScreeningFuhfumCreatedOn: string;
    LastScreeningFuhfumCreatedBy: string;
    ScreeningFuhfumStatus: number;
    LastHamiltonAnxietyCreatedOn: string;
    LastHamiltonAnxietyCreatedBy: string;
    HamiltonAnxietyScaleStatus: number;
    EngagementDraft: EngagementDraft;

    constructor() {
        this.EngagementDraft = new EngagementDraft();
    }
}
